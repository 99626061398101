import { makeStyles } from '@material-ui/core/styles';

import { NavigationStatus } from '../../interfaces/NavigationStatus';

import COLORS from '../../utils/colors';

const useStyles = makeStyles({
  statusOnTileNavigation: {
    height: '1.375rem',
    paddingRight: '.5rem',
    fontWeight: 700,
    fontSize: '0.875rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    boxShadow: `0px 0px 4px ${COLORS.blackPure}3f`,
  },

  statusOnTable: {
    padding: '0.315rem 0.625rem',
    fontSize: '1.125rem',
    fontWeight: 500,
    borderRadius: '0.375rem',
    lineHeight: '1.625rem',
    height: '2.25rem',
  },
});

const statusData = {
  inTransit: {
    title: 'Em trânsito',
    backgroundColor: COLORS.greenAlert2,
    color: COLORS.greenAlert,
  },

  delay: {
    title: 'Atraso',
    backgroundColor: COLORS.orangeAlert2,
    color: COLORS.orangeAlert,
  },

  riskDelay: {
    title: 'Atraso de Risco',
    backgroundColor: COLORS.redAlert2,
    color: COLORS.redAlert,
  },

  '': {
    title: '',
    backgroundColor: 'transparent',
    color: 'transparent',
  },
};

interface Props {
  status: NavigationStatus;
  type: 'table' | 'tileNavigation';
}

const TileStatus: React.FC<Props> = ({ status, type }) => {
  const classes = useStyles();

  if (!statusData[status]) return null;

  const { title, ...style } = statusData[status];

  return type === 'tileNavigation' ? (
    <div
      className={classes.statusOnTileNavigation}
      style={status !== 'inTransit' ? style : {}}
    >
      {status !== 'inTransit' && `${title}`}
    </div>
  ) : (
    <span className={classes.statusOnTable} style={style}>
      {title}
    </span>
  );
};

export default TileStatus;
