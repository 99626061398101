/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import * as yup from 'yup';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';

import CustomAlert from '../CustomAlert';

import { useAuth } from '../../contexts/auth';

import api from '../../services/api';

import COLORS from '../../utils/colors';

const useStyles = makeStyles({
  buttonEdit: {
    cursor: 'pointer',

    '&:hover': {
      opacity: '0.85',
      transition: '0.3s',
    },
  },

  title: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1.875rem 1.875rem 0 1.875rem',

    '& h1': {
      color: COLORS.gray,
      fontWeight: 500,
      fontSize: '1.875rem',
      maxWidth: '65%',
      textAlign: 'center',
    },

    '& div:nth-child(3)': {
      cursor: 'pointer',
      border: `1px solid ${COLORS.gray3}`,
      borderRadius: '0.375rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '2.25rem',
      height: '2.25rem',

      '& svg': {
        fontSize: '1.5rem',
        color: COLORS.gray,
      },

      '&:hover': {
        backgroundColor: `${COLORS.greenPrimary}0a`,
        transition: '0.3s',
      },
    },
  },

  content: {
    width: '35rem',
    display: 'flex',
    flexDirection: 'column',
    padding: '3.25rem 6.25rem',
  },

  oldField: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '0.625rem',

    '& h2': {
      fontWeight: 500,
      color: COLORS.gray,
      fontSize: '0.875rem',
    },

    '& span': {
      fontWeight: 500,
      color: COLORS.gray,
      fontSize: '1.375rem',
    },
  },

  newField: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '0.5rem',
    marginTop: '2.875rem',

    '& span': {
      fontSize: '0.875rem',
      color: COLORS.gray,
    },

    '& input, & div div input': {
      height: '3rem',
      border: `1px solid ${COLORS.greenPrimary}33`,
      borderRadius: '0.5rem',
      padding: '0 1rem',
      outline: 'none',
      backgroundColor: `${COLORS.greenPrimary}0a`,
      fontSize: '0.875rem',
    },
  },

  buttonSave: {
    marginTop: '3.5rem',
    padding: 0,
    height: '2.5rem',
    width: '7.625rem',
    alignSelf: 'center',
    backgroundColor: COLORS.greenAlert,
    color: COLORS.whiteMatte,

    '&:hover': {
      backgroundColor: COLORS.greenAlert,
      opacity: '0.9',
      transition: '0.3s',
    },
  },
});

interface Props {
  field: string;
  value: string;
  typeInput: string;
}

interface KeyField {
  [key: string]: string;
}

const ModalEditProfile: React.FC<Props> = ({ field, value, typeInput }) => {
  const [open, setOpen] = useState(false);
  const [newValue, setNewValue] = useState('');
  const classes = useStyles();
  const { user, updateUser } = useAuth();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleClose = () => {
    setOpen(false);
    setNewValue('');
    setErrorMessage(null);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSubmit = async () => {
    setErrorMessage(null);

    const keyFields: KeyField = {
      Nome: 'name',
      'E-mail': 'email',
      Telefone: 'cellphone',
      'Telefone de Emergência': 'emergency_phone',
    };
    const data = {
      ...(({
        id,
        role,
        boating_license: boatingLicense,
        marina,
        newsletter,
        token_activation,
        ...others
      }) => others)(user),
      [keyFields[field]]: newValue,
    };

    const schema = yup.object().shape({
      name: yup.string().required('Este campo é obrigatório.'),
      email: yup
        .string()
        .email('E-mail informado tem formato inválido.')
        .required('Este campo é obrigatório.'),
      cellphone: yup
        .string()
        .required('Este campo é obrigatório.')
        .matches(/^\(?[1-9]{2}\)? ?(?:[2-8]|9 ?[1-9])[0-9]{3}-?[0-9]{4}$/, {
          message: 'Telefone informado tem formato inválido',
        }),
      emergency_phone: yup
        .string()
        .required('Este campo é obrigatório.')
        .matches(/^\(?[1-9]{2}\)? ?(?:[2-8]|9 ?[1-9])[0-9]{3}-?[0-9]{4}$/, {
          message: 'Telefone de Emergência informado tem formato inválido',
        }),
    });

    try {
      await schema.validate(data, { abortEarly: false });
      await api.put(`/user/profile`, data);

      updateUser({ ...user, ...data });
      setOpen(false);
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        setErrorMessage(error.inner[0].message);
        return;
      }

      CustomAlert('error', 'Erro inesperado. Por favor, tente novamente!');
    }
  };

  return (
    <>
      <EditIcon className={classes.buttonEdit} onClick={handleOpen} />

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle className={classes.title} disableTypography>
          <div />

          <h1>Alterar {field}</h1>

          <div onClick={handleClose}>
            <CloseIcon />
          </div>
        </DialogTitle>

        <DialogContent className={classes.content}>
          <div className={classes.oldField}>
            <h2>{field} atual</h2>

            <span>{value}</span>
          </div>

          <div className={classes.newField}>
            <span>Novo {field}</span>

            <TextField
              value={newValue}
              onChange={e => {
                setNewValue(e.target.value);
              }}
              InputProps={{ disableUnderline: true }}
              error={Boolean(errorMessage)}
              helperText={errorMessage}
              type={typeInput}
            />
          </div>

          <Button onClick={handleSubmit} className={classes.buttonSave}>
            Salvar
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ModalEditProfile;
