import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import COLORS from '../../utils/colors';

import { ReactComponent as ErrorImg } from '../../images/error.svg';
import { ReactComponent as SuccessImg } from '../../images/success.svg';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    width: '100vw',
    backgroundColor: COLORS.greenPrimary,
    alignItems: 'center',
    justifyContent: 'center',

    '& h1': {
      color: COLORS.whitePure,
      marginBottom: '2.5rem',
      fontSize: '1.875rem',
      textAlign: 'center',
      lineHeight: '1.5',
      maxWidth: '25%',
    },
  },
});

interface Props {
  success: boolean;
}

const MessageResetPassword: React.FC<Props> = ({ success }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <h1>
        {success
          ? 'Senha alterada com sucesso!'
          : 'Você não tem autorização para acessar essa página'}
      </h1>

      {success ? <SuccessImg /> : <ErrorImg />}
    </div>
  );
};

export default MessageResetPassword;
