export default {
  greenPrimary: '#035256',
  greenSecondary: '#05878C',
  greenAlert: '#34C759',
  greenAlert2: '#D9FFDA',

  redAlert: '#FF6666',
  redAlert2: '#FFE7E7',

  orangePrimary: '#FFE9CE',
  orangeAlert: '#FFA400',
  orangeAlert2: '#FFECC9',

  whitePure: '#FFFFFF',
  whiteMatte: '#FAFAFA',

  gray: '#333333',
  gray2: '#555555',
  gray3: '#B9B9B9',
  gray4: '#E6E6E6',
  gray5: '#EFEFEF',
  gray6: '#F4F4F4',

  blueAlert: '#00B2FF',

  blackPure: '#000000',
};
