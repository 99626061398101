import React from 'react';
import { makeStyles } from '@material-ui/core';
import TileNavigationContainer from '../../../components/TileNavigationContainer';
import { useNavigations } from '../../../contexts/navigations';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    columnGap: '1.25rem',
    paddingTop: '1.5rem',
    marginTop: '2.5rem',
    height: '100%',
    width: '100%',
  },
});

interface Props {
  id: string;
  ariaLabelledby: string;
  role: string;
  hidden: boolean;
}

const Overview: React.FC<Props> = ({ id, ariaLabelledby, role, hidden }) => {
  const { exits, navigates, arrivals } = useNavigations();
  const rowsPerPage = 5;

  const classes = useStyles();

  return (
    <div
      id={id}
      aria-labelledby={ariaLabelledby}
      role={role}
      style={{ display: hidden ? 'none' : 'flex' }}
      className={classes.container}
    >
      <TileNavigationContainer
        type="exit"
        data={exits}
        rowsPerPage={rowsPerPage}
      />

      <TileNavigationContainer
        type="navigate"
        data={navigates}
        rowsPerPage={rowsPerPage}
      />

      <TileNavigationContainer
        type="arrival"
        data={arrivals}
        rowsPerPage={rowsPerPage}
      />
    </div>
  );
};

export default Overview;
