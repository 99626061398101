import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import EventIcon from '@material-ui/icons/Event';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers/';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import MomentUtils from '@date-io/moment';
import moment from 'moment';

import CustomGoogleMaps from '../../components/CustomGoogleMaps';

import { useAuth } from '../../contexts/auth';

import { CampaignClickLocation } from '../../interfaces/CampaignClickLocation';

import api from '../../services/api';

import COLORS from '../../utils/colors';

const useStyles = makeStyles({
  container: {
    height: '100vh',
  },

  sideBar: {
    padding: '2rem 1rem',
    textAlign: 'center',
  },

  buttonBack: {
    position: 'absolute',
    top: '2.5rem',
    left: '2.5rem',
    fontSize: '2.25rem',
    cursor: 'pointer',

    '&:hover': {
      color: COLORS.greenPrimary,
      transition: '0.3s',
    },
  },

  logo: {
    fontSize: '2rem',
    fontWeight: 600,
    width: '10rem',
  },

  campaign: {
    width: '80%',
  },

  rangeOfDates: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: '1rem',
    width: '60%',
  },

  totalOfClicks: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '1rem',

    '& p': {
      fontSize: '2rem',
      fontWeight: '600',
      color: COLORS.greenPrimary,
    },
  },
});

interface Campaign {
  id: number;
  name: string;
  number_of_clicks: number;
}

const Heatmap = () => {
  const [campaignSelectedId, setCampaignSelectedId] = useState(0);
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [campaignClickLocations, setCampaignClickLocations] = useState<
    CampaignClickLocation[]
  >([]);
  const [totalOfClicks, setTotalOfClicks] = useState(0);
  const [dateStart, setDateStart] = useState<MaterialUiPickersDate>(moment());
  const [dateEnd, setDateEnd] = useState<MaterialUiPickersDate>(moment());

  const classes = useStyles();
  const history = useHistory();
  const { user } = useAuth();

  useEffect(() => {
    if (user.role !== 'admin') {
      history.push('/dashboard');
    }
  }, []);

  useEffect(() => {
    async function loadCampaigns() {
      try {
        const response = await api.get('/api/partner');

        setCampaigns(response.data);

        if (!campaignSelectedId) {
          setTotalOfClicks(
            response.data
              .map((campaign: Campaign) => campaign.number_of_clicks)
              .reduce((prev: number, current: number) => prev + current),
          );
        }
      } catch (error) {
        console.log(error);
      }
    }

    loadCampaigns();
  }, []);

  useEffect(() => {
    async function loadCampaign() {
      try {
        const response = await api.get('api/partner/static', {
          params: {
            partner_id: campaignSelectedId,
            date_start: dateStart?.format('YYYY-MM-DD'),
            date_end: dateEnd?.format('YYYY-MM-DD'),
          },
        });

        console.log(response.data);
        setCampaignClickLocations(response.data);

        setTotalOfClicks(
          response.data
            .map((campaign: CampaignClickLocation) => campaign.number_of_clicks)
            .reduce((prev: number, current: number) => prev + current),
        );
      } catch (error) {
        console.log(error);
        setTotalOfClicks(0);
      }
    }

    if (campaignSelectedId) {
      loadCampaign();
    }
  }, [campaignSelectedId, dateStart, dateEnd]);

  return (
    <Grid container className={classes.container}>
      <Grid
        container
        item
        xs={3}
        className={classes.sideBar}
        direction="column"
        alignItems="center"
        justifyContent="space-around"
      >
        <KeyboardBackspaceIcon
          className={classes.buttonBack}
          onClick={() => {
            history.push('/dashboard');
          }}
        />

        <h1 className={classes.logo}>Heatmap DattaSea</h1>

        <TextField
          select
          label="Selecione a campanha"
          value={campaignSelectedId}
          onChange={event => {
            setCampaignSelectedId(Number(event.target.value));
          }}
          className={classes.campaign}
          variant="outlined"
        >
          {campaigns &&
            campaigns.map(campaign => (
              <MenuItem key={campaign.id} value={campaign.id}>
                {campaign.name}
              </MenuItem>
            ))}
        </TextField>

        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
          <div className={classes.rangeOfDates}>
            <DatePicker
              placeholder="Data de início"
              format={moment(dateStart).format('L')}
              variant="inline"
              value={dateStart}
              onChange={newValue => {
                setDateStart(newValue);
              }}
              inputVariant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <EventIcon style={{ color: COLORS.greenPrimary }} />
                  </InputAdornment>
                ),
              }}
            />

            <span>a</span>

            <DatePicker
              placeholder="Data de fim"
              format={moment(dateEnd).format('L')}
              variant="inline"
              value={dateEnd}
              onChange={newValue => {
                setDateEnd(newValue);
              }}
              inputVariant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <EventIcon style={{ color: COLORS.greenPrimary }} />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </MuiPickersUtilsProvider>

        <div className={classes.totalOfClicks}>
          <h2>Total de cliques</h2>

          <p>{totalOfClicks}</p>
        </div>
      </Grid>

      <Grid item xs={9}>
        <CustomGoogleMaps campaignClickLocations={campaignClickLocations} />
      </Grid>
    </Grid>
  );
};

export default Heatmap;
