import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import { makeStyles, withStyles, createStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import ModalShowWatercraft from '../../components/ModalShowWatercraft';
import ModalCreateWatercraft from '../../components/ModalCreateWatercraft';
import Layout from '../../components/Layout';
import CustomPagination from '../../components/CustomPagination';
import ModalDeleteWatercraft from '../../components/ModalDeleteWatercraft';

import { useAuth } from '../../contexts/auth';
import { useSearch } from '../../contexts/search';

import { Watercraft } from '../../interfaces/Watercraft';

import api from '../../services/api';

import COLORS from '../../utils/colors';

const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100%',
    padding: '1.5rem 5rem 2.75rem 5rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  select: {
    padding: 0,
    borderRadius: '0.375rem',

    '& .MuiInputBase-root': {
      backgroundColor: COLORS.gray4,
      width: '10.25rem',
      color: COLORS.blackPure,
      fontSize: '1.125rem',
      fontWeight: 400,
      height: '2.25rem',
    },

    '& .MuiSelect-selectMenu': {
      paddingTop: 0,
      paddingBottom: 0,
      backgroundColor: COLORS.gray4,
    },

    '& svg': {
      color: COLORS.blackPure,
    },

    '& fieldset': {
      border: 'none',
    },
  },

  table: {
    '& .MuiTableCell-root': {
      border: 'none',
    },
  },

  tableHeader: {
    '& th': {
      fontWeight: 500,
      fontSize: '1.125rem',
      color: COLORS.gray3,
    },
  },

  buttonActionsOnWatercraft: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '1.5rem',
  },

  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& #totalWatercrafts': {
      fontWeight: 400,
      fontSize: '1.125rem',
      color: COLORS.gray3,
    },
  },

  buttonImport: {
    padding: 0,
    height: '2.25rem',
    width: '8rem',
    textTransform: 'none',
    color: COLORS.blackPure,
    backgroundColor: COLORS.gray4,
    fontSize: '1.125rem',
    fontWeight: 400,
  },

  pagination: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    columnGap: '1.625rem',

    '& span': {
      color: COLORS.gray3,
      fontWeight: 500,
      fontSize: '1.125rem',
    },
  },
});

type TypeWatercraft = 'associates' | 'non-associates';

const typeWatercraftsToPtBR = {
  associates: 'associadas',
  'non-associates': 'não associadas',
};

const StyledTableRow = withStyles(() =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: `${COLORS.gray6}`,
      },

      '& .MuiTableCell-root': {
        border: 'none',
      },

      '& td': {
        fontWeight: 500,
        color: COLORS.gray,
        fontSize: '1.375rem',
      },
    },
  }),
)(TableRow);

export default function Watercrafts() {
  const classes = useStyles();
  const history = useHistory();

  const [page, setPage] = useState(1);
  const rowsPerPage = 8;
  const [watercrafts, setWatercrafts] = useState<Watercraft[]>([]);
  const [typeWatercrafts, setTypeWatercrafts] =
    useState<TypeWatercraft>('associates');

  const { search } = useSearch();
  const { user } = useAuth();

  const emptyRows = watercrafts
    ? rowsPerPage -
      Math.min(rowsPerPage, watercrafts.length - (page - 1) * rowsPerPage)
    : rowsPerPage;

  useEffect(() => {
    if (user.role !== 'marina') {
      history.push('/dashboard');
    }
  }, []);

  useEffect(() => {
    async function loadData() {
      try {
        const response =
          typeWatercrafts === 'associates'
            ? await api.get(`/watercraft/owner`, { params: { search } })
            : await api.get(`/watercraft`, { params: { search } });
        setWatercrafts(response.data);
        setPage(1);
      } catch (error) {
        console.log(error);
      }
    }
    loadData();
  }, [typeWatercrafts, search]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  return (
    <Layout routeSelected="watercrafts" type={user.role}>
      <div className={classes.container}>
        <div className={classes.header}>
          <TextField
            value={typeWatercrafts}
            onChange={e => {
              setTypeWatercrafts(e.target.value as TypeWatercraft);
            }}
            variant="outlined"
            className={classes.select}
            select
          >
            <MenuItem value="associates">Associadas</MenuItem>

            <MenuItem value="non-associates">Não Associadas</MenuItem>
          </TextField>

          <ModalCreateWatercraft
            watercrafts={watercrafts}
            setWatercrafts={setWatercrafts}
            typeWatercrafts={typeWatercrafts}
          />
        </div>

        <TableContainer className={classes.table}>
          <Table>
            <TableHead>
              <TableRow className={classes.tableHeader}>
                <TableCell>Nome</TableCell>

                <TableCell>Capitania</TableCell>

                <TableCell>Tipo</TableCell>

                <TableCell>Capacidade</TableCell>

                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              {watercrafts
                ?.slice(
                  (page - 1) * rowsPerPage,
                  (page - 1) * rowsPerPage + rowsPerPage,
                )
                .map(watercraft => (
                  <StyledTableRow key={watercraft.id}>
                    <TableCell>{watercraft.name_boating}</TableCell>

                    <TableCell>{watercraft.number_captaincy}</TableCell>

                    <TableCell>{watercraft.type}</TableCell>

                    <TableCell>{watercraft.capacity}</TableCell>

                    <TableCell>
                      <div className={classes.buttonActionsOnWatercraft}>
                        <ModalShowWatercraft
                          key={watercraft.id}
                          data={watercraft}
                          watercrafts={watercrafts}
                          setWatercrafts={setWatercrafts}
                        />

                        <ModalDeleteWatercraft
                          watercraft={watercraft}
                          setWatercrafts={setWatercrafts}
                        />
                      </div>
                    </TableCell>
                  </StyledTableRow>
                ))}

              {emptyRows > 0 && (
                <TableRow style={{ height: 64 * emptyRows }}>
                  <TableCell />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <div className={classes.footer}>
          <Button
            className={classes.buttonImport}
            endIcon={<ArrowDownwardIcon />}
          >
            Importar
          </Button>

          <div className={classes.pagination}>
            <span>Linhas por página</span>

            <span>{rowsPerPage}</span>

            <span>|</span>

            <CustomPagination
              page={page}
              rowsPerPage={rowsPerPage}
              total={watercrafts?.length}
              handleChangePage={handleChangePage}
            />
          </div>

          <span id="totalWatercrafts">
            {watercrafts?.length} embarcações{' '}
            {typeWatercraftsToPtBR[typeWatercrafts]}
          </span>
        </div>
      </div>
    </Layout>
  );
}
