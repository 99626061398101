import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import SearchIcon from '@material-ui/icons/Search';
import { DebounceInput } from 'react-debounce-input';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';
import clsx from 'clsx';

import Layout from '../../components/Layout';
import ModalCreateOperator from '../../components/ModalCreateOperator';
import CustomAlert from '../../components/CustomAlert';
import ModalEditOperator from '../../components/ModalEditOperator';

import { useAuth } from '../../contexts/auth';

import { Operator } from '../../interfaces/Operator';

import api from '../../services/api';

import COLORS from '../../utils/colors';

const useStyles = makeStyles({
  container: {
    padding: '0 5rem',
    rowGap: '4.5rem',
  },

  headerMarinaDataContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '.75rem',

    '& h2': {
      fontWeight: 'bold',
      fontSize: '1.5rem',
      lineHeight: '4rem',
      color: COLORS.greenPrimary,
    },
  },

  field: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '0.5rem',

    '& span': {
      fontSize: '0.875rem',
      color: COLORS.gray,
    },

    '& input, & div div input': {
      height: '2.75rem',
      border: `1px solid ${COLORS.gray}`,
      borderRadius: '0.5rem',
      padding: '0 1rem',
      outline: 'none',
      backgroundColor: COLORS.whitePure,
      fontSize: '0.875rem',
    },
  },

  buttonEdit: {
    textTransform: 'none',
    padding: 0,
    height: '2.25rem',
    width: '5.5rem',
    border: `1px solid ${COLORS.greenPrimary}`,
    color: COLORS.greenPrimary,
    fontSize: '0.875rem',
    fontWeight: 600,
    lineHeight: '1.5rem',

    '& svg': {
      width: '1rem',
      height: '1rem',
    },

    '&:hover': {
      backgroundColor: COLORS.greenPrimary,
      color: COLORS.whitePure,

      transition: '0.3s',
    },
  },

  buttonSave: {
    textTransform: 'none',
    padding: 0,
    height: '2.25rem',
    width: '5.5rem',
    backgroundColor: COLORS.greenPrimary,
    color: COLORS.whitePure,
    fontSize: '0.875rem',
    fontWeight: 600,
    lineHeight: '1.5rem',

    '& svg': {
      width: '1rem',
      height: '1rem',
    },

    '&:hover': {
      background: 'none',
      border: `1px solid ${COLORS.greenPrimary}`,
      color: COLORS.greenPrimary,

      transition: '0.3s',
    },
  },

  operatorsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  operatorsHeader: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: '1.5rem',

    '& h1': {
      color: COLORS.greenPrimary,
      fontSize: '1.5rem',
    },
  },
});

interface AdminMarinaParams {
  id: string;
}

interface ErrorMessage {
  name_marina?: string;
  cnpj?: string;
  accountable?: string;
  phone?: string;
  address?: string;
}

const AdminMarina = () => {
  const [marina, setMarina] = useState({
    name: '',
    cnpj: '',
    accountable: '',
    phone: '',
    address: '',
  });

  const [isEditingPersonalInfo, setIsEditingPersonalInfo] = useState(false);
  const classes = useStyles();

  const [operators, setOperators] = useState<Operator[]>([]);
  const [search, setSearch] = useState('');
  const [errorMessage, setErrorMessage] = useState<ErrorMessage>({});

  const { user } = useAuth();
  const { id } = useParams<AdminMarinaParams>();
  const history = useHistory();

  useEffect(() => {
    if (user.role !== 'admin') {
      history.push('/dashboard');
    }
  }, []);

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMarina({
      ...marina,
      [event.target.name]: event.target.value,
    });
  };

  const handleUpdateMarina = async () => {
    setErrorMessage({});

    if (!isEditingPersonalInfo) {
      setIsEditingPersonalInfo(true);
      return;
    }

    const marinaUpdated = {
      name_marina: marina.name,
      cnpj: marina.cnpj,
      accountable: marina.accountable,
      phone: marina.phone,
      address: marina.address,
    };

    const schema = yup.object().shape({
      name_marina: yup.string().required('Este campo é obrigatório.'),
      cnpj: yup
        .string()
        .required('Este campo é obrigatório.')
        // Credits: https://gist.github.com/igorcosta/3a4caa954a99035903ab
        .matches(/(^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$)|(^\d{14}$)/, {
          message: 'CNPJ informado tem formato inválido',
        }),
      accountable: yup.string().required('Este campo é obrigatório.'),
      phone: yup
        .string()
        .required('Este campo é obrigatório')
        .matches(/^\(?[1-9]{2}\)? ?(?:[2-8]|9 ?[1-9])[0-9]{3}-?[0-9]{4}$/, {
          message: 'Telefone informado tem formato inválido',
        }),
      address: yup.string().required('Este campo é obrigatório.'),
    });

    try {
      await schema.validate(marinaUpdated, { abortEarly: false });
      await api.put(`/api/marina/${id}`, marinaUpdated);

      CustomAlert('success', 'Marina atualizada!');
      setIsEditingPersonalInfo(false);
    } catch (err) {
      console.log(err);

      if (err instanceof yup.ValidationError) {
        let errorAux = {};

        err.inner.forEach(e => {
          errorAux = {
            ...errorAux,
            [e.path as string]: e.message,
          };
        });

        setErrorMessage(errorAux);
        return;
      }

      setIsEditingPersonalInfo(true);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get(`/api/marina/user/${id}`, {
          params: {
            search,
          },
        });

        const { users, ...marinaResponse } = response.data;

        setOperators(users);
        if (!marina.cnpj) {
          setMarina({
            name: marinaResponse.name_marina || '',
            cnpj: marinaResponse.cnpj || '',
            accountable: marinaResponse.accountable || '',
            phone: marinaResponse.phone || '',
            address: marinaResponse.address || '',
          });
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [id, search]);

  return (
    <Layout routeSelected="dashboard" type={user.role}>
      <Grid container className={classes.container}>
        <div style={{ width: '100%' }}>
          <div className={classes.headerMarinaDataContainer}>
            <h2>Informações da Marina</h2>

            <Button
              onClick={handleUpdateMarina}
              className={clsx({
                [classes.buttonSave]: isEditingPersonalInfo,
                [classes.buttonEdit]: !isEditingPersonalInfo,
              })}
              endIcon={<EditOutlinedIcon />}
            >
              {isEditingPersonalInfo ? 'Salvar' : 'Editar'}
            </Button>
          </div>

          <Grid container alignItems="center" spacing={3}>
            <Grid item xs={4} className={classes.field}>
              <span>Nome</span>

              <TextField
                name="name"
                value={marina.name}
                onChange={handleChangeInput}
                InputProps={{ disableUnderline: true }}
                disabled={!isEditingPersonalInfo}
                error={Boolean(errorMessage?.name_marina)}
                helperText={errorMessage?.name_marina}
              />
            </Grid>

            <Grid item xs={4} className={classes.field}>
              <span>CNPJ</span>

              <TextField
                name="cnpj"
                value={marina.cnpj}
                onChange={handleChangeInput}
                InputProps={{ disableUnderline: true }}
                disabled={!isEditingPersonalInfo}
                error={Boolean(errorMessage?.cnpj)}
                helperText={errorMessage?.cnpj}
              />
            </Grid>

            <Grid item xs={4} className={classes.field}>
              <span>Responsável</span>

              <TextField
                name="accountable"
                value={marina.accountable}
                onChange={handleChangeInput}
                InputProps={{ disableUnderline: true }}
                disabled={!isEditingPersonalInfo}
                error={Boolean(errorMessage?.accountable)}
                helperText={errorMessage?.accountable}
              />
            </Grid>

            <Grid item xs={4} className={classes.field}>
              <span>Telefone</span>

              <TextField
                name="phone"
                value={marina.phone}
                onChange={handleChangeInput}
                InputProps={{ disableUnderline: true }}
                disabled={!isEditingPersonalInfo}
                error={Boolean(errorMessage?.phone)}
                helperText={errorMessage?.phone}
              />
            </Grid>

            <Grid item xs={8} className={classes.field}>
              <span>Endereço</span>

              <TextField
                name="address"
                value={marina.address}
                onChange={handleChangeInput}
                InputProps={{ disableUnderline: true }}
                disabled={!isEditingPersonalInfo}
                error={Boolean(errorMessage?.address)}
                helperText={errorMessage?.address}
              />
            </Grid>
          </Grid>
        </div>

        <div className={classes.operatorsContainer}>
          <div className={classes.operatorsHeader}>
            <h1>Operadores</h1>

            <DebounceInput
              minLength={0}
              debounceTimeout={300}
              element={TextField}
              placeholder="Pesquisar"
              value={search}
              onChange={e => {
                setSearch(e.target.value);
              }}
              InputProps={{
                endAdornment: <SearchIcon />,
                style: { height: '3rem' },
              }}
            />

            <ModalCreateOperator setOperators={setOperators} />
          </div>

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Nome</TableCell>

                  <TableCell align="center">E-mail</TableCell>

                  <TableCell align="center">Telefone</TableCell>

                  <TableCell />
                </TableRow>
              </TableHead>

              <TableBody>
                {operators &&
                  operators.map(operator => (
                    <ModalEditOperator
                      key={operator.id}
                      operator={operator}
                      marina_id={id}
                      setOperators={setOperators}
                    />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Grid>
    </Layout>
  );
};

export default AdminMarina;
