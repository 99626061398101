import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import * as yup from 'yup';

import CustomAlert from '../../components/CustomAlert';
import MessageResetPassword from './Message';

import api from '../../services/api';

import COLORS from '../../utils/colors';

import logo from '../../images/logo.png';
import background from '../../images/background.jpg';

const useStyles = makeStyles({
  logo: {
    padding: '1rem 1rem',
    marginTop: '2rem',

    '@media(min-width: 960px)': {
      padding: '0 0 0 4rem',
      marginTop: '0',
      width: '10rem',
    },
  },

  logoImg: {
    width: '350px',

    '@media(min-width: 960px)': {
      width: '16rem',
    },
    '@media(min-width: 1280px)': {
      width: '25vw',
    },
  },

  texts: {
    padding: '1rem 1rem',

    '@media(min-width: 960px)': {
      padding: '5rem 0 3rem 4rem',
    },
  },

  title: {
    fontWeight: 500,
    fontSize: '2rem',
    marginTop: '3rem',

    '@media(min-width: 960px)': {
      fontSize: '2.5rem',
      marginTop: 0,
    },
  },

  description: {
    color: COLORS.gray2,
  },

  inputs: {
    width: '95%',
    padding: '0 0 0 1rem',
    marginTop: '3rem',

    '@media(min-width: 960px)': {
      padding: '0 0 0 4rem',
      width: '88%',
      marginTop: 0,
    },
  },

  field: {
    margin: '2rem 0 0 0',
  },

  button: {
    margin: '3rem 0 0 0',
    height: '3.5rem',

    '@media(min-width: 960px)': {
      margin: '2rem 0 0 0',
    },
  },

  backgroundBox: {
    display: 'none',

    '@media(min-width: 960px)': {
      display: 'block',
    },
  },

  backgroundImg: {
    width: '100%',
    height: '100vh',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage: `url(${background})`,
    transform:
      'translateX(0) translateY(0)) rotate("180deg") skewX(0) skewY(0) scaleX(1) scaleY(1)',
  },
});

interface Params {
  token: string;
}

interface ErrorMessage {
  newPassword?: string;
  confirmNewPassword?: string;
}

export default function ResetPassword() {
  const [newPassword, setNewPassword] = useState('');
  const [visibilityNewPassword, setVisibilityNewPassword] = useState(false);

  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [visibilityConfirmPassword, setVisibilityConfirmPassword] =
    useState(false);

  const [isAuthorized, setIsAuthorized] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);

  const [errorMessage, setErrorMessage] = useState<ErrorMessage>({});

  const classes = useStyles();

  const { token }: Params = useParams();

  const handleSubmit = async () => {
    setErrorMessage({});

    const schema = yup.object().shape({
      newPassword: yup.string().required('Este campo é obrigatório.'),
      confirmNewPassword: yup
        .string()
        .required('Este campo é obrigatório.')
        .test(
          'passwords-match',
          'Senhas não coincidem.',
          value => newPassword === value,
        ),
    });

    try {
      await schema.validate(
        { newPassword, confirmNewPassword },
        { abortEarly: false },
      );

      await api.post('/recover-password', {
        new_password: newPassword,
        token,
      });

      setIsSuccess(true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.log(error);

      if (error instanceof yup.ValidationError) {
        let errorAux = {};

        error.inner.forEach(e => {
          errorAux = {
            ...errorAux,
            [e.path as string]: e.message,
          };
        });

        setErrorMessage(errorAux);
        return;
      }

      CustomAlert('error', 'Não foi possível recuperar a senha');
    }
  };

  useEffect(() => {
    async function verifyToken() {
      try {
        await api.post('verify-token', { token });
        setIsAuthorized(true);
        setIsLoading(false);
      } catch (error) {
        setIsAuthorized(false);
        setIsLoading(false);
      }
    }

    verifyToken();
  }, [token]);

  if (isLoading) {
    return (
      <Grid
        container
        item
        justifyContent="center"
        alignItems="center"
        style={{ height: '100vh' }}
      >
        <CircularProgress />
      </Grid>
    );
  }

  if (!isAuthorized) {
    return <MessageResetPassword success={false} />;
  }

  if (isSuccess) {
    return <MessageResetPassword success />;
  }

  return (
    <Grid container item>
      <Grid
        container
        item
        direction="column"
        xs={12}
        md={4}
        justifyContent="center"
      >
        <Grid container item className={classes.logo}>
          <img src={logo} alt="logo" className={classes.logoImg} />
        </Grid>

        <Grid container item direction="column" className={classes.texts}>
          <Typography className={classes.title}>Redefinir Senha</Typography>

          <Typography className={classes.description}>
            Por favor, escolha sua nova senha
          </Typography>
        </Grid>

        <Grid container item direction="column" className={classes.inputs}>
          <TextField
            label="Nova Senha"
            variant="outlined"
            value={newPassword}
            onChange={e => {
              setNewPassword(e.target.value);
            }}
            type={visibilityNewPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={() => {
                    setVisibilityNewPassword(!visibilityNewPassword);
                  }}
                >
                  {visibilityNewPassword ? (
                    <VisibilityIcon />
                  ) : (
                    <VisibilityOffIcon />
                  )}
                </IconButton>
              ),
            }}
            error={Boolean(errorMessage?.newPassword)}
            helperText={errorMessage?.newPassword}
          />

          <TextField
            label="Confirme sua senha"
            className={classes.field}
            variant="outlined"
            value={confirmNewPassword}
            onChange={e => {
              setConfirmNewPassword(e.target.value);
            }}
            type={visibilityConfirmPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={() => {
                    setVisibilityConfirmPassword(!visibilityConfirmPassword);
                  }}
                >
                  {visibilityConfirmPassword ? (
                    <VisibilityIcon />
                  ) : (
                    <VisibilityOffIcon />
                  )}
                </IconButton>
              ),
            }}
            error={Boolean(errorMessage?.confirmNewPassword)}
            helperText={errorMessage?.confirmNewPassword}
          />

          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Salvar Nova Senha
          </Button>
        </Grid>
      </Grid>

      <Grid item xs={8} className={classes.backgroundBox}>
        <div className={classes.backgroundImg} />
      </Grid>
    </Grid>
  );
}
