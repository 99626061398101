/* eslint-disable import/prefer-default-export */
import moment from 'moment';
import { useState, useEffect } from 'react';

export function useDate() {
  const [date, setDate] = useState(moment());

  useEffect(() => {
    const timer = setInterval(() => {
      setDate(moment());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return { date };
}
