import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import ModalEditProfile from '../../components/ModalEditProfile';
import ModalChangePassword from '../../components/ModalChangePassword';
import Layout from '../../components/Layout';

import { useAuth } from '../../contexts/auth';

import COLORS from '../../utils/colors';

const useStyles = makeStyles({
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    rowGap: '3.5rem',
    width: '50%',
  },

  field: {
    borderBottom: `1px solid ${COLORS.gray}`,

    '& label': {
      color: COLORS.gray,
      fontSize: '1.125rem',
    },

    '& input': {
      color: COLORS.gray,
      fontSize: '1.375rem',
      fontWeight: 500,
    },

    '& input.name': {
      fontSize: '1.875rem',
      fontWeight: 700,
    },

    '& svg': {
      color: COLORS.gray,
      fontSize: '1.5rem',
    },
  },

  buttonLogout: {
    padding: 0,
    height: '3rem',
    width: '13.375rem',
    marginTop: '2.375rem',
    textTransform: 'none',
    borderRadius: '0.625rem',
    border: `1px solid ${COLORS.redAlert}`,
    color: COLORS.redAlert,
    fontWeight: 500,
    fontSize: '1.375rem',

    '&:hover': {
      backgroundColor: COLORS.redAlert,
      color: COLORS.whitePure,
      transition: '0.3s',
    },
  },

  tooltip: {
    position: 'absolute',
    bottom: '3.125rem',
    right: '3.125rem',
    height: '2.875rem',
    width: '2.875rem',
    border: `1px solid ${COLORS.gray2}`,
    color: COLORS.gray2,
    borderRadius: '50%',
    fontSize: '1.875rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '&:hover': {
      backgroundColor: COLORS.gray2,
      color: COLORS.whitePure,
      transition: '0.3s',
    },
  },
});

const CustomToolTip = withStyles({
  tooltip: {
    backgroundColor: COLORS.gray2,
    color: COLORS.whitePure,
    borderRadius: '0.5rem',
    fontSize: '.875rem',
  },
})(Tooltip);

export default function Profile() {
  const classes = useStyles();
  const { user, logout } = useAuth();

  return (
    <Layout routeSelected="profile" type={user.role}>
      <div className={classes.container}>
        <div className={classes.content}>
          <TextField
            label="Nome"
            value={user.name}
            InputProps={{
              endAdornment: (
                <ModalEditProfile
                  field="Nome"
                  value={user.name}
                  typeInput="text"
                />
              ),
              disabled: true,
              inputProps: {
                className: 'name',
              },
              disableUnderline: true,
            }}
            fullWidth
            className={classes.field}
          />

          <TextField
            label="E-mail"
            value={user.email}
            InputProps={{
              endAdornment: (
                <ModalEditProfile
                  field="E-mail"
                  value={user.email}
                  typeInput="email"
                />
              ),
              disabled: true,
              disableUnderline: true,
            }}
            fullWidth
            className={classes.field}
          />

          <TextField
            label="Telefone"
            value={user.cellphone}
            InputProps={{
              endAdornment: (
                <ModalEditProfile
                  field="Telefone"
                  value={user.cellphone}
                  typeInput="tel"
                />
              ),
              disabled: true,
              disableUnderline: true,
            }}
            fullWidth
            className={classes.field}
          />

          <TextField
            label="Telefone de emergência"
            value={user.emergency_phone}
            InputProps={{
              endAdornment: (
                <ModalEditProfile
                  field="Telefone de Emergência"
                  value={user.emergency_phone}
                  typeInput="tel"
                />
              ),
              disabled: true,
              disableUnderline: true,
            }}
            fullWidth
            className={classes.field}
          />

          <TextField
            label="Senha"
            value="******"
            InputProps={{
              endAdornment: <ModalChangePassword />,
              disabled: true,
              disableUnderline: true,
            }}
            fullWidth
            className={classes.field}
          />

          <Button onClick={logout} className={classes.buttonLogout}>
            Encerrar sessão
          </Button>
        </div>

        <CustomToolTip title="DattaSea 3.0" className={classes.tooltip}>
          <div>?</div>
        </CustomToolTip>
      </div>
    </Layout>
  );
}
