import ModalCancelExit from '../ModalCancelExit';
import ModalCancelNavigate from '../ModalCancelNavigate';
import ModalLeaveNavigate from '../ModalLeaveNavigate';
import ModalRegressNavigate from '../ModalRegressNavigate';

import { TileNavigation } from '../../interfaces/TileNavigation';
import { Navigation } from '../../interfaces/Navigation';

const getActionsBy = (
  type: TileNavigation,
  navigation: Navigation,
  typeButton: 'normal' | 'onModal',
) => {
  const actionsBy = {
    exit: {
      cancel: (
        <ModalCancelExit navigation={navigation} typeButton={typeButton} />
      ),
      confirm: (
        <ModalLeaveNavigate navigation={navigation} typeButton={typeButton} />
      ),
    },
    navigate: {
      cancel: (
        <ModalCancelNavigate
          navigation={navigation}
          type="navigates"
          typeButton={typeButton}
        />
      ),
      confirm: (
        <ModalRegressNavigate
          navigation={navigation}
          type="navigates"
          typeButton={typeButton}
        />
      ),
    },

    arrival: {
      cancel: (
        <ModalCancelNavigate
          navigation={navigation}
          type="arrivals"
          typeButton={typeButton}
        />
      ),
      confirm: (
        <ModalRegressNavigate
          navigation={navigation}
          type="arrivals"
          typeButton={typeButton}
        />
      ),
    },
  };

  return actionsBy[type];
};

export default getActionsBy;
