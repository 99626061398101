import { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import * as yup from 'yup';

import CustomAlert from '../CustomAlert';

import { Marina } from '../../interfaces/Marina';

import api from '../../services/api';

import COLORS from '../../utils/colors';

const useStyles = makeStyles({
  buttonCreateMarina: {
    textTransform: 'none',

    '&:hover': {
      background: COLORS.greenPrimary,
      opacity: '0.9',
      transition: '0.3s',
    },
  },

  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& span': {
      fontSize: '1.25rem',
      fontWeight: 500,
      lineHeight: '1.6',
    },

    '& svg': {
      fontSize: '2rem',
      cursor: 'pointer',

      '&:hover': {
        color: COLORS.gray2,
        transition: '0.3s',
      },
    },
  },

  input: {
    margin: '0.5rem auto',
    paddingRight: '1rem',
  },

  actions: {
    margin: '1.5rem auto',
  },

  buttonRegister: {
    textTransform: 'none',
    paddingLeft: '2rem',
    paddingRight: '2rem',
  },
});

interface Props {
  setMarinas: React.Dispatch<React.SetStateAction<Marina[]>>;
  setTotalTableMarinas: React.Dispatch<React.SetStateAction<number>>;
  rowsPerPage: number;
  currentPage: number;
  totalTableMarinas: number;
}

interface ErrorMessage {
  name_marina?: string;
  cnpj?: string;
  accountable?: string;
  phone?: string;
  address?: string;
}

const ModalCreateMarina: React.FC<Props> = ({
  setMarinas,
  setTotalTableMarinas,
  rowsPerPage,
  currentPage,
  totalTableMarinas,
}) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const [marinaName, setMarinaName] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [accountable, setAccountable] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');

  const [errorMessage, setErrorMessage] = useState<ErrorMessage>({});

  const handleClose = () => {
    setOpen(false);
    setMarinaName('');
    setCnpj('');
    setAccountable('');
    setPhone('');
    setAddress('');
    setErrorMessage({});
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSubmit = async () => {
    setErrorMessage({});

    const marina = {
      name_marina: marinaName,
      cnpj,
      accountable,
      phone,
      address,
    };

    const schema = yup.object().shape({
      name_marina: yup.string().required('Este campo é obrigatório.'),
      cnpj: yup
        .string()
        .required('Este campo é obrigatório.')
        // Credits: https://gist.github.com/igorcosta/3a4caa954a99035903ab
        .matches(/(^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$)|(^\d{14}$)/, {
          message: 'CNPJ informado tem formato inválido',
        }),
      accountable: yup.string().required('Este campo é obrigatório.'),
      phone: yup
        .string()
        .required('Este campo é obrigatório')
        .matches(/^\(?[1-9]{2}\)? ?(?:[2-8]|9 ?[1-9])[0-9]{3}-?[0-9]{4}$/, {
          message: 'Telefone informado tem formato inválido',
        }),
      address: yup.string().required('Este campo é obrigatório.'),
    });

    try {
      await schema.validate(marina, { abortEarly: false });
      const response = await api.post('/api/marina', marina);
      const newMarina = response.data;

      if (totalTableMarinas < rowsPerPage * currentPage) {
        setMarinas(prevMarinas => [...prevMarinas, newMarina]);
      }

      setTotalTableMarinas(totalTableMarinas + 1);

      CustomAlert('success', 'Marina foi criada!');

      handleClose();
    } catch (error) {
      console.log(error);

      if (error instanceof yup.ValidationError) {
        let errorAux = {};

        error.inner.forEach(e => {
          errorAux = {
            ...errorAux,
            [e.path as string]: e.message,
          };
        });

        setErrorMessage(errorAux);
        return;
      }

      CustomAlert('error', 'Erro inesperado. Por favor, tente novamente!');
    }
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        disableElevation
        className={classes.buttonCreateMarina}
        onClick={handleOpen}
      >
        Criar marina
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle className={classes.titleContainer} disableTypography>
          <span>Criar marina</span>

          <CloseIcon onClick={handleClose} />
        </DialogTitle>

        <DialogContent>
          <TextField
            label="Nome"
            className={classes.input}
            fullWidth
            value={marinaName}
            onChange={e => {
              setMarinaName(e.target.value);
            }}
            required
            error={Boolean(errorMessage?.name_marina)}
            helperText={errorMessage?.name_marina}
          />

          <TextField
            label="CNPJ"
            className={classes.input}
            fullWidth
            value={cnpj}
            onChange={e => {
              setCnpj(e.target.value);
            }}
            required
            error={Boolean(errorMessage?.cnpj)}
            helperText={errorMessage?.cnpj}
          />

          <TextField
            label="Telefone"
            className={classes.input}
            fullWidth
            value={phone}
            onChange={e => {
              setPhone(e.target.value);
            }}
            required
            error={Boolean(errorMessage?.phone)}
            helperText={errorMessage?.phone}
          />

          <TextField
            label="Responsável"
            className={classes.input}
            fullWidth
            value={accountable}
            onChange={e => {
              setAccountable(e.target.value);
            }}
            required
            error={Boolean(errorMessage?.accountable)}
            helperText={errorMessage?.accountable}
          />

          <TextField
            label="Endereço"
            className={classes.input}
            fullWidth
            value={address}
            onChange={e => {
              setAddress(e.target.value);
            }}
            required
            error={Boolean(errorMessage?.address)}
            helperText={errorMessage?.address}
          />
        </DialogContent>

        <DialogActions className={classes.actions}>
          <Button
            color="primary"
            variant="contained"
            disableElevation
            className={classes.buttonRegister}
            onClick={handleSubmit}
          >
            Criar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ModalCreateMarina;
