import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import COLORS from '../../utils/colors';

import { ReactComponent as ErrorImg } from '../../images/error.svg';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    width: '100vw',
    backgroundColor: COLORS.greenPrimary,
    alignItems: 'center',
    justifyContent: 'center',

    '& h1': {
      color: COLORS.orangePrimary,
      fontSize: '4.5rem',
      lineHeight: '1.5',
      textAlign: 'center',
      fontWeight: '400',
    },

    '& h2': {
      color: COLORS.whitePure,
      textAlign: 'center',
      lineHeight: '1.5',
      marginBottom: '2.5rem',
      fontSize: '1.875rem',
      fontWeight: '400',
    },
  },
});

export default function NotFound() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <h1>404</h1>

      <h2>Página não encontrada</h2>

      <ErrorImg />
    </div>
  );
}
