import React from 'react';

export default function Privacy() {
  return (
    <pre>
      {`
	Política de privacidade
	Última atualização: 04.11.2020

	Este site DattaSea é mantido e operado por VS DATTA IMAGEM COMERCIO E SERVIÇOS EIRELI. 
	Nós coletamos e utilizamos alguns dados pessoais que pertencem àqueles que utilizam nosso site. 
	Ao fazê-lo, agimos na qualidade de controlador desses dados e estamos sujeitos às disposições da Lei Federal n. 13.709/2018 (Lei Geral de Proteção de Dados Pessoais - LGPD). 
	
	Nós cuidamos da proteção de seus dados pessoais e, por isso, disponibilizamos esta política de privacidade, que contém informações importantes sobre:
	•	Quem deve utilizar nosso site;
	•	Quais dados coletamos e o que fazemos com eles;
	•	Seus direitos em relação aos seus dados pessoais; e
	•	Como entrar em contato conosco.

	1. Quem deve utilizar nosso site
		Nosso site só deve ser utilizado por pessoas com mais de dezoito anos de idade. Sendo assim, crianças e adolescentes não devem utilizá-lo.

	2. Dados que coletamos e motivos da coleta
		Nosso site coleta e utiliza alguns dados pessoais de nossos usuários, de acordo com o disposto nesta seção.

		2.1. Dados pessoais fornecidos expressamente pelo usuário:

			Nós coletamos os seguintes dados pessoais que nossos usuários nos fornecem expressamente ao utilizar nosso site:
			•	Nome Completo;
			•	Data de Nascimento;
			•	Sexo;
			•	CPF;
			•	RG;
			•	Endereço eletrônico (E-mail);
			•	Endereço Residencial;
			•	Telefone de Contato;
			•	Se representante legal:
			•	Razão Social;
			•	CNPJ;
			•	Endereço da Empresa.

			A coleta destes dados ocorre nos seguintes momentos:
			•	Quando o usuário faz o cadastro inicial no nosso portal;
			•	Quando o usuário faz o cadastro no nosso App.

			Os dados fornecidos por nossos usuários são coletados com as seguintes finalidades:
			•	Para a identificação dos usuários dos nossos serviços.
			•	Para fornecer informações à Marinha do Brasil sobre a movimentação de embarcações

		2.2. Dados pessoais obtidos de outras formas

			Nós coletamos os seguintes dados pessoais de nossos usuários:
			•	Endereço IP;
			•	Dados de Geolocalização;
			•	Dados de transações realizadas por meio de utilização do portal e do App.

			A coleta destes dados ocorre nos seguintes momentos:
			•	Na identificação de uma embarcação;
			•	Na liberação de uma embarcação para saída;
			•	No fornecimento de informações à Marinha do Brasil.

		2.3. Dados sensíveis
			Não serão coletados dados sensíveis de nossos usuários, assim entendidos aqueles definidos nos arts. 11 e seguintes da Lei de Proteção de Dados Pessoais.
			Assim, não haverá coleta de dados sobre origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, 
			filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico, quando vinculado a uma pessoa natural.

		2.4. Cookies
			Cookies são pequenos arquivos de texto baixados automaticamente em seu dispositivo quando você acessa e navega por um site. 
			Eles servem, basicamente, para seja possível identificar dispositivos, atividades e preferências de usuários. 
			Os cookies não permitem que qualquer arquivo ou informação sejam extraídos do disco rígido do usuário, não sendo possível, ainda, que, por meio deles, 
			se tenha acesso a informações pessoais que não tenham partido do usuário ou da forma como utiliza os recursos do site.

			a.	Cookies do site
				Os cookies do site são aqueles enviados ao computador ou dispositivo do usuário e administrador exclusivamente pelo site.
				As informações coletadas por meio destes cookies são utilizadas para melhorar e personalizar a experiência do usuário, sendo que alguns cookies podem, por exemplo,
				ser utilizados para lembrar as preferências e escolhas do usuário, bem como para o oferecimento de conteúdo personalizado.

			b.	Cookies de terceiros
				Alguns de nossos parceiros podem configurar cookies nos dispositivos dos usuários que acessam nosso site. 
				Estes cookies, em geral, visam possibilitar que nossos parceiros possam oferecer seu conteúdo e seus serviços ao usuário que acessa nosso site de forma personalizada,
				por meio da obtenção de dados de navegação extraídos a partir de sua interação com o site. O usuário poderá obter mais informações sobre os cookies de terceiro e sobre a
				forma como os dados obtidos a partir dele são tratados, além de ter acesso à descrição dos cookies utilizados e de suas características, acessando o seguinte link:

				• Google Analytics:
				https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=pt-BR

				As entidades encarregadas da coleta dos cookies poderão ceder as informações obtidas a terceiros.

			c. Gestão de cookies
				O usuário poderá se opor ao registro de cookies pelo site, bastando que desative esta opção no seu próprio navegador. 
				Mais informações sobre como fazer isso em alguns dos principais navegadores utilizados hoje podem ser acessadas a partir dos seguintes links:

				Internet Explorer:
				https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-delete-manage-cookies

				Safari:
				https://support.apple.com/pt-br/guide/safari/sfri11471/mac

				Google Chrome:
				https://support.google.com/chrome/answer/95647?hl=pt-BR&hlrm=pt

				Mozilla Firefox:
				https://support.mozilla.org/pt-BR/kb/ative-e-desative-os-cookies-que-os-sites-usam

				Opera:
				https://www.opera.com/help/tutorials/security/privacy/

				A desativação dos cookies, no entanto, pode afetar a disponibilidade de algumas ferramentas e funcionalidades do site, comprometendo seu correto e esperado funcionamento.
				Outra consequência possível é remoção das preferências do usuário que eventualmente tiverem sido salvas, prejudicando sua experiência.

			2.5. Coleta de dados não previstos expressamente
				Eventualmente, outros tipos de dados não previstos expressamente nesta Política de Privacidade poderão ser coletados, desde que sejam fornecidos com o consentimento
				do usuário, ou, ainda, que a coleta seja permitida com fundamento em outra base legal prevista em lei.
				Em qualquer caso, a coleta de dados e as atividades de tratamento dela decorrentes serão informadas aos usuários do site.

	3. Compartilhamento de dados pessoais com terceiros
		Parte dos dados coletados no ato do cadastro serão eventualmente compartilhados com a Marinha do Brasil, com a finalidade de fornecer informações sobre embarcações e condutores.
		Além disso, é possível que os compartilhemos para cumprir alguma determinação legal ou regulatória, ou, ainda, para cumprir alguma ordem expedida por autoridade pública.

	4. Por quanto tempo seus dados pessoais serão armazenados
		Os dados pessoais coletados pelo site são armazenados e utilizados por período de tempo que corresponda ao necessário para atingir as finalidades elencadas neste documento e que 
		considere os direitos de seus titulares, os direitos do controlador do site e as disposições legais ou regulatórias aplicáveis.
		Uma vez expirados os períodos de armazenamento dos dados pessoais, eles são removidos de nossas bases de dados ou anonimizados, salvo nos casos em que houver a possibilidade ou a 
		necessidade de armazenamento em virtude de disposição legal ou regulatória.

	5. Bases legais para o tratamento de dados pessoais
		A geração dos contratos e termos pode ser realizada de forma dinâmica e baseada em cada tipo de documento. Para isto, disponibilizamos um conjunto de funcionalidades, objetivando
		permitir uma maior flexibilidade na integração, permitindo maior acoplamento e abrangência as necessidades do usuário: Uma base legal para o tratamento de dados pessoais nada mais 
		é que um fundamento jurídico, previsto em lei, que justifica o justifica. Assim, cada operação de tratamento de dados pessoais precisa ter uma base legal a ela correspondente.
		
		Nós tratamos os dados pessoais de nossos usuários nas seguintes hipóteses:
		a.	Mediante o consentimento do titular dos dados pessoais;
		b.	Para o exercício regular de direitos em processo judicial, administrativo ou arbitral;
		c.	Para a execução de contrato ou de procedimentos preliminares relacionados a contrato do qual seja parte o titular, a pedido do titular dos dados pessoais;
		d.	Quando necessário para atender aos interesses legítimos do controlador ou de terceiros.

		5.1. Consentimento
			Determinadas operações de tratamento de dados pessoais realizadas em nosso site dependerão da prévia concordância do usuário, que deverá manifestá-la de forma livre, informada e
			inequívoca.

			O usuário poderá revogar seu consentimento a qualquer momento, sendo que, não havendo hipótese legal que permita ou que demande o armazenamento dos dados, os dados fornecidos
			mediante consentimento serão excluídos. Além disso, se desejar, o usuário poderá não concordar com alguma operação de tratamento de dados pessoais baseada no consentimento. 
			Nestes casos, porém, é possível que não possa utilizar alguma funcionalidade do site que dependa daquela operação. As consequências da falta de consentimento para uma atividade 
			específica são informadas previamente ao tratamento.

		5.2. Legítimo interesse
			Para determinadas operações de tratamento de dados pessoais, nos baseamos exclusivamente em nosso interesse legítimo. Para saber mais sobre em quais casos, especificamente,
			nos valemos desta base legal, ou para obter mais informações sobre os testes que fazemos para termos certeza de que podemos utilizá-la, entre em contato com nosso Encarregado
			de Proteção de Dados Pessoais por algum dos canais informados nesta Política de Privacidade, na seção "Como entrar em contato conosco".

	6. Direitos do usuário

		O usuário do site possui os seguintes direitos, conferidos pela Lei de Proteção de Dados Pessoais:
		•	Confirmação da existência de tratamento;
		•	Acesso aos dados;
		•	Correção de dados incompletos, inexatos ou desatualizados;
		•	Anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o disposto na lei;
		•	Portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa, de acordo com a regulamentação da autoridade nacional, observados os segredos
			comercial e industrial;
		•	Eliminação dos dados pessoais tratados com o consentimento do titular, exceto nos casos previstos em lei;
		•	Informação das entidades públicas e privadas com as quais o controlador realizou uso compartilhado de dados;
		•	Informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa;
		•	Revogação do consentimento.

		É importante destacar que, nos termos da LGPD, não existe um direito de eliminação de dados tratados com fundamento em bases legais distintas do consentimento, a menos que os dados
		sejam desnecessários, excessivos ou tratados em desconformidade com o previsto na lei.

		6.1. Como o titular pode exercer seus direitos
			Os titulares de dados pessoais tratados por nós poderão exercer seus direitos por meio do formulário disponibilizado no seguinte caminho: https://dattasea.com.br. 
			Alternativamente, se desejar, o titular poderá enviar um e-mail ou uma correspondência ao nosso Encarregado de Proteção de Dados Pessoais. As informações necessárias para isso
			estão na seção "Como entrar em contato conosco" desta Política de Privacidade. Os titulares de dados pessoais tratados por nós poderão exercer seus direitos a partir do envio
			de mensagem ao nosso Encarregado de Proteção de Dados Pessoais, seja por e-mail ou por correspondência. As informações necessárias para isso estão na seção "Como entrar em 
			contato conosco" desta Política de Privacidade. Para garantir que o usuário que pretende exercer seus direitos é, de fato, o titular dos dados pessoais objeto da requisição,
			poderemos  solicitar documentos ou outras informações que possam auxiliar em sua correta identificação, a fim de resguardar nossos direitos e os direitos de terceiros. Isto 
			somente será feito, porém, se for absolutamente necessário, e o requerente receberá todas as informações relacionadas.

	7. Medidas de segurança no tratamento de dados pessoais
		Empregamos medidas técnicas e organizativas aptas a proteger os dados pessoais de acessos não autorizados e de situações de destruição, perda, extravio ou alteração desses dados.

		As medidas que utilizamos levam em consideração a natureza dos dados, o contexto e a finalidade do tratamento, os riscos que uma eventual violação geraria para os direitos e
		liberdades do usuário, e os padrões atualmente empregados no mercado por empresas semelhantes à nossa.

		Entre as medidas de segurança adotadas por nós, destacamos as seguintes:
		•	Critérios fortes para definição de senhas;
		•	Armazenamento de senhas utilizando hashes criptográficos;
		•	Restrições de acessos a banco de dados e URLs;
		•	Restrição de acesso baseadas em perfis de usuário;
		•	Monitoramento de acessos físicos a servidores.

		Ainda que adote tudo o que está ao seu alcance para evitar incidentes de segurança, é possível que ocorra algum problema motivado exclusivamente por um terceiro - como em caso de
		ataques de hackers ou crackers ou, ainda, em caso de culpa exclusiva do usuário, que ocorre, por exemplo, quando ele mesmo transfere seus dados a terceiro. Assim, embora sejamos,
		em geral, responsáveis pelos dados pessoais que tratamos, nos eximimos de responsabilidade caso ocorra uma situação excepcional como essas, sobre as quais não temos nenhum tipo 
		de controle.

		De qualquer forma, caso ocorra qualquer tipo de incidente de segurança que possa gerar risco ou dano relevante para qualquer de nossos usuários, comunicaremos os afetados e a
		Autoridade Nacional de Proteção de Dados acerca do ocorrido, em conformidade com o disposto na Lei Geral de Proteção de Dados.

	8. Reclamação a uma autoridade de controle
		Sem prejuízo de qualquer outra via de recurso administrativo ou judicial, os titulares de dados pessoais que se sentirem, de qualquer forma, lesados, podem apresentar reclamação
		à Autoridade Nacional de Proteção de Dados.

	9. Alterações nesta política
		A presente versão desta Política de Privacidade foi atualizada pela última vez em: 05/03/2021.

		Nos reservamos o direito de modificar, a qualquer momento, as presentes normas, especialmente para adaptá-las às eventuais alterações feitas em nosso site, seja pela disponibilização
		de novas funcionalidades, seja pela supressão ou modificação daquelas já existentes.

		Sempre que houver uma modificação, nossos usuários serão notificados acerca da mudança.

	10. Como entrar em contato conosco
		Para esclarecer quaisquer dúvidas sobre esta Política de Privacidade ou sobre os dados pessoais que tratamos, entre em contato com nosso Encarregado de Proteção de Dados Pessoais,
		por algum dos canais mencionados abaixo:

		E-mail: contato@dattasea.com.br

		Telefone: +55 83 3225-5858

		Endereço postal: Av. Epitácio Pessoa, N 1145, Sala A E B Andar 1. Bairro dos Estados, CEP 58.030-000, João Pessoa-PB.

		
`}
    </pre>
  );
}
