import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';

import CustomPagination from '../../../components/CustomPagination';
import TileNavigation from '../../../components/TileNavigation';

import { useNavigations } from '../../../contexts/navigations';
import COLORS from '../../../utils/colors';

const useStyles = makeStyles({
  container: {
    height: '100%',
    paddingTop: '2.25rem',
    marginTop: '2.5rem',
    flexDirection: 'column',
    justifyContent: 'space-between',

    '& .customPagination': {
      alignSelf: 'center',
    },
  },

  header: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '1.125rem',

    '& h1, & span': {
      color: COLORS.greenPrimary,
      fontSize: '1.875rem',
      fontWeight: 700,
      lineHeight: '2.625rem',
    },

    '& span': {
      color: COLORS.gray,
    },
  },

  content: {
    display: 'grid',
    height: '83%',
    gridTemplateColumns: '1fr 1fr 1fr',
    columnGap: '1rem',
    rowGap: '.75rem',
  },
});

interface Props {
  id: string;
  ariaLabelledby: string;
  role: string;
  hidden: boolean;
}

const PlannedDepartures: React.FC<Props> = ({
  id,
  ariaLabelledby,
  role,
  hidden,
}) => {
  const { exits } = useNavigations();
  const [page, setPage] = useState(1);
  const rowsPerPage = 15;

  const classes = useStyles();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  return (
    <div
      id={id}
      aria-labelledby={ariaLabelledby}
      role={role}
      style={{ display: hidden ? 'none' : 'flex' }}
      className={classes.container}
    >
      <div className={classes.header}>
        <h1>Saídas Previstas</h1>

        <span>{exits.length}</span>
      </div>

      <div className={classes.content}>
        {exits
          ?.slice(
            (page - 1) * rowsPerPage,
            (page - 1) * rowsPerPage + rowsPerPage,
          )
          .map(element => (
            <TileNavigation key={element.id} data={element} type="exit" />
          ))}
      </div>

      <div className="customPagination">
        <CustomPagination
          page={page}
          rowsPerPage={rowsPerPage}
          total={exits?.length}
          handleChangePage={handleChangePage}
        />
      </div>
    </div>
  );
};

export default PlannedDepartures;
