/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-no-constructed-context-values */
import { createContext, useState, useContext } from 'react';
import moment from 'moment';

import { Navigation } from '../interfaces/Navigation';

interface NavigationsContextProps {
  exits: Navigation[];
  navigates: Navigation[];
  arrivals: Navigation[];
  setExits: (value: Navigation[]) => void;
  setNavigates: (value: Navigation[]) => void;
  setArrivals: (value: Navigation[]) => void;
  handleAuthorizeExit: (currentWatercraft: Navigation) => void;
  handleUnauthorizedExit: (currentWatercraft: Navigation, type: string) => void;
  latestDepartures: Navigation[];
  setLatestDepartures: (value: Navigation[]) => void;
}

const NavigationsContext = createContext({});

export const NavigationsProvider: React.FC = ({ children }) => {
  const [exits, setExits] = useState<Navigation[]>([]);
  const [navigates, setNavigates] = useState<Navigation[]>([]);
  const [arrivals, setArrivals] = useState<Navigation[]>([]);
  const [latestDepartures, setLatestDepartures] = useState<Navigation[]>([]);

  const handleAuthorizeExit = (currentWatercraft: Navigation) => {
    setExits(
      exits
        .filter(exit => exit.id !== currentWatercraft.id)
        .sort(
          (a, b) =>
            moment(a.exit_forecast).valueOf() -
            moment(b.exit_forecast).valueOf(),
        ),
    );
    currentWatercraft.status = 1;

    const arrivalForecast = moment(currentWatercraft.arrival_forecast);
    const momentAuthorizedToExit = moment().add(30, 'minutes');

    if (arrivalForecast.isAfter(momentAuthorizedToExit)) {
      setNavigates(
        [...navigates, currentWatercraft].sort(
          (a, b) =>
            moment(a.arrival_forecast).valueOf() -
            moment(b.arrival_forecast).valueOf(),
        ),
      );
      return;
    }

    setArrivals(
      [...arrivals, currentWatercraft].sort(
        (a, b) =>
          moment(a.arrival_forecast).valueOf() -
          moment(b.arrival_forecast).valueOf(),
      ),
    );
  };

  const handleUnauthorizedExit = (
    currentWatercraft: Navigation,
    type: string,
  ) => {
    currentWatercraft.status = 0;
    setExits(
      [...exits, currentWatercraft].sort(
        (a, b) =>
          moment(a.exit_forecast).valueOf() - moment(b.exit_forecast).valueOf(),
      ),
    );

    if (type === 'navigates') {
      setNavigates(
        navigates
          .filter(navigate => currentWatercraft.id !== navigate.id)
          .sort(
            (a, b) =>
              moment(a.arrival_forecast).valueOf() -
              moment(b.arrival_forecast).valueOf(),
          ),
      );
      return;
    }

    setArrivals(
      arrivals
        .filter(arrival => currentWatercraft.id !== arrival.id)
        .sort(
          (a, b) =>
            moment(a.arrival_forecast).valueOf() -
            moment(b.arrival_forecast).valueOf(),
        ),
    );
  };

  return (
    <NavigationsContext.Provider
      value={{
        exits,
        navigates,
        arrivals,
        setExits,
        setNavigates,
        setArrivals,
        handleAuthorizeExit,
        handleUnauthorizedExit,
        latestDepartures,
        setLatestDepartures,
      }}
    >
      {children}
    </NavigationsContext.Provider>
  );
};

export function useNavigations(): NavigationsContextProps {
  const context = useContext(NavigationsContext) as NavigationsContextProps;

  return context;
}
