import Canvg, { RenderingContext2D } from 'canvg';

/*
  Credits: 
    https://stackoverflow.com/questions/28450471/convert-inline-svg-to-base64-string/28450879
    https://stackoverflow.com/questions/3975499/convert-svg-to-image-jpeg-png-etc-in-the-browser
*/

function getCanvasBlob(canvas: HTMLCanvasElement): Promise<Blob | null> {
  return new Promise(resolve => {
    canvas.toBlob(blob => {
      resolve(blob);
    });
  });
}

export default async function svgToBlob(svg: HTMLElement | null) {
  const svgSerialized = new XMLSerializer().serializeToString(svg as Node);

  const canvas = document.createElement('canvas');
  canvas.width = svg?.clientWidth || 0;
  canvas.height = svg?.clientHeight || 0;

  const ctx = canvas.getContext('2d');
  const svgRendererOnCanvas = Canvg.fromString(
    ctx as RenderingContext2D,
    svgSerialized,
  );
  await svgRendererOnCanvas.render();

  const canvasBlob = await getCanvasBlob(canvas);

  return canvasBlob;
}
