import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import CustomAlert from '../CustomAlert';

import { Watercraft } from '../../interfaces/Watercraft';

import api from '../../services/api';

import COLORS from '../../utils/colors';

const dialogWidth = '53rem';

const useStyles = makeStyles({
  buttonDelete: {
    fontSize: '1.625rem',
    color: COLORS.redAlert,
    cursor: 'pointer',

    '&:hover': {
      transition: '0.3s',
      opacity: '0.9',
    },
  },

  title: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '3.125rem 3.125rem 0 5rem',

    '& h1': {
      color: COLORS.gray,
      fontWeight: 500,
      fontSize: '1.875rem',
      maxWidth: '40.375rem',
      lineHeight: '2.625rem',

      '& .delete': {
        fontWeight: 700,
        color: COLORS.redAlert,
      },

      '& .name': {
        fontWeight: 700,
        color: COLORS.greenSecondary,
      },
    },

    '& div': {
      cursor: 'pointer',
      border: `1px solid ${COLORS.gray3}`,
      borderRadius: '0.375rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '2.25rem',
      height: '2.25rem',

      '& svg': {
        fontSize: '1.5rem',
        color: COLORS.gray,
      },

      '&:hover': {
        backgroundColor: `${COLORS.greenPrimary}0a`,
        transition: '0.3s',
      },
    },
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '3.125rem 5rem 3.875rem 5rem',
  },

  fields: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '1.25rem',

    '& h2': {
      fontSize: '1.375rem',
      fontWeight: 500,
      color: COLORS.gray3,

      '& span': {
        fontSize: '1.375rem',
        fontWeight: 500,
        color: COLORS.blackPure,
      },
    },
  },

  buttonsContainer: {
    display: 'flex',
    marginTop: '7.25rem',
    justifyContent: 'center',
    columnGap: '1.625rem',
  },

  buttonNo: {
    height: '4.375rem',
    width: '11.25rem',
    padding: 0,
    textTransform: 'none',
    fontWeight: 500,
    fontSize: '1.125rem',
    lineHeight: '1.625rem',
    borderRadius: '0.25rem',
    border: `1px solid ${COLORS.greenAlert}`,
    color: COLORS.greenAlert,
  },

  buttonYes: {
    height: '4.375rem',
    width: '11.25rem',
    padding: 0,
    textTransform: 'none',
    fontWeight: 500,
    fontSize: '1.125rem',
    lineHeight: '1.625rem',
    borderRadius: '0.25rem',
    border: `1px solid ${COLORS.redAlert}`,
    color: COLORS.redAlert,
  },
});

interface Props {
  watercraft: Watercraft;
  setWatercrafts: React.Dispatch<React.SetStateAction<Watercraft[]>>;
}

const ModalDeleteWatercraft: React.FC<Props> = ({
  watercraft,
  setWatercrafts,
}) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const handleOpen = (e: React.MouseEvent) => {
    e.stopPropagation();

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    try {
      await api.delete(`/api/watercraft/revoke/${watercraft.id}`);

      setWatercrafts((prevStateWatercrafts: Watercraft[]) => {
        const updatedWatercrafts = prevStateWatercrafts.filter(
          element => element.id !== watercraft.id,
        );
        return updatedWatercrafts;
      });

      CustomAlert(
        'success',
        `A embarcação '${watercraft.name_boating}' foi excluída!`,
      );

      handleClose();
    } catch (error) {
      CustomAlert('error', 'Erro inesperado. Por favor, tente novamente!');
    }
  };

  return (
    <>
      <DeleteOutlineIcon
        className={classes.buttonDelete}
        onClick={handleOpen}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{ style: { width: dialogWidth } }}
        maxWidth={false}
      >
        <DialogTitle className={classes.title} disableTypography>
          <h1>
            Você deseja mesmo <span className="delete">excluir</span> a
            embarcação <span className="name">{watercraft.name_boating}</span>?
            Essa ação não poderá ser desfeita.
          </h1>

          <div onClick={handleClose}>
            <CloseIcon />
          </div>
        </DialogTitle>

        <DialogContent className={classes.content}>
          <div className={classes.fields}>
            <h2>
              Capitania: <span> {watercraft.number_captaincy}</span>
            </h2>

            <h2>
              Tipo: <span>{watercraft.type}</span>
            </h2>

            <h2>
              Capacidade: <span>{watercraft.capacity}</span>
            </h2>

            <h2>
              Modelo: <span>{watercraft.model_brand}</span>
            </h2>

            <h2>
              Proprietário(s):{' '}
              <span>
                {watercraft.owners
                  ? watercraft.owners.split(';').join(', ')
                  : 'Nenhum proprietário associado no momento.'}
              </span>
            </h2>
          </div>

          <div className={classes.buttonsContainer}>
            <Button onClick={handleClose} className={classes.buttonNo}>
              Não, quero voltar às embarcações
            </Button>

            <Button onClick={handleDelete} className={classes.buttonYes}>
              Sim, quero excluir essa embarcação
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ModalDeleteWatercraft;
