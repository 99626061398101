import { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import BusinessIcon from '@material-ui/icons/Business';
import CloseIcon from '@material-ui/icons/Close';
import * as yup from 'yup';

import CustomAlert from '../CustomAlert';

import api from '../../services/api';

import COLORS from '../../utils/colors';

const useStyles = makeStyles({
  buttonCreateCampaign: {
    textTransform: 'none',
    background: COLORS.whitePure,
    color: COLORS.greenSecondary,
    border: `1px solid ${COLORS.greenSecondary}`,
    padding: '1rem',
    width: '50%',

    '&:hover': {
      color: COLORS.whitePure,
      background: COLORS.greenSecondary,
      transition: '0.4s',
    },
  },

  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& span': {
      fontSize: '1.25rem',
      fontWeight: 500,
      lineHeight: '1.6',
    },

    '& svg': {
      fontSize: '2rem',
      cursor: 'pointer',

      '&:hover': {
        color: COLORS.gray2,
        transition: '0.3s',
      },
    },
  },

  input: {
    margin: '0.5rem auto',
    paddingRight: '1rem',
  },

  actions: {
    margin: '1.5rem auto',
  },

  buttonRegister: {
    textTransform: 'none',
    paddingLeft: '2rem',
    paddingRight: '2rem',
  },
});

interface ErrorMessage {
  name?: string;
  url?: string;
  logotype_url?: string;
}

const ModalCreateCampaign: React.FC = () => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const [name, setName] = useState('');
  const [url, setUrl] = useState('');
  const [logotypeUrl, setLogotypeUrl] = useState('');

  const [errorMessage, setErrorMessage] = useState<ErrorMessage>();

  const handleClose = () => {
    setOpen(false);
    setName('');
    setUrl('');
    setLogotypeUrl('');
    setErrorMessage({});
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSubmit = async () => {
    setErrorMessage({});

    const campaign = {
      name,
      url,
      logotype_url: logotypeUrl,
    };

    const schema = yup.object().shape({
      name: yup.string().required('Este campo é obrigatório.'),
      url: yup
        .string()
        .required('Este campo é obrigatório.')
        .url('URL informada tem formato inválido'),
      logotype_url: yup
        .string()
        .required('Este campo é obrigatório.')
        .url('URL informada tem formato inválido'),
    });

    try {
      await schema.validate(campaign, { abortEarly: false });
      await api.post('/api/partner', campaign);

      handleClose();
      CustomAlert('success', 'Anunciante foi criado!');
    } catch (error) {
      console.log(error);
      if (error instanceof yup.ValidationError) {
        let errorAux = {};

        error.inner.forEach(e => {
          errorAux = {
            ...errorAux,
            [e.path as string]: e.message,
          };
        });

        setErrorMessage(errorAux);
        return;
      }

      CustomAlert('error', 'Erro inesperado. Por favor, tente novamente!');
    }
  };

  return (
    <>
      <Button
        disableElevation
        className={classes.buttonCreateCampaign}
        startIcon={<BusinessIcon />}
        onClick={handleOpen}
      >
        Criar anunciante
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle className={classes.titleContainer} disableTypography>
          <span>Criar anunciante</span>

          <CloseIcon onClick={handleClose} />
        </DialogTitle>

        <DialogContent>
          <TextField
            label="Nome"
            className={classes.input}
            fullWidth
            value={name}
            onChange={e => {
              setName(e.target.value);
            }}
            required
            error={Boolean(errorMessage?.name)}
            helperText={errorMessage?.name}
          />

          <TextField
            label="Url da Empresa"
            className={classes.input}
            fullWidth
            value={url}
            onChange={e => {
              setUrl(e.target.value);
            }}
            required
            error={Boolean(errorMessage?.url)}
            helperText={errorMessage?.url}
          />

          <TextField
            label="Url da Logotipo"
            className={classes.input}
            fullWidth
            value={logotypeUrl}
            onChange={e => {
              setLogotypeUrl(e.target.value);
            }}
            required
            error={Boolean(errorMessage?.logotype_url)}
            helperText={errorMessage?.logotype_url}
          />
        </DialogContent>

        <DialogActions className={classes.actions}>
          <Button
            color="primary"
            variant="contained"
            disableElevation
            className={classes.buttonRegister}
            onClick={handleSubmit}
          >
            Criar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ModalCreateCampaign;
