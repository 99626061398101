import { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import PeopleIcon from '@material-ui/icons/People';
import moment from 'moment';

import TileStatus from '../TileStatus';
import getActionsBy from './getActionsBy';

import { useAuth } from '../../contexts/auth';

import { TileNavigation as TileNavigationType } from '../../interfaces/TileNavigation';
import { Navigation } from '../../interfaces/Navigation';

import COLORS from '../../utils/colors';
import getNavigationStatus from '../../utils/getNavigationStatus';

const dialogWidth = '50.75rem';

const useStyles = makeStyles({
  tileNavigationContainer: {
    backgroundColor: COLORS.whitePure,
    padding: '0.75rem 1rem',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '0.5rem',
    boxShadow: `0px 1px 4px ${COLORS.blackPure}3f`,

    '& header': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',

      '& h1': {
        fontSize: '1.125rem',
        color: COLORS.gray,
        lineHeight: '1.625rem',
        fontWeight: 400,
      },

      '& .delay': {
        color: COLORS.orangeAlert,
      },

      '& .riskDelay': {
        color: COLORS.redAlert,
      },
    },

    '& .divider': {
      height: '1px',
      backgroundColor: COLORS.gray5,
    },

    '& footer': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',

      '& .main': {
        display: 'flex',
        alignItems: 'center',
        fontSize: '0.875rem',
        fontWeight: 700,
        maxWidth: '95%',

        '& p': {
          color: COLORS.gray,
        },

        '& span': {
          color: COLORS.greenSecondary,
        },

        '& svg': {
          color: COLORS.gray3,
          fontSize: '0.875rem',
        },

        '& .destiny': {
          marginLeft: '.5rem',
          fontWeight: 500,
          color: COLORS.gray2,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '50%',
        },
      },
    },
  },

  manning: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '0.375rem',
    color: COLORS.gray,
    fontSize: '1rem',
    lineHeight: '1.25rem',

    '& svg': {
      fontSize: '1rem',
    },
  },

  title: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '3.125rem 3.125rem 0 3.125rem',

    '& section': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      rowGap: '.5rem',

      '& h1': {
        color: COLORS.blackPure,
        fontWeight: 500,
        fontSize: '1.875rem',
        lineHeight: '2.625rem',
      },

      '& h2': {
        color: COLORS.gray2,
        fontWeight: 400,
        fontSize: '1.125rem',
        lineHeight: '1.5rem',
      },

      '& h3': {
        color: COLORS.gray2,
        fontWeight: 500,
        fontSize: '1.125rem',
        lineHeight: '1.5rem',
      },
    },

    '& div': {
      cursor: 'pointer',
      border: `1px solid ${COLORS.gray3}`,
      borderRadius: '0.375rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '2.25rem',
      height: '2.25rem',

      '& svg': {
        fontSize: '1.5rem',
        color: COLORS.gray,
      },

      '&:hover': {
        backgroundColor: `${COLORS.greenPrimary}0a`,
        transition: '0.3s',
      },
    },
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '3.125rem 3.125rem 2.25rem 3.125rem',

    '& main': {
      display: 'flex',
      justifyContent: 'space-between',

      '& aside': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',

        '& .observation': {
          display: 'flex',
          flexDirection: 'column',
          rowGap: '0.875rem',

          '& p': {
            fontSize: '0.875rem',
            color: COLORS.gray2,
            fontWeight: 700,
          },

          '& .MuiInputBase-root': {
            borderRadius: '0.5rem',
            border: `1px solid ${COLORS.gray3}`,
            padding: '.75rem',
            outline: 'none',
            fontSize: '0.875rem',
          },
        },

        '& .field': {
          display: 'flex',
          columnGap: '1rem',
          alignItems: 'center',

          '& p': {
            color: COLORS.gray3,
            fontWeight: 500,
            fontSize: '0.875rem',
          },

          '& span': {
            color: COLORS.gray2,
            fontWeight: 400,
            fontSize: '1.125rem',
          },
        },
      },
    },

    '& footer': {
      marginTop: '3rem',
      display: 'flex',
      columnGap: '1.5rem',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },

  exitAndArrival: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '1.375rem',

    '& h4': {
      color: COLORS.gray,
      fontSize: '1.125rem',
      fontWeight: 500,

      '& span': {
        color: COLORS.greenSecondary,
      },
    },
  },

  fields: {
    marginTop: '2.25rem',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '1rem',

    '& div': {
      display: 'grid',
      gridTemplateColumns: '1fr 2fr',
      columnGap: '0.625rem',
      alignItems: 'center',

      '& p': {
        color: COLORS.gray3,
        fontWeight: 500,
        fontSize: '0.875rem',
      },

      '& span': {
        color: COLORS.gray2,
        fontWeight: 400,
        fontSize: '1.125rem',
      },

      '& .marinaName': {
        color: COLORS.greenSecondary,
      },
    },
  },
});

interface Props {
  type: TileNavigationType;
  data: Navigation;
}

const TileNavigation: React.FC<Props> = ({ type, data }) => {
  const [open, setOpen] = useState(false);

  const { user } = useAuth();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { cancel: ActionCancelNormal, confirm: ActionConfirmNormal } =
    getActionsBy(type, data, 'normal');
  const { cancel: ActionCancelOnModal, confirm: ActionConfirmOnModal } =
    getActionsBy(type, data, 'onModal');

  const classes = useStyles();

  const [dayExit, hourExit] = moment(data.exit_forecast).calendar().split(', ');

  const [dayArrival, hourArrival] = moment(data.arrival_forecast)
    .calendar()
    .split(', ');

  const navigationStatus = getNavigationStatus(
    data.status,
    type === 'exit' ? data.exit_forecast : data.arrival_forecast,
  );

  return (
    <>
      <div onClick={handleOpen}>
        <TileStatus status={navigationStatus} type="tileNavigation" />

        <div className={classes.tileNavigationContainer}>
          <header>
            <h1 className={`${navigationStatus}`}>
              {data.watercraft?.name_boating ?? data.watercraft_name} |{' '}
              {data.watercraft?.number_captaincy ?? data.captaincy_number}
              <div />
            </h1>

            {user.role === 'marina' ? (
              ActionCancelNormal
            ) : (
              <div className={classes.manning}>
                <PeopleIcon />

                {data.tribulation}
              </div>
            )}
          </header>

          <div className="divider" />

          <footer>
            <div className="main">
              <p>
                {dayExit}, <span>{hourExit}</span>
              </p>

              <KeyboardArrowRightIcon />

              <p>
                {dayExit !== dayArrival && `${dayArrival},`}{' '}
                <span>{hourArrival}</span>
              </p>

              <p className="destiny">{data.destiny}</p>
            </div>

            {user.role === 'marina' && ActionConfirmNormal}
          </footer>
        </div>
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{ style: { width: dialogWidth } }}
        maxWidth={false}
      >
        <DialogTitle className={classes.title} disableTypography>
          <TileStatus status={navigationStatus} type="table" />
          <section>
            <h1>{data.watercraft?.name_boating ?? data.watercraft_name}</h1>

            <h2>
              {data.watercraft?.number_captaincy ?? data.captaincy_number}
            </h2>

            <h3>{data.watercraft?.type ?? ''}</h3>
          </section>

          <div onClick={handleClose}>
            <CloseIcon />
          </div>
        </DialogTitle>

        <DialogContent className={classes.content}>
          <main>
            <section>
              <div className={classes.exitAndArrival}>
                <h4>
                  Navegando{' '}
                  <span>{moment(data.exit_forecast).format('DD/MM')}</span> às{' '}
                  <span>{moment(data.exit_forecast).format('LT')}</span>
                </h4>

                <h4>
                  Retornando{' '}
                  <span>{moment(data.arrival_forecast).format('DD/MM')}</span>{' '}
                  às <span>{moment(data.arrival_forecast).format('LT')}</span>
                </h4>
              </div>

              <div className={classes.fields}>
                <div>
                  <p>Comandante</p>

                  <span>
                    {data.conductor?.name ?? 'Ainda não tem condutor'}
                  </span>
                </div>

                <div>
                  <p>Destino</p>

                  <span>{data.destiny}</span>
                </div>

                <div>
                  <p>Tripulantes</p>

                  <span>{data.tribulation}</span>
                </div>

                {user.role === 'marina' ? (
                  <div>
                    <p>Telefone</p>

                    <span>
                      {data.commander
                        ? data.conductor?.cellphone
                        : data.user.cellphone}
                    </span>
                  </div>
                ) : (
                  <div>
                    <p>Marina</p>

                    <span className="marinaName">
                      {data.marina?.name_marina}
                    </span>
                  </div>
                )}
              </div>
            </section>

            <aside>
              <div className="observation">
                <p>Observações</p>

                <TextField
                  variant="outlined"
                  disabled
                  value={data.observation}
                  multiline
                  minRows={6}
                />
              </div>

              {user.role === 'marine' && (
                <div className="field">
                  <p>Telefone</p>

                  <span>
                    {data.commander
                      ? data.conductor?.cellphone
                      : data.user.cellphone}
                  </span>
                </div>
              )}

              <div className="field">
                <p>Telefone de emergência</p>

                <span>
                  {data.commander
                    ? data.conductor?.emergency_phone
                    : data.user.emergency_phone}
                </span>
              </div>
            </aside>
          </main>

          {user.role === 'marina' && (
            <footer>
              {ActionConfirmOnModal}
              {ActionCancelOnModal}
            </footer>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TileNavigation;
