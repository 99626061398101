/* eslint-disable react/jsx-props-no-spreading */
import { Route, Redirect } from 'react-router-dom';

import { useAuth } from '../contexts/auth';

interface Props {
  component: React.FC;
  path: string;
}

const PrivateRoute: React.FC<Props> = ({ component: Component, ...props }) => {
  const { logged } = useAuth();

  return (
    <Route
      {...props}
      render={() => (logged ? <Component {...props} /> : <Redirect to="/" />)}
    />
  );
};

export default PrivateRoute;
