/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-no-constructed-context-values */
import { createContext, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import CustomAlert from '../components/CustomAlert';

import api from '../services/api';

interface User {
  id: number;
  name: string;
  email: string;
  cellphone: string;
  emergency_phone: string;
  address?: string;
  state?: string;
  district?: string;
  city?: string;
  number?: string;
  cep?: string;
  boating_license?: string;
  role: 'marina' | 'marine' | 'admin';
  newsletter?: string;
  token_activation?: string;
  marina?: {
    id: number;
    name_marina: string;
  };
}

interface AuthContextProps {
  logged: boolean;
  user: User;
  updateUser: (newUser: User) => void;
  login: (email: string, password: string) => void;
  logout: () => void;
}

const AuthContext = createContext({});

export const AuthProvider: React.FC = ({ children }) => {
  const [logged, setLogged] = useState<boolean>(
    !!JSON.parse(localStorage.getItem('@dattaboat:logged') || 'null'),
  );
  const [user, setUser] = useState<User | null>(
    JSON.parse(localStorage.getItem('@dattaboat:user') || 'null'),
  );
  const history = useHistory();

  const login = async (email: string, password: string) => {
    try {
      const response = await api.post('/login', {
        email,
        password,
      });

      const { token, user: currentUser } = response.data;

      const { role, name } = currentUser;

      if (role === 'client') {
        CustomAlert(
          'error',
          `${name}, você não tem acesso a essa versão. Por favor, baixe o aplicativo!`,
        );
        return;
      }

      setLogged(true);
      setUser(currentUser);

      localStorage.setItem('@dattaboat:logged', 'true');
      localStorage.setItem('@dattaboat:user', JSON.stringify(currentUser));
      localStorage.setItem('@dattaboat:token', token);
      history.push('/dashboard');
    } catch (error: any) {
      if (
        error.response &&
        error.response.data.message ===
          'The email address or password is incorrect. Please retry...' &&
        error.response.status === 401
      ) {
        CustomAlert('error', 'E-mail e/ou Senha estão incorretos!');
        return;
      }

      CustomAlert('error', 'Erro inesperado. Por favor, tente novamente!');
      console.log(error);
    }
  };

  const logout = () => {
    localStorage.clear();
    setLogged(false);
    setUser(null);
    history.push('/');
  };

  const updateUser = (newUser: User) => {
    setUser(newUser);
    localStorage.setItem('@dattaboat:user', JSON.stringify(newUser));
  };

  return (
    <AuthContext.Provider value={{ logged, user, updateUser, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextProps {
  const context = useContext(AuthContext) as AuthContextProps;

  return context;
}
