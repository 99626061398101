import { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';

import CustomAlert from '../CustomAlert';

import { Operator } from '../../interfaces/Operator';

import api from '../../services/api';

import COLORS from '../../utils/colors';

const useStyles = makeStyles({
  buttonCreateOperator: {
    textTransform: 'none',

    '&:hover': {
      background: COLORS.greenPrimary,
      opacity: '0.9',
      transition: '0.3s',
    },
  },

  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& span': {
      fontSize: '1.25rem',
      fontWeight: 500,
      lineHeight: '1.6',
    },

    '& svg': {
      fontSize: '2rem',
      cursor: 'pointer',

      '&:hover': {
        color: COLORS.gray2,
        transition: '0.3s',
      },
    },
  },

  input: {
    margin: '0.5rem auto',
    paddingRight: '1rem',
  },

  actions: {
    margin: '1.5rem auto',
  },

  buttonRegister: {
    textTransform: 'none',
    paddingLeft: '2rem',
    paddingRight: '2rem',
  },
});

interface Props {
  setOperators: React.Dispatch<React.SetStateAction<Operator[]>>;
}

interface Params {
  id: string;
}

interface ErrorMessage {
  name?: string;
  email?: string;
  cpf?: string;
  cellphone?: string;
  password?: string;
  password_confirmation?: string;
}

const ModalCreateOperator: React.FC<Props> = ({ setOperators }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const { id } = useParams<Params>();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [cpf, setCpf] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [visibilityPassword, setVisibilityPassword] = useState(false);
  const [visibilityConfirmPassword, setVisibilityConfirmPassword] =
    useState(false);

  const [errorMessage, setErrorMessage] = useState<ErrorMessage>({});

  const handleClose = () => {
    setOpen(false);
    setName('');
    setEmail('');
    setCpf('');
    setPhone('');
    setPassword('');
    setConfirmPassword('');
    setVisibilityPassword(false);
    setVisibilityConfirmPassword(false);
    setErrorMessage({});
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSubmit = async () => {
    setErrorMessage({});

    const operator = {
      name,
      email,
      cpf,
      password,
      password_confirmation: confirmPassword,
      cellphone: phone,
      marina_id: id,
    };

    const schema = yup.object().shape({
      name: yup.string().required('Este campo é obrigatório.'),
      email: yup
        .string()
        .email('E-mail informado tem formato inválido')
        .required('Este campo é obrigatório.'),
      cpf: yup
        .string()
        .required('Este campo é obrigatório.')
        // Credits: https://gist.github.com/igorcosta/3a4caa954a99035903ab
        .matches(/(^\d{3}\.\d{3}\.\d{3}-\d{2}$)|(^\d{11}$)/, {
          message: 'CPF informado tem formato inválido',
        }),
      password: yup
        .string()
        .min(4, 'A senha deve ter pelo menos 4 caracteres')
        .required('Este campo é obrigatório.'),
      password_confirmation: yup
        .string()
        .required('Este campo é obrigatório.')
        .test(
          'passwords-match',
          'Senhas não coincidem.',
          value => password === value,
        ),
      cellphone: yup.string().required('Este campo é obrigatório.'),
    });

    try {
      await schema.validate(operator, { abortEarly: false });
      const response = await api.post(`/api/marina/user`, operator);

      const newOperator = response.data;

      setOperators((prevOperators: Operator[]) => [
        ...prevOperators,
        newOperator,
      ]);

      handleClose();
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        let errorAux = {};

        error.inner.forEach(e => {
          errorAux = {
            ...errorAux,
            [e.path as string]: e.message,
          };
        });

        setErrorMessage(errorAux);
        return;
      }

      CustomAlert('error', 'Erro inesperado. Por favor, tente novamente!');
    }
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        disableElevation
        className={classes.buttonCreateOperator}
        onClick={handleOpen}
      >
        Criar operador
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle className={classes.titleContainer} disableTypography>
          <span>Criar operador</span>

          <CloseIcon onClick={handleClose} />
        </DialogTitle>

        <DialogContent>
          <TextField
            label="Nome"
            className={classes.input}
            fullWidth
            value={name}
            onChange={e => {
              setName(e.target.value);
            }}
            required
            error={Boolean(errorMessage?.name)}
            helperText={errorMessage?.name}
          />

          <TextField
            label="E-mail"
            className={classes.input}
            fullWidth
            value={email}
            onChange={e => {
              setEmail(e.target.value);
            }}
            required
            error={Boolean(errorMessage?.email)}
            helperText={errorMessage?.email}
          />

          <TextField
            label="CPF"
            className={classes.input}
            fullWidth
            value={cpf}
            onChange={e => {
              setCpf(e.target.value);
            }}
            required
            error={Boolean(errorMessage?.cpf)}
            helperText={errorMessage?.cpf}
          />

          <TextField
            label="Telefone"
            className={classes.input}
            fullWidth
            value={phone}
            onChange={e => {
              setPhone(e.target.value);
            }}
            required
            error={Boolean(errorMessage?.cellphone)}
            helperText={errorMessage?.cellphone}
          />

          <TextField
            label="Senha"
            className={classes.input}
            fullWidth
            value={password}
            onChange={e => {
              setPassword(e.target.value);
            }}
            type={visibilityPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={() => {
                    setVisibilityPassword(!visibilityPassword);
                  }}
                >
                  {visibilityPassword ? (
                    <VisibilityIcon />
                  ) : (
                    <VisibilityOffIcon />
                  )}
                </IconButton>
              ),
            }}
            required
            error={Boolean(errorMessage?.password)}
            helperText={errorMessage?.password}
          />

          <TextField
            label="Confirmar senha"
            className={classes.input}
            fullWidth
            value={confirmPassword}
            onChange={e => {
              setConfirmPassword(e.target.value);
            }}
            type={visibilityConfirmPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={() => {
                    setVisibilityConfirmPassword(!visibilityConfirmPassword);
                  }}
                >
                  {visibilityConfirmPassword ? (
                    <VisibilityIcon />
                  ) : (
                    <VisibilityOffIcon />
                  )}
                </IconButton>
              ),
            }}
            required
            error={Boolean(errorMessage?.password_confirmation)}
            helperText={errorMessage?.password_confirmation}
          />
        </DialogContent>

        <DialogActions className={classes.actions}>
          <Button
            color="primary"
            variant="contained"
            disableElevation
            className={classes.buttonRegister}
            onClick={handleSubmit}
          >
            Criar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ModalCreateOperator;
