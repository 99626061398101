import { useEffect, useState } from 'react';
import { makeStyles, Tabs, Tab } from '@material-ui/core';

import PlannedDepartures from './PlannedDepartures';

import { useNavigations } from '../../../contexts/navigations';

import COLORS from '../../../utils/colors';

import api from '../../../services/api';
import SailingAndArrivals from './SailingAndArrivals';

const useStyles = makeStyles(props => ({
  container: {
    width: '100%',
    height: '106.25%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '-3rem',
    padding: '0 4.375rem 2.25rem 4.375rem',
  },

  tabs: {
    padding: 0,
    position: 'absolute',
    zIndex: props.zIndex.appBar + 1,

    '& .MuiTabs-flexContainer': {
      columnGap: '2rem',
    },

    '& .MuiTab-root': {
      maxWidth: 'max-content',
    },

    '& .MuiButtonBase-root': {
      textTransform: 'none',
      padding: 0,
      fontSize: '1.5rem',
      fontWeight: 700,
      color: COLORS.gray3,
      width: 'max-content',
    },

    '& .Mui-selected': {
      color: COLORS.greenPrimary,
    },
  },
}));

export default function MarineDashboard() {
  const [valueTab, setValueTab] = useState(0);
  const { setExits, setNavigates, setArrivals } = useNavigations();

  const classes = useStyles();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (event: React.ChangeEvent<any>, newValue: number) => {
    setValueTab(newValue);
  };

  useEffect(() => {
    async function loadData() {
      try {
        const response = await api.get(`marine/departure`);

        setExits(response.data.exits);
        setNavigates(response.data.navigates);
        setArrivals(response.data.arrivals);
      } catch (error) {
        console.log(error);
      }
    }
    loadData();

    const interval = setInterval(() => {
      loadData();
    }, 60 * 1000);

    return () => clearInterval(interval);
  }, [setExits, setArrivals, setNavigates]);

  return (
    <div className={classes.container}>
      <Tabs
        value={valueTab}
        onChange={handleChange}
        className={classes.tabs}
        indicatorColor="primary"
      >
        <Tab
          label="Navegando e Chegadas"
          id="tab-0"
          aria-controls="tabpanel-0"
        />

        <Tab label="Saídas Previstas" id="tab-1" aria-controls="tabpanel-1" />
      </Tabs>

      <SailingAndArrivals
        id="tabpanel-0"
        ariaLabelledby="tab-0"
        role="tabpanel"
        hidden={valueTab !== 0}
      />

      <PlannedDepartures
        id="tabpanel-1"
        ariaLabelledby="tab-1"
        role="tabpanel"
        hidden={valueTab !== 1}
      />
    </div>
  );
}
