import React from 'react';
import { Switch, Route } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';

import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';
import Watercrafts from '../pages/Watercrafts';
import Profile from '../pages/Profile';
import Report from '../pages/Report';
import Historic from '../pages/Historic';
import Heatmap from '../pages/Heatmap';
import ResetPassword from '../pages/ResetPassword';
import Download from '../pages/Download';
import Privacy from '../pages/Privacy';
import NotFound from '../pages/NotFound';
import AdminMarina from '../pages/AdminMarina';
import TideTable from '../pages/TideTable';
import AdsInNavigations from '../pages/AdsInNavigations';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" component={Login} exact />

      <PrivateRoute path="/dashboard" component={Dashboard} />

      <PrivateRoute path="/watercrafts" component={Watercrafts} />
      <PrivateRoute path="/profile" component={Profile} />
      <PrivateRoute path="/report" component={Report} />
      <PrivateRoute path="/historic" component={Historic} />
      <PrivateRoute path="/heatmap" component={Heatmap} />
      <PrivateRoute path="/marina/:id" component={AdminMarina} />
      <PrivateRoute path="/tide-table" component={TideTable} />
      <PrivateRoute path="/ads-in-navigations" component={AdsInNavigations} />

      <Route path="/reset-password/:token" component={ResetPassword} />
      <Route path="/download" component={Download} />
      <Route path="/privacy" component={Privacy} />
      <Route component={NotFound} />
    </Switch>
  );
}
