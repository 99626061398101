import platform from 'platform';

const platforms = {
  Android: 'https://dattasea.page.link/download',
  iOS: 'https://apps.apple.com/app/id1558841743?mt=8',
};

type Platform = 'Android' | 'iOS';

export default function Download() {
  const currentPlatform = platform.os?.family as Platform;

  document.location =
    platforms[currentPlatform] || 'https://www.dattasea.com.br/';
  return null;
}
