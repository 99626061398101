/* eslint-disable react/jsx-props-no-spreading */
import React, { ChangeEvent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';

import COLORS from '../../utils/colors';

const useStyles = makeStyles({
  pagination: {
    '& .MuiPaginationItem-page': {
      color: COLORS.gray3,
      fontWeight: 500,
      fontSize: '1.125rem',
    },

    '& .MuiPaginationItem-page:hover': {
      backgroundColor: `${COLORS.greenSecondary}0f`,
      transition: '0.3s',
    },

    '& .MuiPaginationItem-page.Mui-selected': {
      color: COLORS.whiteMatte,
      fontWeight: 500,
      fontSize: '1.125rem',
      padding: 0,
      backgroundColor: COLORS.greenSecondary,

      '&:hover': {
        transition: '0.3s',
        opacity: '0.9',
      },
    },

    '& .MuiPaginationItem-ellipsis': {
      color: COLORS.gray3,
      fontSize: '1.25rem',
    },
  },

  buttonPaginationActions: {
    marginTop: '0.25rem',
    fontSize: '1.125rem',
    color: COLORS.gray3,
    cursor: 'pointer',

    '&:hover': {
      color: COLORS.gray2,
      transition: '0.3s',
    },
  },

  buttonPaginationActionsDisabled: {
    marginTop: '0.25rem',
    fontSize: '1.125rem',
    color: COLORS.gray4,

    '&:hover': {
      backgroundColor: COLORS.whitePure,

      '& svg': {
        color: COLORS.gray2,
        transition: '0.3s',
      },
    },
  },
});

interface Props {
  page: number;
  handleChangePage(event: ChangeEvent<unknown>, newPage: number): void;
  total: number;
  rowsPerPage: number;
}

const CustomPagination: React.FC<Props> = ({
  page,
  handleChangePage,
  total,
  rowsPerPage,
}) => {
  const classes = useStyles();

  return (
    <Pagination
      page={page}
      onChange={handleChangePage}
      count={Math.ceil(total / rowsPerPage)}
      shape="rounded"
      siblingCount={1}
      size="small"
      boundaryCount={1}
      className={classes.pagination}
      renderItem={item => {
        const handleClick = !item.disabled
          ? item.onClick
          : () => {
              // do nothing.
            };

        const className = item.disabled
          ? classes.buttonPaginationActionsDisabled
          : classes.buttonPaginationActions;

        if (item.type === 'previous') {
          return (
            <ArrowBackIosIcon
              onClick={handleClick}
              className={className}
              style={{ marginRight: '0.25rem' }}
            />
          );
        }

        if (item.type === 'next') {
          return (
            <ArrowForwardIosIcon
              onClick={handleClick}
              className={className}
              style={{ marginLeft: '0.25rem' }}
            />
          );
        }

        return <PaginationItem {...item} />;
      }}
    />
  );
};

export default CustomPagination;
