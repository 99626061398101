import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import DescriptionIcon from '@material-ui/icons/Description';
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HomeIcon from '@material-ui/icons/Home';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PersonIcon from '@material-ui/icons/Person';
import PoolIcon from '@material-ui/icons/Pool';
import ReplayIcon from '@material-ui/icons/Replay';
import SearchIcon from '@material-ui/icons/Search';
import TuneIcon from '@material-ui/icons/Tune';
import { useHistory } from 'react-router-dom';
import { DebounceInput } from 'react-debounce-input';
import clsx from 'clsx';

import { useDate } from '../../hooks/useDate';
import { useAuth } from '../../contexts/auth';
import { useSearch } from '../../contexts/search';

import COLORS from '../../utils/colors';

import { ReactComponent as LogoAppBar } from '../../images/logo-app-bar.svg';

const drawerWidth = '6.375rem';
const appBarHeight = '11.875rem';

const drawerRoutes = {
  marina: [
    { name: 'dashboard', title: 'Dashboard', icon: <HomeIcon /> },
    { name: 'profile', title: 'Perfil', icon: <PersonIcon /> },
    { name: 'watercrafts', title: 'Embarcações', icon: <DirectionsBoatIcon /> },
    { name: 'historic', title: 'Histórico', icon: <AccessTimeIcon /> },
    { name: 'report', title: 'Relatório', icon: <DescriptionIcon /> },
    { name: 'tide-table', title: 'Tábua de Marés', icon: <PoolIcon /> },
  ],
  marine: [
    { name: 'dashboard', title: 'Dashboard', icon: <HomeIcon /> },
    { name: 'profile', title: 'Perfil', icon: <PersonIcon /> },
    { name: 'report', title: 'Relatório', icon: <DescriptionIcon /> },
    { name: 'tide-table', title: 'Tábua de Marés', icon: <PoolIcon /> },
  ],
  admin: [
    { name: 'dashboard', title: 'Dashboard', icon: <HomeIcon /> },
    { name: 'profile', title: 'Perfil', icon: <PersonIcon /> },
  ],
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },

  appBar: {
    backgroundColor: COLORS.whiteMatte,
    padding: `3.75rem 4.375rem 0 calc(${drawerWidth} + 4.375rem)`,
    height: appBarHeight,
  },

  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    height: '6.75rem',
    padding: 0,
  },

  toolBarContent: {
    color: COLORS.gray,
    fontSize: '2.25rem',
    fontWeight: 600,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },

  toolBarContentHeader: {
    display: 'flex',
    columnGap: '2.75rem',
    lineHeight: '3.125rem',

    '& p span': {
      color: COLORS.blueAlert,
    },

    '& div': {
      display: 'flex',
      columnGap: '1.25rem',

      '& svg': {
        fontSize: '2.25rem',
        height: '3.125rem',
      },
    },
  },

  searchWatercraft: {
    marginTop: '2rem',
    borderRadius: '0.625rem',
    color: COLORS.gray3,
    width: '21.75rem',

    '& .MuiInputBase-root': {
      height: '2.875rem',
      borderRadius: '0.625rem',
      backgroundColor: COLORS.gray6,
      color: COLORS.gray3,
      fontSize: '1.25rem',
      textAlign: 'center',
    },

    '& input': {
      paddingLeft: '1rem',
      color: COLORS.gray,
      fontSize: '1.25rem',
    },

    '& svg': {
      color: COLORS.gray3,
    },

    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },

    '& ::-webkit-input-placeholder, & ::placeholder': {
      color: COLORS.gray,
      fontSize: '1.25rem',
    },
  },

  drawer: {
    width: drawerWidth,
    border: 'none',
    padding: '1.5rem',
    backgroundColor: COLORS.greenSecondary,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '& div': {
      display: 'flex',
      flexDirection: 'column',
      rowGap: '2.625rem',
    },
  },

  itemDrawer: {
    width: '3.375rem',
    height: '3.375rem',
    padding: '.75rem',
    borderRadius: '0.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',

    '& svg': {
      color: COLORS.whiteMatte,
      fontSize: '1.875rem',
    },

    '&:hover': {
      backgroundColor: COLORS.whiteMatte,
      transition: '.3s',

      '& svg': {
        color: COLORS.greenPrimary,
        transition: '.3s',
      },
    },
  },

  itemDrawerSelected: {
    width: '3.375rem',
    height: '3.375rem',
    borderRadius: '0.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: COLORS.whiteMatte,

    '& svg': {
      color: COLORS.greenPrimary,
      fontSize: '1.875rem',
    },
  },

  content: {
    flexGrow: 1,
    overflow: 'auto',
    marginLeft: drawerWidth,
    backgroundColor: COLORS.whiteMatte,
    width: `calc(100vw - ${drawerWidth})`,
    height: `calc(100vh - ${appBarHeight})`,
  },
});

const CustomToolTip = withStyles({
  tooltip: {
    backgroundColor: COLORS.gray2,
    color: COLORS.whitePure,
    fontWeight: 500,
    borderRadius: '0.5rem',
    fontSize: '.875rem',
  },
})(Tooltip);

interface Props {
  routeSelected: string;
  type: 'marina' | 'marine' | 'admin';
}

const Layout: React.FC<Props> = ({ children, type, routeSelected }) => {
  const classes = useStyles();
  const { logout, user } = useAuth();
  const { date } = useDate();
  const { search, updateSearch } = useSearch();
  const history = useHistory();

  return (
    <div className={classes.root}>
      <AppBar elevation={0} position="static" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <LogoAppBar />

          <div className={classes.toolBarContent}>
            <div className={classes.toolBarContentHeader}>
              <p>
                {date.format('LL')}, <span>{date.format('LT')}</span>
              </p>

              <div>
                <ReplayIcon />

                {user.role === 'marina' && <NotificationsIcon />}
              </div>
            </div>

            {user.role === 'marina' && (
              <DebounceInput
                minLength={0}
                debounceTimeout={300}
                element={TextField}
                variant="outlined"
                className={classes.searchWatercraft}
                placeholder="Pesquisar embarcação"
                InputProps={{
                  startAdornment: <SearchIcon />,
                  endAdornment: <TuneIcon />,
                }}
                value={search}
                onChange={e => {
                  updateSearch(e.target.value);
                }}
              />
            )}
          </div>
        </Toolbar>
      </AppBar>

      <Drawer variant="permanent" classes={{ paper: classes.drawer }}>
        <Toolbar className={classes.toolbar} />

        <div>
          {drawerRoutes[type].map(drawerRoute => (
            <CustomToolTip
              key={drawerRoute.name}
              title={drawerRoute.title}
              placement="right"
              onClick={() => {
                history.push(`/${drawerRoute.name}`);
              }}
            >
              <div
                className={clsx({
                  [classes.itemDrawerSelected]:
                    routeSelected === drawerRoute.name,
                  [classes.itemDrawer]: routeSelected !== drawerRoute.name,
                })}
              >
                {drawerRoute.icon}
              </div>
            </CustomToolTip>
          ))}
        </div>

        <div style={{ flexGrow: 1 }} />

        <CustomToolTip title="Sair" placement="right" onClick={logout}>
          <div className={classes.itemDrawer}>
            <ExitToAppIcon />
          </div>
        </CustomToolTip>
      </Drawer>

      <main className={classes.content}>{children}</main>
    </div>
  );
};

export default Layout;
