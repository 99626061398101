import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

import CustomAlert from '../CustomAlert';

import { Marina } from '../../interfaces/Marina';

import api from '../../services/api';

import COLORS from '../../utils/colors';

const useStyles = makeStyles({
  title: {
    display: 'flex',
    justifyContent: 'flex-end',

    '& .MuiSvgIcon-root': {
      fontSize: '2.25rem',
      color: COLORS.gray2,
      cursor: 'pointer',
    },
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 3rem',
  },

  description: {
    backgroundColor: COLORS.whitePure,
    fontSize: '1.125rem',
    color: COLORS.gray,
    maxWidth: '28rem',
    textAlign: 'center',

    '& .red': {
      color: COLORS.redAlert,
    },

    '& .bold': {
      fontWeight: 'bold',
    },
  },

  iconContainer: {
    width: '3.875rem',
    height: '3.875rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: COLORS.redAlert,
    borderRadius: '50%',
    margin: '1.75rem 0',

    '& .MuiSvgIcon-root': {
      fontSize: '2.375rem',
      color: COLORS.whitePure,
    },
  },

  buttonNo: {
    backgroundColor: COLORS.gray,
    width: '13.5rem',
    height: '3.5rem',
    borderRadius: '0.5rem',
    fontSize: '0.875rem',
    color: COLORS.whitePure,
    textTransform: 'none',
    fontWeight: 'bold',

    '&:hover': {
      backgroundColor: COLORS.blackPure,
      transition: '0.3s',
    },
  },

  buttonYes: {
    backgroundColor: 'none',
    width: '13.5rem',
    height: '3rem',
    borderRadius: '0.5rem',
    fontSize: '0.875rem',
    color: COLORS.redAlert,
    textTransform: 'none',
    border: `1px ${COLORS.redAlert} solid`,
    margin: '0.75rem 0 2.25rem 0',
    fontWeight: 'bold',

    '&:hover': {
      backgroundColor: COLORS.redAlert,
      color: COLORS.whitePure,
      transition: '0.3s',
    },
  },
});

interface Props {
  id: number;
  name: string;
  setMarinas: React.Dispatch<React.SetStateAction<Marina[]>>;
  setTotalTableMarinas: React.Dispatch<React.SetStateAction<number>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  rowsPerPage: number;
  emptyRows: number;
}

const ModalDeleteMarina: React.FC<Props> = ({
  id,
  name,
  setMarinas,
  setTotalTableMarinas,
  rowsPerPage,
  emptyRows,
  setPage,
}) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const handleOpen = (e: React.MouseEvent) => {
    e.stopPropagation();

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    try {
      await api.delete(`/api/marina/${id}`);

      setMarinas((prevMarinas: Marina[]) => {
        const updatedMarinas = prevMarinas.filter(
          watercraft => watercraft.id !== id,
        );
        return updatedMarinas;
      });

      setTotalTableMarinas(prevTotalTableMarinas => prevTotalTableMarinas - 1);

      if (rowsPerPage === emptyRows + 1) {
        setPage(prevPage => prevPage - 1);
      }

      CustomAlert('success', `A Marina '${name}' foi excluída!`);

      handleClose();
    } catch (error) {
      CustomAlert(
        'error',
        `Erro ao tentar excluir a marina ${name}. Tente novamente mais tarde!`,
      );
    }
  };

  return (
    <>
      <TableCell align="center" style={{ width: '7%' }}>
        <DeleteOutlineIcon
          style={{ color: COLORS.redAlert }}
          onClick={handleOpen}
        />
      </TableCell>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle className={classes.title} disableTypography>
          <CloseIcon onClick={handleClose} />
        </DialogTitle>

        <DialogContent className={classes.content}>
          <p className={classes.description}>
            Você tem certeza que quer <span className="red bold">excluir</span>{' '}
            a marina <span className="bold">{name}</span>?
          </p>

          <div className={classes.iconContainer}>
            <DeleteOutlinedIcon />
          </div>

          <Button className={classes.buttonNo} onClick={handleClose}>
            Não, eu não quero excluir
          </Button>

          <Button className={classes.buttonYes} onClick={handleDelete}>
            Sim, exclua a marina
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ModalDeleteMarina;
