import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import * as yup from 'yup';

import CustomAlert from '../CustomAlert';

import { Watercraft } from '../../interfaces/Watercraft';

import api from '../../services/api';

import COLORS from '../../utils/colors';

const dialogWidth = '50.75rem';

const useStyles = makeStyles({
  buttonCreateWatercraft: {
    textTransform: 'none',
    padding: 0,
    height: '2.25rem',
    width: '16rem',
    background: COLORS.greenSecondary,
    color: COLORS.whitePure,
    fontSize: '1.125rem',
    fontWeight: 400,

    '& svg': {
      fontSize: '1.25rem !important',
    },

    '&:hover': {
      background: COLORS.greenPrimary,
      opacity: '0.9',
      transition: '0.3s',
    },
  },

  title: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '3.125rem 3.125rem 0 5rem',

    '& h1': {
      color: COLORS.blackPure,
      fontWeight: 500,
      fontSize: '1.875rem',
      lineHeight: '2.625rem',

      '& .delete': {
        fontWeight: 700,
        color: COLORS.redAlert,
      },

      '& .name': {
        fontWeight: 700,
        color: COLORS.greenSecondary,
      },
    },

    '& div': {
      cursor: 'pointer',
      border: `1px solid ${COLORS.gray3}`,
      borderRadius: '0.375rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '2.25rem',
      height: '2.25rem',

      '& svg': {
        fontSize: '1.5rem',
        color: COLORS.gray,
      },

      '&:hover': {
        backgroundColor: `${COLORS.greenPrimary}0a`,
        transition: '0.3s',
      },
    },
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '3.125rem 5rem 3.875rem 5rem',
  },

  fieldsContainer: {
    display: 'flex',
    justifyContent: 'center',
    columnGap: '3.125rem',

    '& section': {
      display: 'flex',
      flexDirection: 'column',
      rowGap: '1.25rem',
    },
  },

  field: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '0.625rem',

    '& span': {
      fontSize: '1.125rem',
      fontWeight: 500,
      color: COLORS.gray,
    },

    '& .MuiInputBase-root': {
      height: '3rem',
      width: '18.75rem',
      borderRadius: '0.5rem',
      border: `1px solid ${COLORS.greenPrimary}33`,
      padding: '0 0 0 1rem',
      outline: 'none',
      backgroundColor: `${COLORS.greenPrimary}0a`,
      fontSize: '0.875rem',
    },
  },

  mainButton: {
    height: '2.5rem',
    width: '7.625rem',
    padding: 0,
    textTransform: 'none',
    fontWeight: 500,
    fontSize: '1.125rem',
    lineHeight: '1.625rem',
    borderRadius: '0.25rem',
    marginTop: '4.625rem',
    backgroundColor: COLORS.greenAlert,
    color: COLORS.whiteMatte,
    alignSelf: 'center',

    '&:hover': {
      backgroundColor: COLORS.greenSecondary,
      color: COLORS.whitePure,
      transition: '0.3s',
    },
  },
});

const types = ['', 'Barco', 'Jet', 'Barco + Jet'];

interface Props {
  watercrafts: Watercraft[];
  setWatercrafts: (value: Watercraft[]) => void;
  typeWatercrafts: string;
}

interface ErrorMessage {
  searchForCaptaincy?: string;
  name_boating?: string;
  type?: string;
  capacity?: string;
  number_captaincy?: string;
  model_brand?: string;
}

const ModalCreateWatercraft: React.FC<Props> = ({
  watercrafts,
  setWatercrafts,
  typeWatercrafts,
}) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const [watercraft, setWatercraft] = useState({
    name_boating: '',
    type: '',
    capacity: '',
    number_captaincy: '',
    model_brand: '',
  });

  const [searchForCaptaincy, setSearchForCaptaincy] = useState('');
  const [isSearchingCaptaincy, setIsSearchingCaptaincy] = useState(true);

  const [watercraftIdFound, setWatercraftIdFound] = useState<number | null>(
    null,
  );

  const [errorMessage, setErrorMessage] = useState<ErrorMessage>({});

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      setWatercraft({
        name_boating: '',
        type: '',
        capacity: '',
        number_captaincy: '',
        model_brand: '',
      });
      setSearchForCaptaincy('');
      setIsSearchingCaptaincy(true);
      setErrorMessage({});
      setWatercraftIdFound(null);
    }, 300);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setWatercraft({
      ...watercraft,
      [e.target.name]: e.target.value,
    });
  };

  const handleTransition = () => {
    setOpen(false);
    setTimeout(() => {
      setIsSearchingCaptaincy(false);
      setOpen(true);
    }, 300);
  };

  const handleSubmit = async () => {
    setErrorMessage({});

    const schema = yup.object().shape({
      name_boating: yup.string().required('Este campo é obrigatório.'),
      type: yup.string().required('Este campo é obrigatório.'),
      capacity: yup
        .number()
        .typeError('Este campo é do tipo numérico.')
        .required('Este campo é obrigatório.'),
      number_captaincy: yup.string().required('Este campo é obrigatório.'),
      model_brand: yup
        .string()
        .min(3, 'Este campo tem de ter pelo menos 3 caracteres.')
        .required('Este campo é obrigatório.'),
    });

    try {
      await schema.validate(watercraft, { abortEarly: false });

      const response = watercraftIdFound
        ? await api.post('/api/watercraft-to-marina', {
            watercraft_id: watercraftIdFound,
          })
        : await api.post('/watercraft', watercraft);

      const newWatercraft = watercraftIdFound
        ? {
            id: watercraftIdFound,
            ...watercraft,
          }
        : response.data;

      if (typeWatercrafts === 'non-associates') {
        setWatercrafts([...watercrafts, newWatercraft]);
      }

      if (typeWatercrafts === 'associates' && watercraftIdFound) {
        setWatercrafts([...watercrafts, newWatercraft]);
      }

      handleClose();
    } catch (error) {
      console.log(error);

      if (error instanceof yup.ValidationError) {
        let errorAux = {};

        error.inner.forEach(e => {
          errorAux = {
            ...errorAux,
            [e.path as string]: e.message,
          };
        });

        setErrorMessage(errorAux);
        return;
      }

      CustomAlert('error', 'Erro inesperado. Por favor, tente novamente!');
    }
  };

  const loadDataForCaptaincy = async () => {
    setErrorMessage({});

    if (!searchForCaptaincy) {
      setErrorMessage({
        ...errorMessage,
        searchForCaptaincy: 'Este campo é obrigatório.',
      });

      return;
    }

    try {
      const response = await api.get(
        `/api/marina/watercraft/void/${searchForCaptaincy}`,
      );

      if (response.data) {
        const responseWatercraft = response.data.watercraft;
        setWatercraftIdFound(responseWatercraft.id);

        delete responseWatercraft.id;
        setWatercraft(responseWatercraft);
        handleTransition();

        return;
      }

      CustomAlert(
        'error',
        'Essa embarcação já está vinculada a outra marina. Por favor, desvincule primeiro no app do cliente para poder cadastrar!',
      );

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.log(error);
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.message === 'unregistered vessel'
      ) {
        setWatercraft({
          ...watercraft,
          number_captaincy: searchForCaptaincy,
        });

        setWatercraftIdFound(null);
        handleTransition();
        return;
      }

      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.message === 'watercraft already exists'
      ) {
        CustomAlert(
          'error',
          'Essa embarcação já está vinculada a sua Marina. Não precisa cadastrar!',
        );
      }
    }
  };

  return (
    <>
      <Button
        className={classes.buttonCreateWatercraft}
        onClick={handleOpen}
        startIcon={<AddIcon />}
      >
        Adicionar Embarcação
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{ style: { width: dialogWidth } }}
        maxWidth={false}
      >
        <DialogTitle className={classes.title} disableTypography>
          <p />

          <h1>Nova embarcação</h1>

          <div onClick={handleClose}>
            <CloseIcon />
          </div>
        </DialogTitle>

        <DialogContent className={classes.content}>
          {isSearchingCaptaincy ? (
            <div className={classes.field}>
              <span>Digite o número da capitania</span>

              <TextField
                value={searchForCaptaincy}
                onChange={e => {
                  setSearchForCaptaincy(e.target.value);
                }}
                required
                error={Boolean(errorMessage?.searchForCaptaincy)}
                helperText={errorMessage?.searchForCaptaincy}
                InputProps={{ disableUnderline: true }}
              />
            </div>
          ) : (
            <div className={classes.fieldsContainer}>
              <section>
                <div className={classes.field}>
                  <span>Nome</span>

                  <TextField
                    name="name_boating"
                    value={watercraft.name_boating}
                    onChange={handleChange}
                    required
                    error={Boolean(errorMessage?.name_boating)}
                    helperText={errorMessage?.name_boating}
                    InputProps={{ disableUnderline: true }}
                  />
                </div>

                <div className={classes.field}>
                  <span>Tipo</span>

                  <TextField
                    name="type"
                    value={watercraft.type}
                    onChange={handleChange}
                    required
                    error={Boolean(errorMessage?.type)}
                    helperText={errorMessage?.type}
                    InputProps={{ disableUnderline: true }}
                    select
                  >
                    {types.map(option => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>

                <div className={classes.field}>
                  <span>Capacidade</span>

                  <TextField
                    name="capacity"
                    value={watercraft.capacity}
                    onChange={handleChange}
                    type="number"
                    required
                    error={Boolean(errorMessage?.capacity)}
                    helperText={errorMessage?.capacity}
                    InputProps={{ disableUnderline: true }}
                  />
                </div>
              </section>

              <section>
                <div className={classes.field}>
                  <span>Modelo</span>

                  <TextField
                    name="model_brand"
                    value={watercraft.model_brand}
                    onChange={handleChange}
                    required
                    error={Boolean(errorMessage?.model_brand)}
                    helperText={errorMessage?.model_brand}
                    InputProps={{ disableUnderline: true }}
                  />
                </div>

                <div className={classes.field}>
                  <span>Capitania</span>

                  <TextField
                    name="number_captaincy"
                    value={watercraft.number_captaincy}
                    onChange={handleChange}
                    required
                    error={Boolean(errorMessage?.number_captaincy)}
                    helperText={errorMessage?.number_captaincy}
                    InputProps={{ disableUnderline: true }}
                  />
                </div>
              </section>
            </div>
          )}

          <Button
            onClick={isSearchingCaptaincy ? loadDataForCaptaincy : handleSubmit}
            className={classes.mainButton}
          >
            {isSearchingCaptaincy ? 'Avançar' : 'Salvar'}
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ModalCreateWatercraft;
