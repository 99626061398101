import { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import moment from 'moment';

import CustomAlert from '../CustomAlert';

import { useNavigations } from '../../contexts/navigations';

import { Navigation } from '../../interfaces/Navigation';

import api from '../../services/api';

import COLORS from '../../utils/colors';

const dialogWidth = '36rem';

const useStyles = makeStyles({
  buttonRevertNavigateIcon: {
    cursor: 'pointer',
    fontSize: '1.5rem',

    '&:hover': {
      color: COLORS.blackPure,
    },
  },

  title: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1.875rem 1.875rem 0 3.125rem',

    '& h1': {
      color: COLORS.blackPure,
      fontWeight: 700,
      fontSize: '1.875rem',
      lineHeight: '2.625rem',
      width: '90%',
      marginTop: '2rem',

      '& span': {
        color: COLORS.greenSecondary,
      },
    },

    '& div': {
      cursor: 'pointer',
      border: `1px solid ${COLORS.gray3}`,
      borderRadius: '0.375rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '2.25rem',
      height: '2.25rem',

      '& svg': {
        fontSize: '1.5rem',
        color: COLORS.gray,
      },

      '&:hover': {
        backgroundColor: `${COLORS.greenPrimary}0a`,
        transition: '0.3s',
      },
    },
  },

  content: {
    display: 'flex',
    padding: '3.125rem 5rem 3.125rem 3.125rem',
    columnGap: '1.5rem',
  },

  buttonNo: {
    height: '4.375rem',
    width: '15rem',
    padding: '0 1.625rem 0 .5rem',
    textTransform: 'none',
    fontWeight: 500,
    fontSize: '1.125rem',
    lineHeight: '1.625rem',
    borderRadius: '0.25rem',
    background: 'none',
    color: COLORS.redAlert,
    border: `1px solid ${COLORS.redAlert}`,
    textAlign: 'start',

    '&:hover': {
      backgroundColor: COLORS.redAlert,
      color: COLORS.whitePure,
      transition: '0.3s',
    },
  },

  buttonYes: {
    height: '4.375rem',
    width: '15rem',
    padding: '0 1.625rem 0 .5rem',
    textTransform: 'none',
    fontWeight: 500,
    fontSize: '1.125rem',
    lineHeight: '1.625rem',
    borderRadius: '0.25rem',
    background: 'none',
    color: COLORS.greenAlert,
    border: `1px solid ${COLORS.greenAlert}`,
    textAlign: 'start',

    '&:hover': {
      backgroundColor: COLORS.greenAlert,
      color: COLORS.whitePure,
      transition: '0.3s',
    },
  },
});

interface Props {
  navigation: Navigation;
}

const ModalRevertNavigate: React.FC<Props> = ({ navigation }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const { latestDepartures, setLatestDepartures } = useNavigations();

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSubmit = async () => {
    try {
      await api.post(`/departure/review-arrivals/${navigation.id}`, {
        user_id: navigation.user.id,
      });

      CustomAlert('success', 'Embarcação foi revertida!');

      setLatestDepartures(
        latestDepartures
          ?.filter(departure => departure.id !== navigation.id)
          .sort(
            (a: Navigation, b: Navigation) =>
              moment(b.arrival_date).valueOf() -
              moment(a.arrival_date).valueOf(),
          ),
      );
    } catch (error) {
      CustomAlert('error', 'Erro inesperado. Por favor, tente novamente!');
    }
  };

  return (
    <>
      <KeyboardBackspaceIcon
        onClick={handleOpen}
        className={classes.buttonRevertNavigateIcon}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{ style: { width: dialogWidth } }}
        maxWidth={false}
      >
        <DialogTitle className={classes.title} disableTypography>
          <h1>
            Tem certeza que deseja reverter a chegada da embarcação{' '}
            <span>
              {navigation.watercraft?.name_boating ??
                navigation.watercraft_name}{' '}
            </span>{' '}
            conduzida por <span>{navigation.conductor?.name}</span>?
          </h1>

          <div onClick={handleClose}>
            <CloseIcon />
          </div>
        </DialogTitle>

        <DialogContent className={classes.content}>
          <Button onClick={handleClose} className={classes.buttonNo}>
            Não, quero voltar ao histórico
          </Button>

          <Button onClick={handleSubmit} className={classes.buttonYes}>
            Sim, a embarcação ainda não chegou
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ModalRevertNavigate;
