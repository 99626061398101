import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import { KeyboardArrowRight } from '@material-ui/icons';
import moment from 'moment';

import Layout from '../../components/Layout';
import CustomPagination from '../../components/CustomPagination';
import ModalRevertNavigate from '../../components/ModalRevertNavigate';

import { useAuth } from '../../contexts/auth';
import { useNavigations } from '../../contexts/navigations';

import api from '../../services/api';

import COLORS from '../../utils/colors';

const useStyles = makeStyles({
  container: {
    height: '100%',
    width: '100%',
    padding: '0 6rem 2.25rem 4.25rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    '& h1': {
      fontSize: '2.25rem',
      fontWeight: 700,
      color: COLORS.greenPrimary,
    },

    '& .customPagination': {
      alignSelf: 'center',
    },
  },

  table: {
    '& .MuiTableCell-root': {
      border: 'none',
    },
  },

  tableHeader: {
    '& th': {
      fontWeight: 500,
      fontSize: '1.125rem',
      color: COLORS.gray3,
    },
  },

  dayAndHourContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '0.5rem',

    '& span': {
      color: COLORS.blueAlert,
    },
  },
});

const StyledTableRow = withStyles(() =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: `${COLORS.gray6}`,
      },

      '& .MuiTableCell-root': {
        border: 'none',
      },

      '& td': {
        fontWeight: 500,
        color: COLORS.gray,
        fontSize: '1.375rem',
      },
    },
  }),
)(TableRow);

export default function Historic() {
  const history = useHistory();

  const { latestDepartures, setLatestDepartures } = useNavigations();
  const [page, setPage] = useState(1);

  const { user } = useAuth();
  const classes = useStyles();

  const rowsPerPage = 8;
  const emptyRows = latestDepartures
    ? rowsPerPage -
      Math.min(rowsPerPage, latestDepartures.length - (page - 1) * rowsPerPage)
    : rowsPerPage;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (user.role !== 'marina') {
      history.push('/dashboard');
    }
  }, []);

  useEffect(() => {
    async function loadData() {
      try {
        const response = await api.get(`/departure/latest-arrivals`);
        setLatestDepartures(response.data);
      } catch (error) {
        console.log(error);
      }
    }
    loadData();
  }, [setLatestDepartures]);

  return (
    <Layout routeSelected="historic" type={user.role}>
      <div className={classes.container}>
        <h1>Histórico</h1>

        <TableContainer className={classes.table}>
          <Table>
            <TableHead>
              <TableRow className={classes.tableHeader}>
                <TableCell>Nome</TableCell>

                <TableCell>Capitania</TableCell>

                <TableCell>Destino</TableCell>

                <TableCell>Data e Hora</TableCell>

                <TableCell align="center">Reverter chegada</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {latestDepartures
                ?.slice(
                  (page - 1) * rowsPerPage,
                  (page - 1) * rowsPerPage + rowsPerPage,
                )
                .map(departure => {
                  const [dayExit, hourExit] = moment(departure.departure_date)
                    .calendar()
                    .split(', ');

                  const [dayArrival, hourArrival] = moment(
                    departure.arrival_date,
                  )
                    .calendar()
                    .split(', ');

                  return (
                    <StyledTableRow key={departure.id}>
                      <TableCell>
                        {departure.watercraft?.name_boating ??
                          departure.watercraft_name}
                      </TableCell>

                      <TableCell>
                        {departure.watercraft?.number_captaincy ??
                          departure.captaincy_number}
                      </TableCell>

                      <TableCell>{departure.destiny}</TableCell>

                      <TableCell>
                        {departure.departure_date && departure.arrival_date ? (
                          <div className={classes.dayAndHourContainer}>
                            <p>
                              {dayExit}, <span>{hourExit}</span>
                            </p>

                            <KeyboardArrowRight />

                            <p>
                              {dayArrival}, <span>{hourArrival}</span>
                            </p>
                          </div>
                        ) : (
                          'Essa embarcação não saiu'
                        )}
                      </TableCell>

                      <TableCell align="center">
                        <ModalRevertNavigate navigation={departure} />
                      </TableCell>
                    </StyledTableRow>
                  );
                })}

              {emptyRows > 0 && (
                <TableRow style={{ height: 64 * emptyRows }}>
                  <TableCell />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <div className="customPagination">
          <CustomPagination
            page={page}
            rowsPerPage={rowsPerPage}
            total={latestDepartures?.length}
            handleChangePage={handleChangePage}
          />
        </div>
      </div>
    </Layout>
  );
}
