import { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import CustomAlert from '../CustomAlert';

import { useNavigations } from '../../contexts/navigations';

import { Navigation } from '../../interfaces/Navigation';

import api from '../../services/api';

import COLORS from '../../utils/colors';

const dialogWidth = '40rem';

const useStyles = makeStyles({
  buttonCancelNavigateIcon: {
    cursor: 'pointer',
    fontSize: '1.5rem',
    color: COLORS.gray3,

    '&:hover': {
      color: COLORS.blackPure,
      transition: '0.3s',
    },
  },

  buttonCancelNavigate: {
    padding: 0,
    height: '2.25rem',
    width: '11.75rem',
    textTransform: 'none',
    backgroundColor: COLORS.redAlert2,
    color: COLORS.gray2,
    fontSize: '1.125rem',
    fontWeight: 500,

    '&:hover': {
      backgroundColor: `${COLORS.redAlert}55`,
      transition: '0.3s',
    },
  },

  title: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '3.125rem 3.125rem 0 3.125rem',

    '& h1': {
      color: COLORS.blackPure,
      fontWeight: 500,
      fontSize: '1.875rem',
      lineHeight: '2.625rem',
    },

    '& div': {
      cursor: 'pointer',
      border: `1px solid ${COLORS.gray3}`,
      borderRadius: '0.375rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '2.25rem',
      height: '2.25rem',

      '& svg': {
        fontSize: '1.5rem',
        color: COLORS.gray,
      },

      '&:hover': {
        backgroundColor: `${COLORS.greenPrimary}0a`,
        transition: '0.3s',
      },
    },
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '3.125rem 5rem 3.125rem 5rem',
    rowGap: '3.125rem',
  },

  field: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '1rem',

    '& p:nth-child(1)': {
      fontSize: '1.125rem',
      fontWeight: 500,
      color: COLORS.gray,
      textAlign: 'justify',

      '& span': {
        color: COLORS.redAlert,
      },
    },

    '& .MuiInputBase-root': {
      width: '100%',
      borderRadius: '0.5rem',
      border: `1px solid ${COLORS.greenPrimary}33`,
      padding: '1rem 1rem 0 1rem',
      backgroundColor: `${COLORS.greenPrimary}0a`,
      fontSize: '0.875rem',
    },
  },

  confirmButton: {
    height: '2.5rem',
    width: '7.625rem',
    padding: 0,
    textTransform: 'none',
    fontWeight: 500,
    fontSize: '1.125rem',
    lineHeight: '1.625rem',
    borderRadius: '0.25rem',
    backgroundColor: COLORS.greenAlert,
    color: COLORS.whiteMatte,
    alignSelf: 'center',

    '&:hover': {
      backgroundColor: COLORS.greenSecondary,
      color: COLORS.whitePure,
      transition: '0.3s',
    },
  },
});

interface Props {
  navigation: Navigation;
  type: string;
  typeButton: 'normal' | 'onModal';
}

const ModalCancelNavigate: React.FC<Props> = ({
  navigation,
  type,
  typeButton,
}) => {
  const [open, setOpen] = useState(false);
  const [reason, setReason] = useState('');
  const classes = useStyles();

  const { handleUnauthorizedExit } = useNavigations();

  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleClose = () => {
    setOpen(false);
    setErrorMessage('');
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSubmit = async () => {
    setErrorMessage('');

    if (!reason) {
      setErrorMessage('Este campo é obrigatório.');
      return;
    }

    try {
      await api.post(`/departure/cancel/remark/${navigation.id}`, { reason });
      CustomAlert('success', 'A saída da embarcação foi cancelada!');
      handleUnauthorizedExit(navigation, type);
    } catch (error) {
      CustomAlert('error', 'Erro inesperado. Por favor, tente novamente!');
    }
  };

  return (
    <div
      onClick={e => {
        e.stopPropagation();
      }}
    >
      {typeButton === 'normal' ? (
        <KeyboardBackspaceIcon
          className={classes.buttonCancelNavigateIcon}
          onClick={handleOpen}
        />
      ) : (
        <Button onClick={handleOpen} className={classes.buttonCancelNavigate}>
          Cancelar Saída
        </Button>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{ style: { width: dialogWidth } }}
        maxWidth={false}
      >
        <DialogTitle className={classes.title} disableTypography>
          <h1>Cancelar saída da embarcação</h1>

          <div onClick={handleClose}>
            <CloseIcon />
          </div>
        </DialogTitle>

        <DialogContent className={classes.content}>
          <div className={classes.field}>
            <p>
              Por que você quer <span>cancelar</span> a saída da embarcação?
            </p>

            <TextField
              value={reason}
              onChange={e => {
                setReason(e.target.value);
              }}
              required
              error={Boolean(errorMessage)}
              helperText={errorMessage}
              InputProps={{ disableUnderline: true }}
              multiline
              minRows={4}
            />
          </div>

          <Button onClick={handleSubmit} className={classes.confirmButton}>
            Confirmar
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ModalCancelNavigate;
