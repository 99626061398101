import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';

import CustomPagination from '../../../components/CustomPagination';
import TileNavigation from '../../../components/TileNavigation';

import { useNavigations } from '../../../contexts/navigations';
import COLORS from '../../../utils/colors';

const useStyles = makeStyles({
  container: {
    height: '100%',
    paddingTop: '2.25rem',
    marginTop: '2.5rem',
    columnGap: '2rem',
  },

  navigations: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '50%',
    height: '100%',

    '& .header': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',

      '& h1': {
        color: COLORS.greenPrimary,
        fontSize: '1.875rem',
        fontWeight: 700,
        lineHeight: '2.625rem',
      },

      '& span': {
        color: COLORS.gray,
        fontSize: '0.875rem',
        fontWeight: 400,
        lineHeight: '1.25rem',
      },
    },

    '& .content': {
      display: 'grid',
      height: '83%',
      gridTemplateColumns: '1fr 1fr',
      columnGap: '1rem',
      rowGap: '.5rem',
    },

    '& .customPagination': {
      alignSelf: 'center',
    },
  },
});

interface Props {
  id: string;
  ariaLabelledby: string;
  role: string;
  hidden: boolean;
}

const SailingAndArrivals: React.FC<Props> = ({
  id,
  ariaLabelledby,
  role,
  hidden,
}) => {
  const { navigates, arrivals } = useNavigations();

  const [page, setPage] = useState(1);
  const rowsPerPage = 10;

  const classes = useStyles();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  return (
    <div
      id={id}
      aria-labelledby={ariaLabelledby}
      role={role}
      style={{ display: hidden ? 'none' : 'flex' }}
      className={classes.container}
    >
      <div className={classes.navigations}>
        <div className="header">
          <h1>Navegando</h1>

          <span>{navigates.length} navegações</span>
        </div>

        <div className="content">
          {navigates
            ?.slice(
              (page - 1) * rowsPerPage,
              (page - 1) * rowsPerPage + rowsPerPage,
            )
            .map(element => (
              <TileNavigation key={element.id} data={element} type="navigate" />
            ))}
        </div>

        <div className="customPagination">
          <CustomPagination
            page={page}
            rowsPerPage={rowsPerPage}
            total={navigates?.length}
            handleChangePage={handleChangePage}
          />
        </div>
      </div>

      <div className={classes.navigations}>
        <div className="header">
          <h1>Chegadas</h1>

          <span>{arrivals.length} chegadas previstas</span>
        </div>

        <div className="content">
          {arrivals
            ?.slice(
              (page - 1) * rowsPerPage,
              (page - 1) * rowsPerPage + rowsPerPage,
            )
            .map(element => (
              <TileNavigation key={element.id} data={element} type="arrival" />
            ))}
        </div>

        <div className="customPagination">
          <CustomPagination
            page={page}
            rowsPerPage={rowsPerPage}
            total={arrivals?.length}
            handleChangePage={handleChangePage}
          />
        </div>
      </div>
    </div>
  );
};

export default SailingAndArrivals;
