import { HashRouter } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/pt-br';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

import Routes from './routes';

import { AuthProvider } from './contexts/auth';
import { NavigationsProvider } from './contexts/navigations';
import { SearchProvider } from './contexts/search';
import { ThemeProvider } from './contexts/theme';

moment.updateLocale('pt-br', {
  calendar: {
    lastDay: '[Ontem,] LT',
    sameDay: '[Hoje,] LT',
    nextDay: '[Amanhã,] LT',
    // Credits: https://gist.github.com/fernandosavio/680a2549e417befea930
    lastWeek(this: moment.CalendarSpecVal) {
      const currentDay = moment(this.valueOf()).day();
      const SUNDAY = 0;
      const SATURDAY = 6;

      return currentDay === SUNDAY || currentDay === SATURDAY
        ? '[Último] dddd, LT'
        : '[Última] dddd, LT';
    },
    nextWeek: 'dddd, LT',
    sameElse(this: moment.CalendarSpecVal) {
      const year = moment(this.valueOf()).year();
      const currentYear = moment().year();

      return currentYear === year ? 'DD/MM, LT' : 'L, LT';
    },
  },
});

export default function App() {
  return (
    <ThemeProvider>
      <ToastContainer />

      <HashRouter>
        <AuthProvider>
          <NavigationsProvider>
            <SearchProvider>
              <Routes />
            </SearchProvider>
          </NavigationsProvider>
        </AuthProvider>
      </HashRouter>
    </ThemeProvider>
  );
}
