import React from 'react';

import { useAuth } from '../../contexts/auth';

import Layout from '../../components/Layout';
import MarineDashboard from './Marine';
import MarinaDashboard from './Marina';
import AdminDashboard from './admin';

const ComponentToRenderByRole = {
  marina: MarinaDashboard,
  marine: MarineDashboard,
  admin: AdminDashboard,
};

export default function Dashboard() {
  const { user } = useAuth();

  const CurrentDashboard = ComponentToRenderByRole[user.role];

  return (
    <Layout routeSelected="dashboard" type={user.role}>
      <CurrentDashboard />
    </Layout>
  );
}
