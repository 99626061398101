import React from 'react';
import {
  GoogleMap,
  HeatmapLayer,
  useJsApiLoader,
} from '@react-google-maps/api';

import { CampaignClickLocation } from '../../interfaces/CampaignClickLocation';

const mapContainerStyle = { width: '100%', height: '100%' };
const center = { lat: -12.3642834, lng: -34.4535488 };
const zoom = 5.71;

interface Props {
  campaignClickLocations: CampaignClickLocation[];
}

const CustomGoogleMaps: React.FC<Props> = ({ campaignClickLocations }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY || '',
    libraries: ['visualization'],
    mapIds: ['satellite'],
  });

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      center={center}
      zoom={zoom}
    >
      <HeatmapLayer
        data={campaignClickLocations.map(campaignClickLocation => ({
          location: new google.maps.LatLng(
            campaignClickLocation.latitude,
            campaignClickLocation.longitude,
          ),
          weight: campaignClickLocation.number_of_clicks,
        }))}
      />
    </GoogleMap>
  ) : null;
};

export default CustomGoogleMaps;
