import moment from 'moment';
import { NavigationStatus } from '../interfaces/NavigationStatus';

const getExitStatus = (dateExitForecast: string) => {
  const exitForecast = moment(dateExitForecast);
  const interval = moment().diff(exitForecast, 'minutes');

  if (interval > 15) return 'delay';

  return '';
};

const getArrivalStatus = (dateArrivalForecast: string) => {
  const arrivalForecast = moment(dateArrivalForecast);
  const interval = moment().diff(arrivalForecast, 'minutes');

  if (interval > 30) return 'riskDelay';
  if (interval > 15) return 'delay';

  return 'inTransit';
};

export default function getNavigationStatus(
  statusCode: number,
  dateForecast: string,
): NavigationStatus {
  if (statusCode === 0) return getExitStatus(dateForecast);
  if (statusCode === 1) return getArrivalStatus(dateForecast);

  return '';
}
