/* eslint-disable import/prefer-default-export */
import {
  ThemeProvider as MUIThemeProvider,
  createTheme,
} from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';

import COLORS from '../utils/colors';

import MyFonts from '../fonts';

const theme = createTheme(
  {
    palette: {
      primary: {
        main: COLORS.greenPrimary,
      },

      secondary: {
        main: COLORS.orangePrimary,
      },

      error: {
        main: COLORS.redAlert,
      },

      background: {
        default: COLORS.whitePure,
      },
    },

    typography: {
      fontFamily: ['Noto Sans', 'sans-serif'].join(','),
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '@font-face': MyFonts,
        },
      },
    },
  },
  ptBR,
);

interface Props {
  children: React.ReactNode;
}

export function ThemeProvider({ children }: Props) {
  return <MUIThemeProvider theme={theme}>{children}</MUIThemeProvider>;
}
