import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import GetAppIcon from '@material-ui/icons/GetApp';
import {
  MuiPickersUtilsProvider,
  Calendar,
  TimePicker,
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import * as yup from 'yup';
import FileSaver from 'file-saver';

import CustomAlert from '../../components/CustomAlert';
import Layout from '../../components/Layout';

import { useAuth } from '../../contexts/auth';

import api from '../../services/api';

import COLORS from '../../utils/colors';

const useStyles = makeStyles({
  container: {
    padding: '0 4.375rem 1.5rem 4.375rem',
    height: '100%',
    width: '100%',

    '& h1': {
      fontWeight: 700,
      fontSize: '1.875rem',
      color: COLORS.greenPrimary,
      lineHeight: '2.625rem',
    },
  },

  content: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '3rem',
  },

  report: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '2rem',
  },

  select: {
    padding: 0,
    borderRadius: '0.375rem',

    '& .MuiInputBase-root': {
      backgroundColor: COLORS.gray4,
      width: '8.75rem',
      color: COLORS.blackPure,
      fontSize: '1.125rem',
      fontWeight: 400,
      height: '2.25rem',
    },

    '& .MuiSelect-selectMenu': {
      paddingTop: 0,
      paddingBottom: 0,
      backgroundColor: COLORS.gray4,
    },

    '& svg': {
      color: COLORS.blackPure,
    },

    '& fieldset': {
      border: 'none',
    },
  },

  calendar: {
    overflow: 'hidden',
    width: '19.125rem',
    backgroundColor: COLORS.gray6,

    '& .MuiPickersCalendarHeader-iconButton': {
      backgroundColor: COLORS.gray6,
    },

    '& .MuiPickersCalendarHeader-transitionContainer p': {
      fontSize: '1.125rem',
      color: COLORS.gray,
      fontWeight: 500,
    },

    '& .MuiPickersCalendarHeader-daysHeader span': {
      color: COLORS.gray,
      fontWeight: 700,
      fontSize: '0.875rem',
    },

    '& .MuiPickersDay-day p': {
      fontSize: '0.875rem',
      color: COLORS.gray,
      fontWeight: 500,
    },

    '& .MuiPickersDay-daySelected': {
      backgroundColor: COLORS.greenSecondary,

      '& p': {
        color: COLORS.whiteMatte,
      },
    },
  },

  buttonDownloadReport: {
    padding: 0,
    textTransform: 'none',
    color: COLORS.whiteMatte,
    backgroundColor: COLORS.greenSecondary,
    justifyContent: 'space-evenly',
    height: '3rem',
    width: '8.75rem',
    borderRadius: '0.375rem',
    alignSelf: 'center',
    fontSize: '1.125rem',
    fontWeight: 500,

    '& svg': {
      fontSize: '1.375rem !important',
    },

    '&:hover': {
      transition: '0.3s',
      backgroundColor: COLORS.greenPrimary,
    },
  },

  reportPerPeriodHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },

  selectTimes: {
    display: 'flex',
    columnGap: '.75rem',
  },

  popoverTime: {
    '& .MuiPickersToolbar-toolbar, & .MuiPickersClock-pin, & .MuiPickersClockPointer-pointer, & .MuiPickersClockNumber-clockNumberSelected':
      {
        backgroundColor: COLORS.greenSecondary,
      },

    '& .MuiPickersClockPointer-thumb': {
      borderColor: COLORS.greenSecondary,
    },
  },

  calendarsContainer: {
    display: 'flex',
    columnGap: '.75rem',
  },

  error: {
    color: COLORS.redAlert,
    fontSize: '0.75rem',
    padding: '0.5rem 0 0 2rem',
    backgroundColor: COLORS.whitePure,
    width: '100%',
    display: 'flex',
  },
});

interface ErrorMessage {
  reportTypePerDay?: string;
  reportDatePerDay?: string;

  reportTypePerPeriod?: string;
  reportDateStartPerPeriod?: string;
  reportDateEndPerPeriod?: string;
}

export default function Report() {
  const [reportTypePerDay, setReportTypePerDay] = useState('');
  const [reportDatePerDay, setReportDatePerDay] =
    useState<MaterialUiPickersDate>(moment());

  const [reportTypePerPeriod, setReportTypePerPeriod] = useState('');
  const [reportDateStartPerPeriod, setReportDateStartPerPeriod] =
    useState<MaterialUiPickersDate>(moment());
  const [reportDateEndPerPeriod, setReportDateEndPerPeriod] =
    useState<MaterialUiPickersDate>(moment());

  const [reportTimeStartPerPeriod, setReportTimeStartPerPeriod] =
    useState<MaterialUiPickersDate>(moment());
  const [reportTimeEndPerPeriod, setReportTimeEndPerPeriod] =
    useState<MaterialUiPickersDate>(moment());

  const [errorMessage, setErrorMessage] = useState<ErrorMessage>({});

  const classes = useStyles();

  const { user } = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (user.role !== 'marine' && user.role !== 'marina') {
      history.push('/dashboard');
    }
  }, []);

  const handleSubmitPerDay = async () => {
    setErrorMessage({});

    const schema = yup.object().shape({
      reportTypePerDay: yup.string().required('Este campo é obrigatório.'),
      reportDatePerDay: yup.string().required('Este campo é obrigatório.'),
    });
    try {
      await schema.validate(
        { reportTypePerDay, reportDatePerDay },
        { abortEarly: false },
      );

      const dateStartFormatted = `${reportDatePerDay?.format('L')} 00:00:00`;
      const dateEndFormatted = `${reportDatePerDay?.format('L')} 23:59:59`;

      const response = await api.post(
        `api/report/download`,
        {
          date_start: dateStartFormatted,
          date_end: dateEndFormatted,
          type: reportTypePerDay,
        },
        {
          responseType: 'blob',
        },
      );

      const fileName = `${moment().format(
        'DD[-]MM[-]YYYY[_]k[h]m[min]s[s]',
      )}.csv`;

      FileSaver.saveAs(response.data, fileName);
    } catch (error) {
      console.log(error);

      if (error instanceof yup.ValidationError) {
        let errorAux = {};
        error.inner.forEach(e => {
          errorAux = {
            ...errorAux,
            [e.path as string]: e.message,
          };
        });
        setErrorMessage(errorAux);
        return;
      }
      CustomAlert('error', 'Erro inesperado. Por favor, tente novamente!');
    }
  };

  const handleSubmitPerPeriod = async () => {
    setErrorMessage({});
    const schema = yup.object().shape({
      reportTypePerPeriod: yup.string().required('Este campo é obrigatório.'),
      reportDateStartPerPeriod: yup
        .string()
        .required('Este campo é obrigatório.'),
      reportDateEndPerPeriod: yup
        .string()
        .required('Este campo é obrigatório.'),
    });
    try {
      await schema.validate(
        {
          reportTypePerPeriod,
          reportDateStartPerPeriod,
          reportDateEndPerPeriod,
        },
        { abortEarly: false },
      );
      const dateStartFormatted = `${reportDateStartPerPeriod?.format('L')} ${
        reportTimeStartPerPeriod?.format('LT') || '00:00'
      }:00`;
      const dateEndFormatted = `${reportDateEndPerPeriod?.format('L')} ${
        reportTimeEndPerPeriod?.format('LT') || '23:59'
      }:59`;

      const response = await api.post(
        `api/report/download`,
        {
          date_start: dateStartFormatted,
          date_end: dateEndFormatted,
          type: reportTypePerPeriod,
        },
        {
          responseType: 'blob',
        },
      );

      const fileName = `${moment().format(
        'DD[-]MM[-]YYYY[_]k[h]m[min]s[s]',
      )}.csv`;

      FileSaver.saveAs(response.data, fileName);
    } catch (error) {
      console.log(error);

      if (error instanceof yup.ValidationError) {
        let errorAux = {};
        error.inner.forEach(e => {
          errorAux = {
            ...errorAux,
            [e.path as string]: e.message,
          };
        });

        setErrorMessage(errorAux);
        return;
      }
      CustomAlert('error', 'Erro inesperado. Por favor, tente novamente!');
    }
  };

  return (
    <Layout routeSelected="report" type={user.role}>
      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
        <div className={classes.container}>
          <h1>Gerar relatório</h1>

          <div className={classes.content}>
            <section className={classes.report}>
              <h2>Gerar por dia</h2>

              <TextField
                value={reportTypePerDay}
                onChange={e => {
                  setReportTypePerDay(e.target.value);
                }}
                variant="outlined"
                className={classes.select}
                select
                SelectProps={{ displayEmpty: true }}
                error={Boolean(errorMessage?.reportTypePerDay)}
                helperText={errorMessage?.reportTypePerDay}
              >
                <MenuItem value="" disabled>
                  Tipo
                </MenuItem>

                <MenuItem value="exits">Saída</MenuItem>

                <MenuItem value="arrivals">Chegada</MenuItem>
              </TextField>

              <div className={classes.calendar}>
                <Calendar
                  date={reportDatePerDay}
                  onChange={newValue => {
                    setReportDatePerDay(newValue);
                  }}
                  allowKeyboardControl={false}
                />

                {errorMessage?.reportDatePerDay && (
                  <span className={classes.error}>
                    {errorMessage?.reportDatePerDay}
                  </span>
                )}
              </div>

              <Button
                className={classes.buttonDownloadReport}
                onClick={handleSubmitPerDay}
                endIcon={<GetAppIcon />}
              >
                Download
              </Button>
            </section>

            <section className={classes.report}>
              <h2>Gerar por período</h2>

              <div className={classes.reportPerPeriodHeader}>
                <TextField
                  value={reportTypePerPeriod}
                  onChange={e => {
                    setReportTypePerPeriod(e.target.value);
                  }}
                  variant="outlined"
                  className={classes.select}
                  select
                  SelectProps={{ displayEmpty: true }}
                  error={Boolean(errorMessage?.reportTypePerPeriod)}
                  helperText={errorMessage?.reportTypePerPeriod}
                >
                  <MenuItem value="" disabled>
                    Tipo
                  </MenuItem>

                  <MenuItem value="exits">Saída</MenuItem>

                  <MenuItem value="arrivals">Chegada</MenuItem>
                </TextField>

                <div className={classes.selectTimes}>
                  <TimePicker
                    value={reportTimeStartPerPeriod}
                    onChange={newValue => {
                      setReportTimeStartPerPeriod(newValue);
                    }}
                    ampm={false}
                    inputVariant="outlined"
                    variant="inline"
                    className={classes.select}
                    PopoverProps={{ className: classes.popoverTime }}
                  />

                  <TimePicker
                    value={reportTimeEndPerPeriod}
                    onChange={newValue => {
                      setReportTimeEndPerPeriod(newValue);
                    }}
                    ampm={false}
                    inputVariant="outlined"
                    variant="inline"
                    className={classes.select}
                    PopoverProps={{ className: classes.popoverTime }}
                  />
                </div>
              </div>

              <div className={classes.calendarsContainer}>
                <div className={classes.calendar}>
                  <Calendar
                    date={reportDateStartPerPeriod}
                    onChange={newValue => {
                      setReportDateStartPerPeriod(newValue);
                    }}
                    allowKeyboardControl={false}
                  />

                  {errorMessage?.reportDateStartPerPeriod && (
                    <span className={classes.error}>
                      {errorMessage?.reportDateStartPerPeriod}
                    </span>
                  )}
                </div>

                <div className={classes.calendar}>
                  <Calendar
                    date={reportDateEndPerPeriod}
                    onChange={newValue => {
                      setReportDateEndPerPeriod(newValue);
                    }}
                    allowKeyboardControl={false}
                  />

                  {errorMessage?.reportDateEndPerPeriod && (
                    <span className={classes.error}>
                      {errorMessage?.reportDateEndPerPeriod}
                    </span>
                  )}
                </div>
              </div>

              <Button
                className={classes.buttonDownloadReport}
                onClick={handleSubmitPerPeriod}
                endIcon={<GetAppIcon />}
              >
                Download
              </Button>
            </section>
          </div>
        </div>
      </MuiPickersUtilsProvider>
    </Layout>
  );
}
