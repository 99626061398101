import { useState, KeyboardEvent, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import * as yup from 'yup';

import ModalForgotPassword from '../../components/ModalForgotPassword';

import { useAuth } from '../../contexts/auth';

import COLORS from '../../utils/colors';

import logo from '../../images/logo-login.png';
import background from '../../images/background.jpg';

const useStyles = makeStyles({
  container: {
    backgroundImage: `url(${background})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '2rem 3.5rem 2.5rem 3.5rem',
    backgroundColor: `${COLORS.whitePure}66`,
    borderRadius: '0.5rem',

    // Credits: https://gist.github.com/mrtcmn/0490fb79fa63418e4409a99553f80e26
    '@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none)': {
      '-webkit-backdrop-filter': 'blur(60px)',
      backdropFilter: 'blur(60px)',
    },
  },

  fields: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: '2rem',
    width: '100%',
    rowGap: '0.75rem',
  },

  insideInput: {
    backgroundColor: `${COLORS.whitePure}cc`,
    borderRadius: '0.5rem',

    '&:hover': {
      backgroundColor: COLORS.whitePure,
      transition: '0.3s',
    },
  },

  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    rowGap: '0.875rem',
    marginTop: '1.875rem',
  },

  buttonEnter: {
    fontWeight: 'bold',
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
    height: '3.375rem',
    width: '80%',
    borderRadius: '.625rem',
    padding: '0.75rem 0',

    '&:hover': {
      backgroundColor: COLORS.greenSecondary,
    },
  },

  buttonRequestAccount: {
    backgroundColor: `${COLORS.whitePure}cc`,
    color: COLORS.greenPrimary,
    fontWeight: 'bold',
    textTransform: 'initial',
    fontSize: '1.125rem',
    lineHeight: '1.75rem',
    height: '3.375rem',
    width: '80%',
    borderRadius: '.625rem',
    padding: '0.75rem 0',

    '&:hover': {
      backgroundColor: COLORS.whitePure,
      transition: '0.3s',
    },
  },
});

interface ErrorMessage {
  email?: string;
  password?: string;
}

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [visibilityPassword, setVisibilityPassword] = useState(false);

  const [errorMessage, setErrorMessage] = useState<ErrorMessage>({});

  const classes = useStyles();

  const { login, user } = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (user && history.location.pathname !== '/dashboard') {
      history.push('/dashboard');
    }
  }, []);

  const handleLogin = async () => {
    setErrorMessage({});

    const schema = yup.object().shape({
      email: yup
        .string()
        .email('Informe um e-mail válido.')
        .required('Este campo é obrigatório.'),
      password: yup.string().required('Este campo é obrigatório.'),
    });

    try {
      await schema.validate({ email, password }, { abortEarly: false });
      login(email, password);
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        let errorAux = {};

        error.inner.forEach(e => {
          errorAux = {
            ...errorAux,
            [e.path as string]: e.message,
          };
        });

        setErrorMessage(errorAux);
      }

      console.log(error);
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLImageElement>) => {
    if (e.key === 'Enter' || e.code === 'Enter') {
      handleLogin();
    }
  };

  return (
    <div
      className={classes.container}
      onKeyDown={handleKeyDown}
      tabIndex={0}
      role="button"
    >
      <div className={classes.content}>
        <img
          alt="Com o DattaSea suas navegações se tornam bem mais práticas!"
          src={logo}
        />

        <div className={classes.fields}>
          <TextField
            placeholder="E-mail"
            variant="outlined"
            value={email}
            onChange={event => {
              setEmail(event.target.value);
            }}
            fullWidth
            error={Boolean(errorMessage?.email)}
            helperText={errorMessage?.email}
            InputProps={{
              className: classes.insideInput,
            }}
          />

          <TextField
            placeholder="Senha"
            variant="outlined"
            value={password}
            onChange={event => {
              setPassword(event.target.value);
            }}
            fullWidth
            type={visibilityPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={() => {
                    setVisibilityPassword(!visibilityPassword);
                  }}
                >
                  {visibilityPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              ),
              className: classes.insideInput,
            }}
            error={Boolean(errorMessage?.password)}
            helperText={errorMessage?.password}
          />
        </div>

        <div className={classes.buttonsContainer}>
          <Button
            variant="contained"
            className={classes.buttonEnter}
            onClick={handleLogin}
            color="primary"
            disableElevation
            size="large"
          >
            Entrar
          </Button>

          {/* <Button
            variant="contained"
            className={classes.buttonRequestAccount}
            onClick={handleLogin}
            disableElevation
            size="large"
          >
            Solicite uma conta
          </Button> */}
        </div>

        <ModalForgotPassword value={email} />
      </div>
    </div>
  );
}
