import axios from 'axios';

const api = axios.create({ baseURL: process.env.REACT_APP_API_URL });

api.interceptors.request.use(config => {
  const localConfig = config;

  const token = localStorage.getItem('@dattaboat:token');
  if (token) {
    localConfig.headers.Authorization = `Bearer ${token}`;
  }

  return localConfig;
});

api.interceptors.response.use(
  async response => response,
  async error => {
    if (!error.response) return error;

    const { status, data } = error.response;

    if (status === 401) {
      throw error;
    }

    if (status === 500 && data.message === 'Failed to authenticate token.') {
      localStorage.clear();
      // eslint-disable-next-line no-alert
      alert('Sua sessão expirou. Por favor, faça o Login novamente!');
      window.location.reload();
    }

    if (status === 500) {
      throw error;
    }

    return error;
  },
);

export default api;
