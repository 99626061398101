/* eslint-disable react/jsx-no-constructed-context-values */
import { createContext, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

interface SearchContextProps {
  search: string;
  updateSearch(newSearch: string): void;
}

const SearchContext = createContext({});

export const SearchProvider: React.FC = ({ children }) => {
  const [search, setSearch] = useState('');

  const history = useHistory();

  const updateSearch = (value: string) => {
    setSearch(value);
    history.push('/watercrafts');
  };

  return (
    <SearchContext.Provider value={{ search, updateSearch }}>
      {children}
    </SearchContext.Provider>
  );
};

export function useSearch(): SearchContextProps {
  const context = useContext(SearchContext) as SearchContextProps;

  return context;
}
