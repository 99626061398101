import React, { useState } from 'react';
import {
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core';
import moment from 'moment';

import { KeyboardArrowRight } from '@material-ui/icons';
import CustomPagination from '../../../components/CustomPagination';
import TileStatus from '../../../components/TileStatus';
import ModalCancelNavigate from '../../../components/ModalCancelNavigate';
import ModalRegressNavigate from '../../../components/ModalRegressNavigate';

import { useNavigations } from '../../../contexts/navigations';

import COLORS from '../../../utils/colors';
import getNavigationStatus from '../../../utils/getNavigationStatus';

const useStyles = makeStyles({
  container: {
    height: '100%',
    paddingTop: '2.25rem',
    marginTop: '2.5rem',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& .customPagination': {
      alignSelf: 'center',
    },
  },

  table: {
    '& .MuiTableCell-root': {
      border: 'none',
    },
  },

  tableHeader: {
    '& th': {
      fontWeight: 500,
      fontSize: '1.125rem',
      color: COLORS.gray3,
    },
  },

  dayAndHourContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '0.5rem',

    '& span': {
      color: COLORS.blueAlert,
    },
  },

  actionsContainer: {
    display: 'flex',
    columnGap: '2rem',
    alignItems: 'center',
    justifyContent: 'center',

    '& svg': {
      color: 'currentColor',
      border: 'none',

      '&:hover': {
        color: COLORS.blackPure,
      },
    },
  },
});

const StyledTableRow = withStyles(() =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: `${COLORS.gray6}`,
      },

      '& .MuiTableCell-root': {
        border: 'none',
      },

      '& td': {
        fontWeight: 500,
        color: COLORS.gray,
        fontSize: '1.375rem',
      },

      '& .delay': {
        color: COLORS.orangeAlert,
      },
    },
  }),
)(TableRow);

interface Props {
  id: string;
  ariaLabelledby: string;
  role: string;
  hidden: boolean;
}

const SailingAndArrivals: React.FC<Props> = ({
  id,
  ariaLabelledby,
  role,
  hidden,
}) => {
  const { navigates, arrivals } = useNavigations();

  const navigatesAndArrivals = [...arrivals, ...navigates];

  const [page, setPage] = useState(1);
  const rowsPerPage = 9;
  const emptyRows = navigatesAndArrivals
    ? rowsPerPage -
      Math.min(
        rowsPerPage,
        navigatesAndArrivals.length - (page - 1) * rowsPerPage,
      )
    : rowsPerPage;

  const classes = useStyles();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  return (
    <div
      id={id}
      aria-labelledby={ariaLabelledby}
      role={role}
      style={{ display: hidden ? 'none' : 'flex' }}
      className={classes.container}
    >
      <TableContainer className={classes.table}>
        <Table>
          <TableHead>
            <TableRow className={classes.tableHeader}>
              <TableCell>Nome</TableCell>

              <TableCell>Capitania</TableCell>

              <TableCell>Destino</TableCell>

              <TableCell>Data e Hora</TableCell>

              <TableCell>Status</TableCell>

              <TableCell align="center">Confirmar chegada</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {navigatesAndArrivals
              ?.slice(
                (page - 1) * rowsPerPage,
                (page - 1) * rowsPerPage + rowsPerPage,
              )
              .map((navigation, index) => {
                const [dayExit, hourExit] = moment(navigation.exit_forecast)
                  .calendar()
                  .split(', ');

                const [dayArrival, hourArrival] = moment(
                  navigation.arrival_forecast,
                )
                  .calendar()
                  .split(', ');

                const arrivalStatus = getNavigationStatus(
                  navigation.status,
                  navigation.arrival_forecast,
                );

                return (
                  <StyledTableRow key={navigation.id}>
                    <TableCell>
                      {navigation.watercraft?.name_boating ??
                        navigation.watercraft_name}
                    </TableCell>

                    <TableCell>
                      {navigation.watercraft?.number_captaincy ??
                        navigation.captaincy_number}
                    </TableCell>

                    <TableCell>{navigation.destiny}</TableCell>

                    <TableCell>
                      <div className={classes.dayAndHourContainer}>
                        <p>
                          {dayExit}, <span>{hourExit}</span>
                        </p>

                        <KeyboardArrowRight />

                        <p>
                          {dayArrival}, <span>{hourArrival}</span>
                        </p>
                      </div>
                    </TableCell>

                    <TableCell>
                      <TileStatus status={arrivalStatus} type="table" />
                    </TableCell>

                    <TableCell align="center">
                      <div className={classes.actionsContainer}>
                        <ModalCancelNavigate
                          navigation={navigation}
                          type={
                            index + 1 > arrivals.length
                              ? 'navigates'
                              : 'arrivals'
                          }
                          typeButton="normal"
                        />

                        <ModalRegressNavigate
                          navigation={navigation}
                          type={
                            index + 1 > arrivals.length
                              ? 'navigates'
                              : 'arrivals'
                          }
                          typeButton="normal"
                        />
                      </div>
                    </TableCell>
                  </StyledTableRow>
                );
              })}

            {emptyRows > 0 && (
              <TableRow style={{ height: 64 * emptyRows }}>
                <TableCell />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <CustomPagination
        page={page}
        rowsPerPage={rowsPerPage}
        total={navigatesAndArrivals?.length}
        handleChangePage={handleChangePage}
      />
    </div>
  );
};

export default SailingAndArrivals;
