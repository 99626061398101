import React, { useEffect, useState } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Button,
  makeStyles,
  TextField,
} from '@material-ui/core';
import {
  DirectionsBoat,
  PeopleAlt,
  SwapHoriz,
  Search,
  Room,
} from '@material-ui/icons';
import { DebounceInput } from 'react-debounce-input';
import { useHistory } from 'react-router-dom';

import COLORS from '../../utils/colors';
import api from '../../services/api';
import ModalCreateCampaign from '../../components/ModalCreateCampaign';
import ModalCreateMarina from '../../components/ModalCreateMarina';
import ModalDeleteMarina from '../../components/ModalDeleteMarina';

import { Marina } from '../../interfaces/Marina';
import CustomPagination from '../../components/CustomPagination';

const useStyles = makeStyles(props => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    height: '106.25%',
    marginTop: '-3rem',
    padding: '0 4.375rem 2.25rem 4.375rem',
  },

  reportContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '87%',
    position: 'absolute',
    zIndex: props.zIndex.appBar,
  },

  report: {
    padding: '2.5rem 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: COLORS.gray5,
    columnGap: '2rem',
    width: 'calc(100%/4.5)',

    '& svg': {
      color: COLORS.greenPrimary,
      fontSize: '2.625rem',
    },
  },

  reportData: {
    '& h1': {
      fontSize: '2rem',
      fontWeight: 400,
    },

    '& span': {
      color: COLORS.greenPrimary,
      width: '9.15rem',
    },
  },

  mainContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    paddingTop: '3rem',
    marginTop: '8rem',
  },

  marinasContainer: {
    width: '70%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  marinasHeader: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& h1': {
      color: COLORS.greenPrimary,
      fontSize: '1.5rem',
    },
  },

  otherActionsContainer: {
    height: '100%',
    width: '30%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },

  buttonHeatmap: {
    textTransform: 'none',
    background: COLORS.whitePure,
    color: COLORS.greenPrimary,
    border: `1px solid ${COLORS.greenPrimary}`,
    padding: '1rem',
    width: '50%',

    '&:hover': {
      color: COLORS.whitePure,
      background: COLORS.greenPrimary,
      transition: '0.4s',
    },
  },
}));

const AdminDashboard: React.FC = () => {
  const [report, setReport] = useState({
    departures: 0,
    users: 0,
    watercrafts: 0,
  });

  const [marinas, setMarinas] = useState<Marina[]>([]);
  const [totalTableMarinas, setTotalTableMarinas] = useState(0);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const rowsPerPage = 6;
  const emptyRows = marinas
    ? rowsPerPage -
      Math.min(rowsPerPage, totalTableMarinas - (page - 1) * rowsPerPage)
    : rowsPerPage;

  const classes = useStyles();
  const history = useHistory();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get('/api/admin/dashboard');
        setReport(response.data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get('/api/marina', {
          params: { page, search, limit: rowsPerPage },
        });

        setTotalTableMarinas(Number(response.headers['x-total-count']) || 0);

        setMarinas(response.data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [page, search]);

  return (
    <div className={classes.container}>
      <div className={classes.reportContainer}>
        <div className={classes.report}>
          <PeopleAlt />

          <div className={classes.reportData}>
            <h1>{report?.users}</h1>

            <span>Usuários</span>
          </div>
        </div>

        <div className={classes.report}>
          <DirectionsBoat />

          <div className={classes.reportData}>
            <h1>{report?.watercrafts}</h1>

            <span>Embarcações</span>
          </div>
        </div>

        <div className={classes.report}>
          <SwapHoriz />

          <div className={classes.reportData}>
            <h1>{report?.departures}</h1>

            <span>Saídas</span>
          </div>
        </div>
      </div>

      <div className={classes.mainContainer}>
        <div className={classes.marinasContainer}>
          <div className={classes.marinasHeader}>
            <h1>Marinas</h1>

            <DebounceInput
              minLength={0}
              debounceTimeout={300}
              element={TextField}
              placeholder="Pesquisar"
              value={search}
              onChange={e => {
                setSearch(e.target.value);
              }}
              InputProps={{
                endAdornment: <Search />,
                style: {
                  height: '3rem',
                },
              }}
            />

            <ModalCreateMarina
              setMarinas={setMarinas}
              setTotalTableMarinas={setTotalTableMarinas}
              currentPage={page + 1}
              rowsPerPage={rowsPerPage}
              totalTableMarinas={totalTableMarinas}
            />
          </div>

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Nome</TableCell>

                  <TableCell align="center">Responsável</TableCell>

                  <TableCell align="center">CNPJ</TableCell>

                  <TableCell />
                </TableRow>
              </TableHead>

              <TableBody>
                {marinas &&
                  marinas.map(marina => (
                    <TableRow
                      key={marina.id}
                      hover
                      style={{ cursor: 'pointer' }}
                    >
                      <TableCell
                        onClick={() => {
                          history.push(`/marina/${marina.id}`);
                        }}
                        align="center"
                      >
                        {marina.name_marina}
                      </TableCell>

                      <TableCell
                        onClick={() => {
                          history.push(`/marina/${marina.id}`);
                        }}
                        align="center"
                      >
                        {marina.accountable}
                      </TableCell>

                      <TableCell
                        onClick={() => {
                          history.push(`/marina/${marina.id}`);
                        }}
                        align="center"
                      >
                        {marina.cnpj}
                      </TableCell>

                      <ModalDeleteMarina
                        id={marina.id}
                        name={marina.name_marina}
                        setMarinas={setMarinas}
                        setTotalTableMarinas={setTotalTableMarinas}
                        setPage={setPage}
                        rowsPerPage={rowsPerPage}
                        emptyRows={emptyRows}
                      />
                    </TableRow>
                  ))}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 62 * emptyRows }}>
                    <TableCell />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <CustomPagination
            page={page}
            rowsPerPage={rowsPerPage}
            total={totalTableMarinas}
            handleChangePage={handleChangePage}
          />
        </div>

        <div className={classes.otherActionsContainer}>
          <Button
            disableElevation
            className={classes.buttonHeatmap}
            onClick={() => {
              history.push('/heatmap');
            }}
            startIcon={<Room />}
          >
            Acessar o Heatmap
          </Button>

          <ModalCreateCampaign />
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
