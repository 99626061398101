import { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import * as yup from 'yup';

import CustomAlert from '../CustomAlert';

import api from '../../services/api';

import COLORS from '../../utils/colors';

const dialogWidth = '35rem';

const useStyles = makeStyles({
  forgotPassword: {
    display: 'flex',
    color: '#555555',
    marginTop: '1.875rem',
    fontWeight: 500,
    fontSize: '1.125rem',

    '& span': {
      marginRight: '0.25rem',
    },

    '& div': {
      textDecoration: 'underline',
      cursor: 'pointer',

      '&:hover': {
        color: '#035256',
      },
    },
  },

  title: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '3.125rem 3.125rem 0 3.125rem',

    '& h1': {
      color: COLORS.blackPure,
      fontWeight: 500,
      fontSize: '1.875rem',
      lineHeight: '2.625rem',
    },

    '& div': {
      cursor: 'pointer',
      border: `1px solid ${COLORS.gray3}`,
      borderRadius: '0.375rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '2.25rem',
      height: '2.25rem',

      '& svg': {
        fontSize: '1.5rem',
        color: COLORS.gray,
      },

      '&:hover': {
        backgroundColor: `${COLORS.greenPrimary}0a`,
        transition: '0.3s',
      },
    },
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '3.125rem 5rem 3.125rem 5rem',
    rowGap: '3.125rem',
  },

  field: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '1rem',

    '& p:nth-child(1)': {
      fontSize: '1.125rem',
      fontWeight: 500,
      color: COLORS.gray,
      textAlign: 'justify',

      '& span': {
        color: COLORS.redAlert,
      },
    },

    '& .MuiInputBase-root': {
      height: '2.875rem',
      width: '20rem',
      padding: '0 1rem',
      borderRadius: '0.5rem',
      border: `1px solid ${COLORS.greenPrimary}33`,
      backgroundColor: `${COLORS.greenPrimary}0a`,
      fontSize: '0.875rem',
    },
  },

  button: {
    height: '2.5rem',
    width: '7.625rem',
    padding: 0,
    textTransform: 'none',
    fontWeight: 500,
    fontSize: '1.125rem',
    lineHeight: '1.625rem',
    borderRadius: '0.25rem',
    backgroundColor: COLORS.greenAlert,
    color: COLORS.whiteMatte,
    alignSelf: 'center',

    '&:hover': {
      backgroundColor: COLORS.greenSecondary,
      color: COLORS.whitePure,
      transition: '0.3s',
    },
  },
});

interface Props {
  value: string;
}

const ModalForgotPassword: React.FC<Props> = ({ value }) => {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState(value);

  const [errorMessage, setErrorMessage] = useState('');

  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
    setErrorMessage('');
  };

  const handleOpen = () => {
    setEmail(value);
    setOpen(true);
  };

  const handleSearch = async () => {
    setErrorMessage('');

    if (!email) {
      setErrorMessage('Este campo é obrigatório.');
      return;
    }

    const schema = yup
      .string()
      .email('Informe um e-mail válido.')
      .required('Este campo é obrigatório.');

    try {
      await schema.validate(email, { abortEarly: false });

      await api.post('/forgot-password', { email });

      CustomAlert(
        'success',
        'Verifique o seu e-mail para poder redefinir a senha',
      );

      handleClose();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.log(error);

      if (error instanceof yup.ValidationError) {
        setErrorMessage(error.inner[0].message);
        return;
      }

      if (error.response && error.response.status === 404) {
        CustomAlert(
          'error',
          'Usuário não encontrado. Verifique o e-mail digitado!',
        );
        return;
      }

      CustomAlert('error', 'Erro inesperado. Por favor, tente novamente!');
    }
  };

  return (
    <>
      <div className={classes.forgotPassword}>
        <span>Esqueceu sua senha?</span>

        <div onClick={handleOpen}>Entre aqui.</div>
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{ style: { width: dialogWidth } }}
        maxWidth={false}
      >
        <DialogTitle className={classes.title} disableTypography>
          <h1>Recuperação de senha</h1>

          <div onClick={handleClose}>
            <CloseIcon />
          </div>
        </DialogTitle>

        <DialogContent className={classes.content}>
          <div className={classes.field}>
            <p>E-mail</p>

            <TextField
              value={email}
              onChange={e => {
                setEmail(e.target.value);
              }}
              required
              error={Boolean(errorMessage)}
              helperText={errorMessage}
              InputProps={{
                disableUnderline: true,
              }}
            />
          </div>

          <Button onClick={handleSearch} className={classes.button}>
            Pesquisar
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ModalForgotPassword;
