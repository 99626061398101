import { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import * as yup from 'yup';

import ModalDeleteOperator from '../ModalDeleteOperator';
import CustomAlert from '../CustomAlert';

import { Operator } from '../../interfaces/Operator';

import api from '../../services/api';

import COLORS from '../../utils/colors';

const useStyles = makeStyles({
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& span': {
      fontSize: '1.25rem',
      fontWeight: 500,
      lineHeight: '1.6',
    },

    '& svg': {
      fontSize: '2rem',
      cursor: 'pointer',

      '&:hover': {
        color: COLORS.gray2,
        transition: '0.3s',
      },
    },
  },

  input: {
    margin: '0.5rem auto',
    paddingRight: '1rem',
  },

  actions: {
    margin: '1.5rem auto',
  },

  buttonSave: {
    textTransform: 'none',
    paddingLeft: '2rem',
    paddingRight: '2rem',
  },
});

interface Props {
  operator: Operator;
  marina_id: string;
  setOperators: React.Dispatch<React.SetStateAction<Operator[]>>;
}

interface ErrorMessage {
  name?: string;
  email?: string;
  cellphone?: string;
}

const ModalEditOperator: React.FC<Props> = ({
  operator,
  marina_id,
  setOperators,
}) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const [errorMessage, setErrorMessage] = useState<ErrorMessage>({});

  useEffect(() => {
    setName(operator.name);
    setEmail(operator.email);
    setPhone(operator.cellphone);
  }, [open]);

  const handleClose = () => {
    setOpen(false);
    setErrorMessage({});
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSubmit = async () => {
    setErrorMessage({});

    const operatorUpdated = {
      name,
      email,
      cellphone: phone,
      marina_id,
    };

    const schema = yup.object().shape({
      name: yup.string().required('Este campo é obrigatório.'),
      email: yup
        .string()
        .email('E-mail informado tem formato inválido')
        .required('Este campo é obrigatório.'),

      cellphone: yup
        .string()
        .required('Este campo é obrigatório.')
        .matches(/^\(?[1-9]{2}\)? ?(?:[2-8]|9 ?[1-9])[0-9]{3}-?[0-9]{4}$/, {
          message: 'Telefone informado tem formato inválido',
        }),
    });

    try {
      await schema.validate(operatorUpdated, { abortEarly: false });
      await api.put(`/api/marina/user/update`, operatorUpdated);

      setOperators((prevOperators: Operator[]) =>
        prevOperators.map(prevOperator => {
          if (prevOperator.id !== operator.id) return prevOperator;

          return {
            id: operator.id,
            name,
            email,
            cellphone: phone,
          };
        }),
      );

      CustomAlert('success', 'Operador editado!');

      handleClose();
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        let errorAux = {};

        error.inner.forEach(e => {
          errorAux = {
            ...errorAux,
            [e.path as string]: e.message,
          };
        });

        setErrorMessage(errorAux);
        return;
      }

      CustomAlert('error', 'Erro inesperado. Por favor, tente novamente!');
    }
  };

  return (
    <>
      <TableRow key={operator.id} hover style={{ cursor: 'pointer' }}>
        <TableCell onClick={handleOpen} align="center">
          {operator.name}
        </TableCell>

        <TableCell onClick={handleOpen} align="center">
          {operator.email}
        </TableCell>

        <TableCell onClick={handleOpen} align="center">
          {operator.cellphone}
        </TableCell>

        <ModalDeleteOperator
          id={operator.id}
          name={operator.name}
          marina_id={marina_id}
          setOperators={setOperators}
        />
      </TableRow>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle className={classes.titleContainer} disableTypography>
          <span>Editar operador</span>

          <CloseIcon onClick={handleClose} />
        </DialogTitle>

        <DialogContent>
          <TextField
            label="Nome"
            className={classes.input}
            fullWidth
            value={name}
            onChange={e => {
              setName(e.target.value);
            }}
            required
            error={Boolean(errorMessage?.name)}
            helperText={errorMessage?.name}
          />

          <TextField
            label="E-mail"
            className={classes.input}
            fullWidth
            value={email}
            onChange={e => {
              setEmail(e.target.value);
            }}
            required
            error={Boolean(errorMessage?.email)}
            helperText={errorMessage?.email}
          />

          <TextField
            label="Telefone"
            className={classes.input}
            fullWidth
            value={phone}
            onChange={e => {
              setPhone(e.target.value);
            }}
            required
            error={Boolean(errorMessage?.cellphone)}
            helperText={errorMessage?.cellphone}
          />
        </DialogContent>

        <DialogActions className={classes.actions}>
          <Button
            color="primary"
            variant="contained"
            disableElevation
            className={classes.buttonSave}
            onClick={handleSubmit}
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ModalEditOperator;
