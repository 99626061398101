import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

import TileNavigationContainer from '../../components/TileNavigationContainer';

import { useAuth } from '../../contexts/auth';
import { useNavigations } from '../../contexts/navigations';

import api from '../../services/api';

const useStyles = makeStyles({
  adsFullScreenContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100vw',
    height: '100vh',
  },

  adsHorizontal: {
    width: '100vw',
    height: '10vh',
    position: 'absolute',
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  adsVertical: {
    width: '20vw',
    height: '90vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    right: 0,

    '& img': {
      width: '9vw',
      objectFit: 'contain',
    },
  },

  container: {
    display: 'flex',
    columnGap: '1.25rem',
    padding: '1rem 0',
    height: '90vh',
    width: '80vw',
  },
});

interface Ad {
  id: number;
  name: 'Lovin' | 'ArmazemPB' | 'Vs Datta imagem';
  redirect_to: string;
  logotype_url: string;
}

const colorsAds = {
  Lovin: '#e73b3e',
  ArmazemPB: '#272727',
  'Vs Datta imagem': '#4a4a4a',
};

export default function AdsInNavigations() {
  const { exits, navigates, arrivals, setExits, setNavigates, setArrivals } =
    useNavigations();
  const { user } = useAuth();

  const [ads, setAds] = useState<Ad[]>([]);
  const [adCurrentlyShown, setAdCurrentlyShown] = useState({
    index: 0,
    color: '#e73b3e',
  });
  const [isAdFullScreen, setIsAdFullScreen] = useState(false);

  const rowsPerPage = 6;

  const history = useHistory();

  useEffect(() => {
    if (user.role !== 'marina') {
      history.push('/dashboard');
    }
  }, []);

  const classes = useStyles();

  useEffect(() => {
    async function loadData() {
      try {
        const response = await api.get(`marina/departure`);

        setExits(response.data.exits);
        setNavigates(response.data.navigates);
        setArrivals(response.data.arrivals);

        setAdCurrentlyShown({
          index: 0,
          color: colorsAds.Lovin,
        });
      } catch (error) {
        console.log(error);
      }
    }
    loadData();

    const interval = setInterval(() => {
      loadData();
    }, 60 * 1000);

    return () => clearInterval(interval);
  }, [user.role, setExits, setArrivals, setNavigates]);

  useEffect(() => {
    async function loadAds() {
      try {
        const response = await api.get('/api/ads');

        setAds(response.data);
      } catch (error) {
        console.log(error);
      }
    }

    loadAds();
  }, []);

  useEffect(() => {
    async function updateIndexAdCurrentlyShown() {
      if (ads && ads.length) {
        setAdCurrentlyShown(prevAd => {
          const newIndex = (prevAd.index + 1) % ads.length;
          return {
            index: newIndex,
            color: colorsAds[ads[newIndex].name],
          };
        });
      }
    }

    const interval = setInterval(() => {
      updateIndexAdCurrentlyShown();
    }, 30 * 1000); // 30s

    return () => clearInterval(interval);
  }, [ads]);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsAdFullScreen(prevIsAdFullScreen => !prevIsAdFullScreen);
    }, 15 * 1000); // 15s

    return () => clearInterval(interval);
  }, []);

  return isAdFullScreen ? (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5 }}
      exit={{ opacity: 0 }}
    >
      {ads && !!ads.length && (
        <div
          className={classes.adsFullScreenContainer}
          style={{ backgroundColor: adCurrentlyShown.color }}
        >
          <img src={ads[adCurrentlyShown.index].logotype_url} alt="" />
        </div>
      )}
    </motion.div>
  ) : (
    <div>
      <div className={classes.container}>
        <TileNavigationContainer
          type="exit"
          data={exits}
          rowsPerPage={rowsPerPage}
        />

        <TileNavigationContainer
          type="navigate"
          data={navigates}
          rowsPerPage={rowsPerPage}
        />

        <TileNavigationContainer
          type="arrival"
          data={arrivals}
          rowsPerPage={rowsPerPage}
        />
      </div>

      {ads && !!ads.length && (
        <AnimatePresence exitBeforeEnter>
          <motion.a
            href={ads[adCurrentlyShown.index].redirect_to}
            target="_blank"
            rel="noreferrer"
            className={classes.adsHorizontal}
            style={{ backgroundColor: adCurrentlyShown.color }}
            key={adCurrentlyShown.index}
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.75 }}
          >
            <img src={ads[adCurrentlyShown.index].logotype_url} alt="" />
          </motion.a>
        </AnimatePresence>
      )}

      {ads && !!ads.length && (
        <AnimatePresence exitBeforeEnter>
          <motion.a
            href={ads[adCurrentlyShown.index].redirect_to}
            target="_blank"
            rel="noreferrer"
            className={classes.adsVertical}
            style={{ backgroundColor: adCurrentlyShown.color }}
            key={adCurrentlyShown.color}
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.75 }}
          >
            <img src="" alt="" />
          </motion.a>
        </AnimatePresence>
      )}
    </div>
  );
}
