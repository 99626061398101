import { toast } from 'react-toastify';

type AlertType = 'success' | 'error';

const CustomAlert = (alertType: AlertType, message: string) => {
  toast[alertType](message, {
    position: 'bottom-center',
    autoClose: 3500,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    bodyStyle: {
      textAlign: 'center',
    },
  });
};

export default CustomAlert;
