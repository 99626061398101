/* eslint-disable no-param-reassign */
import { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Close from '@material-ui/icons/Close';
import Edit from '@material-ui/icons/Edit';
import Share from '@material-ui/icons/Share';
import * as yup from 'yup';
import QRCode from 'qrcode.react';
import clsx from 'clsx';

import CustomAlert from '../CustomAlert';

import { useAuth } from '../../contexts/auth';

import { Watercraft } from '../../interfaces/Watercraft';

import api from '../../services/api';

import stringToBase64 from '../../utils/stringToBase64';
import svgToBlob from '../../utils/svgToBlob';
import COLORS from '../../utils/colors';

import logoQR from '../../images/logo-qr.png';

const dialogWidth = '50.75rem';

const useStyles = makeStyles({
  buttonShowWatercraft: {
    padding: 0,
    height: '1.875rem',
    width: '8.25rem',
    textTransform: 'none',
    color: COLORS.whitePure,
    backgroundColor: COLORS.greenSecondary,
    fontSize: '1.125rem',
    fontWeight: 400,

    '&:hover': {
      backgroundColor: COLORS.greenPrimary,
      transition: '0.3s',
    },
  },

  title: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '3.125rem 3.125rem 0 5rem',

    '& h1': {
      color: COLORS.blackPure,
      fontWeight: 500,
      fontSize: '1.875rem',
      lineHeight: '2.625rem',

      '& .delete': {
        fontWeight: 700,
        color: COLORS.redAlert,
      },

      '& .name': {
        fontWeight: 700,
        color: COLORS.greenSecondary,
      },
    },

    '& div': {
      cursor: 'pointer',
      border: `1px solid ${COLORS.gray3}`,
      borderRadius: '0.375rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '2.25rem',
      height: '2.25rem',

      '& svg': {
        fontSize: '1.5rem',
        color: COLORS.gray,
      },

      '&:hover': {
        backgroundColor: `${COLORS.greenPrimary}0a`,
        transition: '0.3s',
      },
    },
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '3.125rem 5rem 3.875rem 5rem',
  },

  mainContainer: {
    display: 'flex',
    justifyContent: 'center',
    columnGap: '3.125rem',

    '& section': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      rowGap: '1.25rem',
    },
  },

  field: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '0.625rem',

    '& span': {
      fontSize: '1.125rem',
      fontWeight: 500,
      color: COLORS.gray,
    },

    '& .MuiInputBase-root': {
      height: '3rem',
      width: '18.75rem',
      borderRadius: '0.5rem',
      border: `1px solid ${COLORS.greenPrimary}33`,
      padding: '0 0 0 1rem',
      outline: 'none',
      backgroundColor: `${COLORS.greenPrimary}0a`,
      fontSize: '0.875rem',
    },
  },

  owners: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '0.625rem',

    '& h2': {
      fontSize: '1.125rem',
      fontWeight: 500,
      color: COLORS.gray,
    },

    '& span': {
      fontSize: '0.875rem',
      color: COLORS.gray2,
      textAlign: 'justify',
    },
  },

  buttonEdit: {
    height: '2.5rem',
    width: '12.5rem',
    marginTop: '.625rem',
    textTransform: 'none',
    padding: 0,
    fontSize: '0.875rem',
    fontWeight: 700,
    color: COLORS.gray,
    border: `1px solid ${COLORS.gray}`,

    '&:hover': {
      color: COLORS.whitePure,
      backgroundColor: COLORS.gray,
      transition: '0.3s',
    },
  },

  buttonSave: {
    height: '2.5rem',
    width: '7.625rem',
    padding: 0,
    textTransform: 'none',
    fontWeight: 500,
    fontSize: '1.125rem',
    lineHeight: '1.625rem',
    borderRadius: '0.25rem',
    marginTop: '.625rem',
    backgroundColor: COLORS.greenAlert,
    color: COLORS.whiteMatte,
    alignSelf: 'center',

    '&:hover': {
      backgroundColor: COLORS.greenSecondary,
      color: COLORS.whitePure,
      transition: '0.3s',
    },
  },

  buttonShareQRCode: {
    height: '2.5rem',
    width: '15rem',
    marginTop: '.625rem',
    textTransform: 'none',
    padding: 0,
    fontSize: '0.875rem',
    fontWeight: 700,
    color: COLORS.gray,
    border: `1px solid ${COLORS.gray}`,

    '&:hover': {
      color: COLORS.whitePure,
      backgroundColor: COLORS.gray,
      transition: '0.3s',
    },
  },

  shareQRCodeContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: '1.25rem',
  },

  buttonSendToShareQRCode: {
    height: '2.5rem',
    width: '12rem',
    padding: 0,
    textTransform: 'none',
    fontWeight: 500,
    fontSize: '1.125rem',
    lineHeight: '1.625rem',
    borderRadius: '0.25rem',
    marginTop: '.625rem',
    backgroundColor: COLORS.greenAlert,
    color: COLORS.whiteMatte,
    alignSelf: 'center',

    '&:hover': {
      backgroundColor: COLORS.greenSecondary,
      color: COLORS.whitePure,
      transition: '0.3s',
    },
  },
});

const types = ['Barco', 'Jet', 'Barco + Jet'];

interface Props {
  data: Watercraft;
  watercrafts: Watercraft[];
  setWatercrafts: React.Dispatch<React.SetStateAction<Watercraft[]>>;
}

interface ErrorMessage {
  name_boating?: string;
  type?: string;
  capacity?: string;
  number_captaincy?: string;
  model_brand?: string;
}

const ModalShowWatercraft: React.FC<Props> = ({
  data,
  watercrafts,
  setWatercrafts,
}) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const { user } = useAuth();

  const [watercraft, setWatercraft] = useState({
    name_boating: '',
    type: '',
    capacity: '',
    number_captaincy: '',
    model_brand: '',
    owners: '',
  });

  const [isEditing, setIsEditing] = useState(false);

  const [isSharingQRCode, setIsSharingQRCode] = useState(false);
  const [emailToShareQRCode, setEmailToShareQRCode] = useState('');
  const [
    errorMessageForEmailToShareQRCode,
    setErrorMessageForEmailToShareQRCode,
  ] = useState('');

  const [errorMessage, setErrorMessage] = useState<ErrorMessage>({});

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      setIsSharingQRCode(false);
      setEmailToShareQRCode('');
      setErrorMessage({});
      setErrorMessageForEmailToShareQRCode('');
      setIsEditing(false);
    }, 300);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setWatercraft({
      ...watercraft,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    setWatercraft({
      name_boating: data.name_boating,
      type: data.type,
      capacity: data.capacity as string,
      number_captaincy: data.number_captaincy,
      model_brand: data.model_brand,
      owners: data.owners || '',
    });
  }, [open]);

  const handleSubmit = async () => {
    setErrorMessage({});

    const schema = yup.object().shape({
      name_boating: yup.string().required('Este campo é obrigatório.'),
      type: yup.string().required('Este campo é obrigatório.'),
      capacity: yup
        .number()
        .typeError('Este campo é do tipo numérico.')
        .required('Este campo é obrigatório.'),
      number_captaincy: yup.string().required('Este campo é obrigatório.'),
      model_brand: yup
        .string()
        .min(3, 'Este campo tem de ter pelo menos 3 caracteres.')
        .required('Este campo é obrigatório.'),
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { owners, ...watercraftToSubmit } = watercraft;

    try {
      await schema.validate(watercraftToSubmit, { abortEarly: false });
      await api.put(`/watercraft/${data.id}`, watercraftToSubmit);

      const index = watercrafts.findIndex(
        (element: Watercraft) => data.id === element.id,
      );
      watercrafts[index] = watercraft;
      setWatercrafts(watercrafts);

      data.name_boating = watercraft.name_boating;
      data.type = watercraft.type;
      data.capacity = watercraft.capacity;
      data.number_captaincy = watercraft.number_captaincy;
      data.model_brand = watercraft.model_brand;

      setIsEditing(false);
    } catch (error) {
      console.log(error);

      if (error instanceof yup.ValidationError) {
        let errorAux = {};

        error.inner.forEach(e => {
          errorAux = {
            ...errorAux,
            [e.path as string]: e.message,
          };
        });

        setErrorMessage(errorAux);
        return;
      }

      CustomAlert('error', 'Erro inesperado. Por favor, tente novamente!');
    }
  };

  const shareQRCode = async () => {
    setErrorMessageForEmailToShareQRCode('');

    const schema = yup
      .string()
      .email('E-mail informado tem formato inválido.')
      .required('Este campo é obrigatório.');

    try {
      await schema.validate(emailToShareQRCode, { abortEarly: false });

      const qrCodeImage = await svgToBlob(
        document.getElementById('qr-code-associate'),
      );

      const formData = new FormData();
      formData.append('email', emailToShareQRCode);
      formData.append('watercraft_id', data.id?.toString() || '');
      formData.append('file', qrCodeImage || '');

      await api.post('/api/watercraft/shared-qrcode', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setIsSharingQRCode(false);
      CustomAlert('success', 'Código QR compartilhado!');
      setEmailToShareQRCode('');
    } catch (error) {
      console.log(error);

      if (error instanceof yup.ValidationError) {
        setErrorMessageForEmailToShareQRCode(error.inner[0].message);
        return;
      }

      CustomAlert('error', 'Erro inesperado. Por favor, tente novamente!');
    }
  };

  return (
    <>
      <Button onClick={handleOpen} className={classes.buttonShowWatercraft}>
        Ver detalhes
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{ style: { width: dialogWidth } }}
        maxWidth={false}
      >
        <DialogTitle className={classes.title} disableTypography>
          <p />

          <h1>Detalhes da embarcação</h1>

          <div onClick={handleClose}>
            <Close />
          </div>
        </DialogTitle>

        <DialogContent className={classes.content}>
          <div className={classes.mainContainer}>
            <section>
              <div className={classes.field}>
                <span>Nome</span>

                <TextField
                  name="name_boating"
                  value={watercraft.name_boating}
                  onChange={handleChange}
                  required
                  error={Boolean(errorMessage?.name_boating)}
                  helperText={errorMessage?.name_boating}
                  InputProps={{ disableUnderline: true }}
                  disabled={!isEditing}
                />
              </div>

              <div className={classes.field}>
                <span>Tipo</span>

                <TextField
                  name="type"
                  value={watercraft.type}
                  onChange={handleChange}
                  required
                  error={Boolean(errorMessage?.type)}
                  helperText={errorMessage?.type}
                  InputProps={{ disableUnderline: true }}
                  select
                  disabled={!isEditing}
                >
                  {types.map(option => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </div>

              <div className={classes.field}>
                <span>Capacidade</span>

                <TextField
                  name="capacity"
                  value={watercraft.capacity}
                  onChange={handleChange}
                  type="number"
                  required
                  error={Boolean(errorMessage?.capacity)}
                  helperText={errorMessage?.capacity}
                  InputProps={{ disableUnderline: true }}
                  disabled={!isEditing}
                />
              </div>

              <div className={classes.field}>
                <span>Modelo</span>

                <TextField
                  name="model_brand"
                  value={watercraft.model_brand}
                  onChange={handleChange}
                  required
                  error={Boolean(errorMessage?.model_brand)}
                  helperText={errorMessage?.model_brand}
                  InputProps={{ disableUnderline: true }}
                  disabled={!isEditing}
                />
              </div>

              <div className={classes.field}>
                <span>Capitania</span>

                <TextField
                  name="number_captaincy"
                  value={watercraft.number_captaincy}
                  onChange={handleChange}
                  required
                  error={Boolean(errorMessage?.number_captaincy)}
                  helperText={errorMessage?.number_captaincy}
                  InputProps={{ disableUnderline: true }}
                  disabled={!isEditing}
                />
              </div>

              <Button
                onClick={
                  isEditing
                    ? handleSubmit
                    : () => {
                        setIsEditing(true);
                      }
                }
                className={clsx({
                  [classes.buttonSave]: isEditing,
                  [classes.buttonEdit]: !isEditing,
                })}
                endIcon={!isEditing && <Edit />}
              >
                {isEditing ? 'Salvar' : 'Editar informações'}
              </Button>
            </section>

            <section>
              <div className={classes.owners}>
                <h2>Proprietários(s)</h2>

                <span>
                  {watercraft.owners
                    ? watercraft.owners.split(';').join(', ')
                    : 'Nenhum proprietário associado no momento.'}
                </span>
              </div>

              <QRCode
                id="qr-code-associate"
                value={stringToBase64(
                  `${process.env.REACT_APP_API_URL}/associate/${user.marina?.id}/${data.id}`,
                )}
                level="H"
                includeMargin
                size={290}
                imageSettings={{
                  src: logoQR,
                  height: 65,
                  width: 65,
                }}
                renderAs="svg"
              />

              {isSharingQRCode ? (
                <div className={classes.shareQRCodeContainer}>
                  <div className={classes.field}>
                    <span>E-mail</span>

                    <TextField
                      value={emailToShareQRCode}
                      onChange={e => {
                        setEmailToShareQRCode(e.target.value);
                      }}
                      required
                      error={Boolean(errorMessageForEmailToShareQRCode)}
                      helperText={errorMessageForEmailToShareQRCode}
                      InputProps={{ disableUnderline: true }}
                    />
                  </div>

                  <Button
                    className={classes.buttonSendToShareQRCode}
                    onClick={shareQRCode}
                  >
                    Enviar código QR
                  </Button>
                </div>
              ) : (
                <Button
                  className={classes.buttonShareQRCode}
                  endIcon={<Share />}
                  onClick={() => {
                    setIsSharingQRCode(true);
                  }}
                >
                  Compartilhar código QR
                </Button>
              )}
            </section>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ModalShowWatercraft;
