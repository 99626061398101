import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import GetAppIcon from '@material-ui/icons/GetApp';

import Layout from '../../components/Layout';

import { useAuth } from '../../contexts/auth';

import COLORS from '../../utils/colors';

const useStyles = makeStyles({
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    padding: '0 6rem 1.5rem 6rem',
    rowGap: '2rem',

    '& h1': {
      fontSize: '2.25rem',
      fontWeight: 700,
      color: COLORS.greenPrimary,
      alignSelf: 'flex-start',
      paddingLeft: '3rem',
    },
  },

  document: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'auto',
    height: '100%',
    backgroundColor: 'red',
  },

  buttonDownload: {
    textTransform: 'none',
    padding: 0,
    height: '2.25rem',
    width: '8.75rem',
    color: COLORS.gray,
    backgroundColor: COLORS.gray4,
    fontWeight: 500,
    fontSize: '1.125rem',
    borderRadius: '0.375rem',
    alignSelf: 'flex-end',

    '& svg': {
      fontSize: '1.25rem !important',
      color: COLORS.gray,
    },
  },
});

export default function TideTable() {
  const classes = useStyles();
  const { user } = useAuth();
  const history = useHistory();

  const openLinkTideTable = () => {
    window.open(process.env.REACT_APP_TIDE_TABLE_URL);
  };

  useEffect(() => {
    if (user.role !== 'marine' && user.role !== 'marina') {
      history.push('/dashboard');
    }
  }, []);

  return (
    <Layout routeSelected="tide-table" type={user.role}>
      <div className={classes.container}>
        <h1>Tábua das Marés</h1>

        <Button
          endIcon={<GetAppIcon />}
          className={classes.buttonDownload}
          onClick={openLinkTideTable}
        >
          Download
        </Button>
      </div>
    </Layout>
  );
}
