import { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import * as yup from 'yup';

import CustomAlert from '../CustomAlert';

import api from '../../services/api';
import COLORS from '../../utils/colors';

const useStyles = makeStyles({
  buttonIcon: {
    cursor: 'pointer',

    '&:hover': {
      opacity: '0.85',
      transition: '0.3s',
    },
  },

  title: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1.875rem 1.875rem 0 1.875rem',

    '& h1': {
      color: COLORS.gray,
      fontWeight: 500,
      fontSize: '1.875rem',
      maxWidth: '65%',
      textAlign: 'center',
    },

    '& div:nth-child(3)': {
      cursor: 'pointer',
      border: `1px solid ${COLORS.gray3}`,
      borderRadius: '0.375rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '2.25rem',
      height: '2.25rem',

      '& svg': {
        fontSize: '1.5rem',
        color: COLORS.gray,
      },

      '&:hover': {
        backgroundColor: `${COLORS.greenPrimary}0a`,
        transition: '0.3s',
      },
    },
  },

  content: {
    width: '35rem',
    display: 'flex',
    flexDirection: 'column',
    padding: '3.25rem 6.25rem',
    rowGap: '1.75rem',
  },

  field: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '0.5rem',

    '& span': {
      fontSize: '0.875rem',
      color: COLORS.gray,
    },

    '& .MuiInputBase-root': {
      height: '3rem',
      borderRadius: '0.5rem',
      border: `1px solid ${COLORS.greenPrimary}33`,
      padding: '0 0 0 1rem',
      outline: 'none',
      backgroundColor: `${COLORS.greenPrimary}0a`,
      fontSize: '0.875rem',
    },
  },

  buttonSave: {
    marginTop: '1.75rem',
    padding: 0,
    height: '2.5rem',
    width: '7.625rem',
    alignSelf: 'center',
    backgroundColor: COLORS.greenAlert,
    color: COLORS.whiteMatte,

    '&:hover': {
      backgroundColor: COLORS.greenAlert,
      opacity: '0.9',
      transition: '0.3s',
    },
  },
});

interface ErrorMessage {
  password?: string;
  newPassword?: string;
  confirmNewPassword?: string;
}

const ModalChangePassword: React.FC = () => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const [password, setPassword] = useState('');
  const [visibilityPassword, setVisibilityPassword] = useState(false);

  const [newPassword, setNewPassword] = useState('');
  const [visibilityNewPassword, setVisibilityNewPassword] = useState(false);

  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [visibilityConfirmNewPassword, setVisibilityConfirmNewPassword] =
    useState(false);

  const [errorMessage, setErrorMessage] = useState<ErrorMessage>({});

  const handleClose = () => {
    setOpen(false);
    setPassword('');
    setVisibilityPassword(false);
    setNewPassword('');
    setVisibilityNewPassword(false);
    setConfirmNewPassword('');
    setVisibilityConfirmNewPassword(false);
    setErrorMessage({});
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSubmit = async () => {
    setErrorMessage({});

    const schema = yup.object().shape({
      password: yup.string().required('Este campo é obrigatório.'),
      newPassword: yup
        .string()
        .min(4, 'A nova senha tem de ter pelo menos 4 caracteres.')
        .required('Este campo é obrigatório.'),
      confirmNewPassword: yup
        .string()
        .required('Este campo é obrigatório.')
        .test(
          'passwords-match',
          'Senhas não coincidem',
          value => newPassword === value,
        ),
    });

    try {
      await schema.validate(
        { password, confirmNewPassword, newPassword },
        { abortEarly: false },
      );

      await api.post('/user/reset-password', {
        current_password: password,
        new_password: newPassword,
      });

      handleClose();

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.log(error);

      if (error instanceof yup.ValidationError) {
        let errorAux = {};

        error.inner.forEach(e => {
          errorAux = {
            ...errorAux,
            [e.path as string]: e.message,
          };
        });

        setErrorMessage(errorAux);
        return;
      }

      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.message ===
          'The password is incorrect. Please retry...'
      ) {
        setErrorMessage({
          password: 'A senha atual está incorreta.',
        });

        return;
      }

      CustomAlert('error', 'Erro inesperado. Por favor, tente novamente!');
    }
  };

  return (
    <>
      <EditIcon onClick={handleOpen} className={classes.buttonIcon} />

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle className={classes.title} disableTypography>
          <div />

          <h1>Alterar Senha</h1>

          <div onClick={handleClose}>
            <CloseIcon />
          </div>
        </DialogTitle>

        <DialogContent className={classes.content}>
          <div className={classes.field}>
            <span>Digite sua senha atual</span>

            <TextField
              value={password}
              onChange={e => {
                setPassword(e.target.value);
              }}
              type={visibilityPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() => {
                      setVisibilityPassword(!visibilityPassword);
                    }}
                  >
                    {visibilityPassword ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                ),
                disableUnderline: true,
              }}
              required
              error={Boolean(errorMessage?.password)}
              helperText={errorMessage?.password}
            />
          </div>

          <div className={classes.field}>
            <span>Digite sua nova senha</span>

            <TextField
              value={newPassword}
              onChange={e => {
                setNewPassword(e.target.value);
              }}
              type={visibilityNewPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() => {
                      setVisibilityNewPassword(!visibilityNewPassword);
                    }}
                  >
                    {visibilityNewPassword ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                ),
                disableUnderline: true,
              }}
              required
              error={Boolean(errorMessage?.newPassword)}
              helperText={errorMessage?.newPassword}
            />
          </div>

          <div className={classes.field}>
            <span>Digite sua nova senha novamente</span>

            <TextField
              value={confirmNewPassword}
              onChange={e => {
                setConfirmNewPassword(e.target.value);
              }}
              type={visibilityConfirmNewPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() => {
                      setVisibilityConfirmNewPassword(
                        !visibilityConfirmNewPassword,
                      );
                    }}
                  >
                    {visibilityConfirmNewPassword ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                ),
                disableUnderline: true,
              }}
              required
              error={Boolean(errorMessage?.confirmNewPassword)}
              helperText={errorMessage?.confirmNewPassword}
            />
          </div>

          <Button onClick={handleSubmit} className={classes.buttonSave}>
            Salvar
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ModalChangePassword;
