import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import CustomPagination from '../CustomPagination';
import TileNavigation from '../TileNavigation';

import { Navigation } from '../../interfaces/Navigation';

import COLORS from '../../utils/colors';

const useStyles = makeStyles({
  container: {
    width: 'calc(100%/3)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    rowGap: '0.75rem',

    '& .customPagination': {
      alignSelf: 'center',
    },
  },

  navigationsContainer: {
    padding: '0.5rem 0.875rem',
    height: '100%',
    width: '100%',
    borderRadius: '0.375rem',

    '&.Navegando': {
      backgroundColor: COLORS.gray4,
    },
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& h1': {
      fontSize: '1.875rem',
      fontWeight: 700,
      color: COLORS.gray,
      lineHeight: '2.625rem',
    },

    '& span': {
      fontSize: '.875rem',
      fontWeight: 500,
      color: COLORS.gray2,
      lineHeight: '1.25rem',
    },
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '0.625rem',
  },
});

interface Props {
  type: 'exit' | 'navigate' | 'arrival';
  data: Navigation[];
  rowsPerPage: number;
}

const headerData = {
  exit: {
    title: 'Solicitados',
    subtitle: (length: number) => (length > 1 ? 'solicitações' : 'solicitação'),
  },

  navigate: {
    title: 'Navegando',
    subtitle: (length: number) => (length > 1 ? 'navegações' : 'navegação'),
  },

  arrival: {
    title: 'Chegadas',
    subtitle: (length: number) =>
      length > 1 ? 'chegadas previstas' : 'chegada prevista',
  },
};

const TileNavigationContainer: React.FC<Props> = ({
  type,
  data,
  rowsPerPage,
}) => {
  const classes = useStyles();

  const [page, setPage] = useState(1);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  return (
    <div className={classes.container}>
      <div
        className={clsx(classes.navigationsContainer, headerData[type].title)}
      >
        <div className={classes.header}>
          <h1>{headerData[type].title}</h1>

          <span>
            {data.length} {headerData[type].subtitle(data.length)}
          </span>
        </div>

        <div className={classes.content}>
          {data
            ?.slice(
              (page - 1) * rowsPerPage,
              (page - 1) * rowsPerPage + rowsPerPage,
            )
            .map(element => (
              <TileNavigation key={element.id} data={element} type={type} />
            ))}
        </div>
      </div>

      <div className="customPagination">
        <CustomPagination
          page={page}
          rowsPerPage={rowsPerPage}
          total={data.length}
          handleChangePage={handleChangePage}
        />
      </div>
    </div>
  );
};

export default TileNavigationContainer;
